import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import UserContext from "../../context/UserContext";
import ImageCard from "./ImageCard";
import axios from "axios";

export default function ImageGrid({
  profile,
  selectedProject,
  projectList, 
  handleRefreshProjects,
  multiSelectProjectId,
  multiSelectProjectName,
  isAdmin = false,
  currentPage,
  setCurrentPage,
}) {
  const [imageCollection, setImageCollection] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const { imageCount, setImageCount, APIToken, logoutUser } = useContext(UserContext);
  const pageSize = 12;
  const pageCount = Math.ceil(imageCount / pageSize);

  async function handleGetImageCollection(page = currentPage) {   
    if (profile?.email === undefined) {
      return;
    }
    
    if (page === 1 && selectedProject?.id !== undefined && selectedProject.name === "Search") {            
      const filteredProjectList = projectList.filter((proj) => proj.name === "Search");
      const sortedImages = filteredProjectList[0]?.images?.sort((a, b) => b.id - a.id);
      setImageCollection(sortedImages);      
      setImageCount(sortedImages.length)
      return
    }
    
  
  if (selectedProject?.id !== undefined && selectedProject?.name !== "Search") {
    var url = "";            
      url = `${process.env.REACT_APP_API_BE}/api/images?email=${profile?.email}&page=${page}&project_id=${selectedProject?.id}&shouldLog=${isAdmin}`;
    } else {
      url = `${process.env.REACT_APP_API_BE}/api/images?email=${profile?.email}&page=${page}&shouldLog=${isAdmin}`;
    }
    const response = await axios.get(url, { 
      headers: {
        "Content-Type": "application/json",        
        Authorization: `Token ${APIToken ? APIToken : localStorage.getItem("token")}`,
      }
     });
     if (response.status === 401) {
      console.error("Unauthorized, please log in first");
      logoutUser();
    }
    else if (response.status === 200) {
      const data = await response.data;
      if (data === null || data?.results?.length === 0) {
        setImageCollection([]);
      } else {
        setImageCollection(data?.results);
        setImageCount(data?.count);
      }
    }
  }

  const getToken = () => {
    const token = APIToken || localStorage.getItem("token");
    if (!token || token === "undefined") {
      console.error("Not logged in");
      logoutUser();
    } else {
      return token;
    }
  }

  async function handleGetAllImages(page = 1) {
    if (profile?.email === undefined) {
      return;
    }

    const token = getToken();
    if (!token) return;
    const response = await axios.get(
      `${process.env.REACT_APP_API_BE}/api/images?email=${profile?.email}&page=${page}&shouldLog=${isAdmin}`,
      { headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken ? APIToken : localStorage.getItem("token")}`,
       }
      }
    );

    if (response.status === 200) {
      const data = response.data;      
      if (!data?.results?.length) {
        setImageCollection([]);
      } else {
        setImageCollection(data?.results);
        setImageCount(data?.count);
      }
    } else if (response.status === 401) {
      console.error("Unauthorized, please log in first");
      logoutUser();
    }
  }

  const handleUpdateProjects = () => {
    handleGetImageCollection();
  };

  const handleAddToProject = (image) => {
    const addToProject = async () => {      
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/project`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${APIToken}`,
          },
          body: JSON.stringify({
            image_id: image?.id,
            project_id: [multiSelectProjectId],
            email: profile?.email,
          }),
        }
      );

      if (response.status === 200) {
        await response.json();        
        handleRefreshProjects();
      }
    };
    addToProject();
  };

    
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    handleGetImageCollection(value);
  };

  useEffect(() => {
    if (profile?.email !== undefined && selectedProject?.id !== undefined) {
      handleGetImageCollection(currentPage);    
      } else {
        handleGetAllImages(currentPage);
      }          
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, profile?.email, currentPage]);

  
  return (
    <div>
      <div>       
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          {imageCount > 4 && (
            <Stack spacing={2}>
              <Pagination
                onChange={handlePageChange}
                count={pageCount}
                variant="outlined"
                color="primary"
                sx={{  paddingBottom: "1em" }}
              />
            </Stack>
          )}
        </Box>

        <Grid
          container
          colums={{ xs: 4, sm: 8, md: 32 }}
          spacing={1}
        >
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 16 }}
          >
            {imageCollection && imageCollection.map((item, index) => (
              <Grid item xs={4} sm={4} key={item.id}>
                <ImageCard
                  item={item}
                  projectList={projectList}
                  handleUpdateProjects={handleUpdateProjects}
                  handleRefreshProjects={handleRefreshProjects}
                  selectedProject={selectedProject}
                  handleAddToProject={handleAddToProject}
                  multiSelectProjectName={multiSelectProjectName}
                  currentPage={currentPage}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
