import React, { useContext, useEffect, useState } from "react";
import BaseballCard from "../components/BaseballCard";
import UserContext from "../context/UserContext";
import { Paper, Box } from "@mui/material";
import PlanLimitTracker from "../components/PlanLimitTracker";
import useMediaQuery from "@mui/material/useMediaQuery";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";
import NavButtons from "../components/navigation/NavButtons";

export default function ProfilePage() {
  const { profile, APIToken } = useContext(UserContext);
  const [planUsage, setPlanUsage] = useState({});
  const [projectCount, setProjectCount] = useState(0);

  const isMobile = useMediaQuery("(max-width:1600px)");

  useEffect(() => {
    async function handleGetPlanUsage() {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/profile/${profile?.email}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken ? APIToken : localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();      
      setPlanUsage(data);
    }
    if (profile?.email) {
      handleGetPlanUsage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.email]);

  return (
    <>
      <NavButtons showTagLine={false} />

      <Paper
        elevation={3}
        sx={{
          p: 2,
          m: 2,
          width: isMobile ? "95%" : "80%",
          display: isMobile ? "block" : "flex",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "column",
          }}
        >
          <BaseballCard
            planUsage={planUsage}
            setProjectCount={setProjectCount}
          />
          <PlanLimitTracker planUsage={planUsage} projectCount={projectCount} />
        </Box>
      </Paper>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}
