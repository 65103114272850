import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
// import ListItemText from "@mui/material/ListItemText";
import NavButtons from "../../components/navigation/NavButtons";
import FooterNavLinks from "../../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../../components/navigation/CommunityImageLinks";

export default function Guides() {
  return (
    <>
      <NavButtons showTagLine={false} />
      <Paper
        elevation={3}
        sx={{
          margin: "auto",
          padding: "2em",
          textAlign: "left",
          width: { xs: "90%", sm: "80%", md: "70%" },
        }}
      >
        <Typography variant="h2">Guides</Typography>

        <br />
        <List>
        <ListItem>            
            <Link href="/videos">Videos</Link>
          </ListItem>
          <ListItem>            
            <Link href="/prompt-guide">Prompt Guide</Link>
          </ListItem>
          <ListItem>            
            <Link href="/guides/remove-elements-from-image">Remove elements from an Image</Link>
          </ListItem>
          <ListItem>            
            <Link href="/guides/use-prompt-modifiers">Use prompt modifiers to fine tune an image</Link>
          </ListItem>
          <ListItem>            
            <Link href="/guides/do-not-prompt-like-this">Do Not Prompt Like This</Link>
          </ListItem>
        </List>
      </Paper>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}
