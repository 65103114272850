import React from "react";
import { List, ListItem, Avatar, Box, ListItemText, Chip } from "@mui/material";
import ImageModifierChips from "../image_display/ImageModifierChips";


export default function ImageListCollection({ imageCollection, currentPage}) {
    return (
        <List
        sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
      >
        {imageCollection.map((item, index) => (
          <ListItem
            key={index + currentPage}
            sx={{ boxShadow: 3, marginBottom: ".75em" }}
          >
            <Avatar
              variant="square"
              src={
                item.low_res_image_url ? item.low_res_image_url : item.image_url
              }
              alt={item.name}
              sx={{ width: 64, height: 64, marginRight: "1em" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                width: "100%",
              }}
            >
              <ListItemText
                primary={item?.name}
                secondary={item?.description}
              />            
              <ImageModifierChips image_modifiers={item.image_modifiers} />
            </Box>
            <Chip label={item?.merch} />
          </ListItem>
        ))}
      </List>
    );
}