import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Box, Paper, Button, Link, Typography } from "@mui/material";
import InfoHelper from "../components/InfoHelper";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import RadioPromptTuner from "../components/radio_tuner/RadioPromptTuner";
import { useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import logo from "../MerchDesignTransparentCleanSM-svg.webp";
import CustomHTMLToolTip from "../components/CustomHTMLToolTip";
import { callCheckImageCount } from "../shared/callCheckImageCount";
import OnboardingStateDirector from "../shared/OnboardingStateDirector";
import UserContext from "../context/UserContext";
import RateImage from "../components/RateImage";
import Visibility from "../components/Visibility";
import UserAlert from "../components/UserAlert";
import AdminNav from "../components/navigation/AdminNav";
import NavButtons from "../components/navigation/NavButtons";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";
import PromptInput from "../components/creation/PromptInput";
import CreateMerch from "../components/creation/CreateMerch";
import CreateColoring from "../components/creation/CreateColoring";
import ChooseYourAdventure from "../components/creation/ChooseYourAdventure";
import { visualDesignOptions } from "../options/visual-design-options";

// Tunes prompts for stickers with 1 image selected only cost 2 tokens
// or prob about 6 tokens with the prod api without promptmagic and
// 8 tokens with promptmagic

export default function Landing({
  profile,
  setImageCount,
  setDailyImageCount,
  setMonthlyImageCount,
  isUsageLimited,
  activeSubscription,
  subscriptionCancelsAt,
}) {
  const [showCreateMerch, setShowCreateMerch] = useState(false);
  const [showCreateColoring, setShowCreateColoring] = useState(false);
  const [showQ1, setShowQ1] = useState(true);
  const [subject, setSubject] = useState("");
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [generateWithPixieDust, setGenerateWithPixieDust] = useState(false);
  // const [isLandingPage, setIsLandingPage] = useState(true);
  const [customMood, setCustomMood] = useState("Cute");
  const [customArtStyle, setCustomArtStyle] = useState("Minimal");
  const [customColorStyle, setCustomColorStyle] = useState("Cool Colors");
  const [customWallArtStyle, setCustomWallArtStyle] = useState("");
  const [customWallArtColorStyle, setCustomWallArtColorStyle] = useState("");
  const [customColoringBookAnimation, setCustomColoringBookAnimation] =
    useState("Cartoon");
  const [customColoringBootArt, setCustomColoringBookArt] = useState("");
  const [customColoringBookTheme, setCustomColoringBookTheme] =
    useState("More Detail");
  const [promptSubmitted, setPromptSubmitted] = useState(true);
  const [showSubjectAlert, setShowSubjectAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [numberOfImages, setNumberOfImages] = useState(1);
  const [showPaywall, setShowPaywall] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);
  const [showTuner, setShowTuner] = useState(false);

  const { noAccessAllowed } = useContext(UserContext);

  const isLargerThan1200 = useMediaQuery("(min-width:1200px)");

  const optionsRef = useRef(null);
  const generatedImageRef = useRef(null);
  const isLandingPage = true;

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.type) {
        handleChooseMerchCategory(event.state.type);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // const handleChooseMerchType = (type) => {
  //   setCreationState({
  //     merch: type === "merch",
  //     coloring: type !== "merch" && type !== "none"
  //   });

  //   window.history.pushState({ type }, '', window.location.pathname);
  // };

  // const handleChooseMerchCategory = (type) => {
  //   setCreationState({
  //     merch: type === "merch",
  //     coloring: type !== "merch" && type !== "none"
  //   });
  //   setShowCreateMerch(type);
  //   window.history.pushState({ type }, '', window.location.pathname);
  // };

  const handleChooseMerchCategory = (type) => {
    if (type === "none") {
      setShowCreateMerch(false);
      setShowCreateColoring(false);
      setSelectedOption({});
    } else if (type === "merch") {
      setShowCreateMerch(true);
      // setSelectedOption({ simpleName: "sticker" });
      setSelectedOption(visualDesignOptions[0]);
      setShowCreateColoring(false);      
    } else {
      setShowCreateMerch(false);
      setShowCreateColoring(true);
    }

    // Push a new state to replace the one we just popped
    window.history.pushState({ type }, "", window.location.pathname);
  };

  const scrollToElement = () => {
    optionsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const scrollToGeneratedImage = () => {
    generatedImageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleShowTuner = () => {
    setShowTuner(!showTuner);
  };

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo === undefined || userInfo === null) {
      window.location.href = "/";
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToElement();
  }, [selectedOption]);

  const revertToInitialState = () => {
    const currentSubject = document.getElementById("subject")?.value;
    setShowQ1(true);
    setGenerateWithPixieDust(false);
    setSubject(currentSubject ? currentSubject : "");
    setImageUrls([]);
    setSelectedOption({});
  };

  // const handleIsLanding = () => {
  //   setIsLandingPage(!isLandingPage);
  // };

  const handleChooseMerchandiseType = (option) => {        
    setShowErrorAlert(false);
    setSelectedOption(option);
  };

  const handleSetSliderImageCount = (e, value) => {
    if (!activeSubscription) {
      setSliderValue(1);
      setShowPaywall(true);
    } else {
      setSliderValue(value);
      setNumberOfImages(value);
    }
  };

  const handleShowPaywall = () => {
    setShowPaywall(!showPaywall);
  };

  const handleSubjectChange = (e) => {
    if (e.target.value.length < 500) {
      setShowSubjectAlert(false);
      setShowErrorAlert(false);
      setErrorAlertMessage("");
      setSubject(e.target.value);
    } else {
      setShowSubjectAlert(true);
    }
  };

  const tunePrompt = () => {
    const updatedSelections = selectedOption?.prompt
      ?.replace(/\[SUBJECT\]/g, subject?.toLowerCase())
      .replace(/\[MOOD\]/g, customMood?.toLowerCase())
      .replace(/\[ART_STYLE\]/g, customArtStyle?.toLowerCase())
      .replace(/\[COLOR_STYLE\]/g, customColorStyle?.toLowerCase())
      .replace(/\[WALL_ART_STYLE\]/g, customWallArtStyle?.toLowerCase())
      .replace(
        /\[WALL_ART_COLOR_STYLE\]/g,
        customWallArtColorStyle?.toLowerCase()
      )
      .replace(
        /\[COLORING_BOOK_ANIMATION\]/g,
        customColoringBookAnimation?.toLowerCase()
      )
      .replace(/\[COLORING_BOOK_ART\]/g, customColoringBootArt?.toLowerCase())
      .replace(
        /\[COLORING_BOOK_THEME\]/g,
        customColoringBookTheme?.toLowerCase()
      );
    // console.log(`Line 115 updatedSelections ${updatedSelections}`);
    return updatedSelections;
  };

  const promptTuningCallback = (tunedOptions) => {
    if (tunedOptions.customMood !== undefined) {
      setCustomMood(tunedOptions.customMood);
    } else if (tunedOptions.customArtStyle !== undefined) {
      setCustomArtStyle(tunedOptions.customArtStyle);
    } else if (tunedOptions.customColorStyle !== undefined) {
      setCustomColorStyle(tunedOptions.customColorStyle);
    } else if (tunedOptions.customWallArtStyle !== undefined) {
      setCustomWallArtStyle(tunedOptions.customWallArtStyle);
    } else if (tunedOptions.customWallArtColorStyle !== undefined) {
      setCustomWallArtColorStyle(tunedOptions.customWallArtColorStyle);
    } else if (tunedOptions.customColoringBookAnimation !== undefined) {
      setCustomColoringBookAnimation(tunedOptions.customColoringBookAnimation);
    } else if (tunedOptions.customColoringBookArt !== undefined) {
      setCustomColoringBookArt(tunedOptions.customColoringBookArt);
    } else if (tunedOptions.customColoringBookTheme !== undefined) {
      setCustomColoringBookTheme(tunedOptions.customColoringBookTheme);
    }
  };

  const handleProcessImage = (response) => {
    const generatedImages = response.data;
    for (let i = 0; i < numberOfImages; i++) {
      const newImage = {
        id:
          selectedOption.simpleName === "wallart"
            ? generatedImages["image_id"][i]
            : generatedImages[i]["image_id"],
        url:
          selectedOption.simpleName === "wallart"
            ? generatedImages["image_url"][i]
            : generatedImages[i]["image_url"],
        revised_prompt:
          selectedOption.simpleName === "wallart"
            ? generatedImages["image_prompt"]
            : generatedImages[i]["image_prompt"],
        name: subject,
      };

      // Check if an image with the same URL already exists in the state
      const imageExists = (imageUrl) => {
        return imageUrls.some((image) => image.url === imageUrl.url);
      };

      // Update state only if the URL is not already present
      if (!imageExists(newImage.url)) {
        setImageUrls((prevImages) => [...prevImages, newImage]);
      }
    }
    setWaitingForResponse(false);
    scrollToGeneratedImage();
  };

  const handleModifiers = () => {
    const modifiers = {};
    if (
      selectedOption?.simpleName === "sticker" ||
      selectedOption?.simpleName === "tshirtart"
    ) {
      modifiers.mood = customMood;
      modifiers.art_style = customArtStyle;
      modifiers.color_style = customColorStyle;
    } else if (selectedOption?.simpleName === "wallart") {
      modifiers.art_style = customWallArtStyle;
      modifiers.color_style = customWallArtColorStyle;
    } else if (selectedOption?.simpleName === "coloring") {
      modifiers.animation_style = customColoringBookAnimation;
      modifiers.art_style = customColoringBootArt;
      modifiers.theme = customColoringBookTheme;
    }
    return modifiers;
  };

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleGenerate = async (e) => {
    setShowErrorAlert(false);
    setErrorAlertMessage("");
    setPromptSubmitted(false);
    setWaitingForResponse(true);
    setShowTuner(false);
    e.preventDefault();
    const tpts = tunePrompt();
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_BE}/api/generate`,
      data: {
        merch_type: selectedOption.simpleName,
        prompt: tpts,
        negative_prompt: selectedOption.negativePrompt,
        image_count: numberOfImages,
        name: subject,
        email: profile?.email,
        modifiers: handleModifiers(),
      },
    };

    axios
      .request(options)
      .then(function (response) {
        handleProcessImage(response);
        setShowQ1(false);
        setPromptSubmitted(true);
        setWaitingForResponse(false);
        scrollToGeneratedImage();
      })
      .catch(function (error) {
        console.error(error.message);
        setErrorAlertMessage(error.message);
        setWaitingForResponse(false);
        setShowErrorAlert(true);
      });
  };

  useEffect(() => {
    const count_promise = callCheckImageCount(profile?.email);
    count_promise
      .then((counts) => {
        setImageCount(counts.image_count);
        setDailyImageCount(counts.daily_image_count);
        setMonthlyImageCount(counts.monthly_image_count);
        
      })
      .catch((error) => {
        console.error("Error fetching image counts:", error);
        // Handle the error appropriately here, such as setting default values or showing an error message
        setImageCount(0); // Assuming you want to set default values in case of error
        setDailyImageCount(0);
      });
    // eslint-disable-next-line
  }, [imageUrls]);

  return (
    <>
      <NavButtons showTagLine={false} />

      <OnboardingStateDirector
        profile={profile}
        showPaywall={showPaywall}
        handleShowPaywall={handleShowPaywall}
      />

      {waitingForResponse && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waitingForResponse}
        >
          <Box
            component="img"
            sx={{
              // height: "auto",
              height: "200px",
              // width: "200px",
              display: "block",
              paddingTop: "0",
              margin: "0",
              animation: waitingForResponse ? "spin 7s linear infinite" : "",
            }}
            alt="MerchDesign AI logo"
            src={logo}
          />
        </Backdrop>
      )}

      {!waitingForResponse && showQ1 && imageUrls.length === 0 && (
        <Box sx={{ width: "fit-content", margin: "auto" }}>
          <Typography
            variant="h2"            
          >
            What are you creating?
          </Typography>          
        </Box>
      )}

      {profile?.email === "merchdesignai@gmail.com" && (
        <AdminNav profile={profile} />
      )}

      {/* {!waitingForResponse && showQ1 && imageUrls.length === 0 && !selectedOption?.simpleName && (
        <Box sx={{ width: "fit-content", margin: "auto", pt:"2rem" }}>
          <Typography
            variant="body"            
          >
            Select a category to get started {'\u2B07'}
          </Typography>          
        </Box>
      )} */}


      {waitingForResponse && !generateWithPixieDust && (
        <Box sx={{ width: "fit-content", margin: "auto" }}>
          <Typography variant="h5">
            Hang tight, creating your masterpiece!
          </Typography>
        </Box>
      )}

      {imageUrls.length > 0 && !waitingForResponse && (
        <Box sx={{ width: "fit-content", margin: "auto" }}>
          <Typography variant="h5">
            Boom! Here ya go! <InfoHelper merch={selectedOption.simpleName} />
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          margin: "auto",
          marginTop: "1em",
          display: "flex",
          maxWidth: isLargerThan1200 ? "80%" : "100%",
          justifyContent: "center",
        }}
      >
        {!waitingForResponse && showQ1 && (
          <>
            {/* <MerchTypeSelector handleChooseMerchandiseType={handleChooseMerchandiseType} /> */}
            {!showCreateMerch && !showCreateColoring && (
              <ChooseYourAdventure
                handleChooseMerchCategory={handleChooseMerchCategory}
                selectedOption={selectedOption}
              />
            )}

            {showCreateMerch && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "auto",
                }}
              >
                <Box sx={{ width: "100%", margin: "auto" }}>
                  <CreateMerch
                    handleChooseMerchandiseType={handleChooseMerchandiseType}
                    selectedOption={selectedOption}
                  />
                </Box>
                {!selectedOption?.simpleName && (
                  <>
                  <Typography sx={{mt:"1em"}} variant="body2">Select a category to get started {'\u2191'}</Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "1.5rem",
                      borderRadius: 5,
                      marginTop: "5%",
                      marginBottom: "5%",
                      marginRight: "1%",
                      color: "#ffffff",
                      backgroundColor: "#13b5cf",
                      "&:hover": {
                        color: "#13b5cf",
                        backgroundColor: "#ffffff",
                      },
                    }}
                    onClick={() => handleChooseMerchCategory("none")}
                  >
                    Go Back
                  </Button>
                  </>
                )}
              </Box>
            )}

            {showCreateColoring && (
              <>
                <Box sx={{ width: "100%", margin: "auto" }}>
                  <CreateColoring
                    handleChooseMerchandiseType={handleChooseMerchandiseType}
                    selectedOption={selectedOption}
                  />

                  {!selectedOption?.simpleName && (
                    <>
                    <Typography sx={{mt:"1em"}} variant="body2">Select a category to get started {'\u2191'}</Typography>
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "1.5rem",
                        borderRadius: 5,
                        marginTop: "5%",
                        marginBottom: "5%",
                        marginRight: "1%",
                        color: "#ffffff",
                        backgroundColor: "#13b5cf",
                        "&:hover": {
                          color: "#13b5cf",
                          backgroundColor: "#ffffff",
                        },
                      }}
                      onClick={() => handleChooseMerchCategory("none")}
                    >
                      Go Back
                    </Button>
                    </>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      {showErrorAlert && (
        <UserAlert
          title="Error Generating Image"
          message={`Error generating image, please try again. If the problem persists, please contact support with the error message: ${errorAlertMessage}`}
          severity="error"
        />
      )}

      <Box
        component="form"
        onSubmit={handleGenerate}
        alignContent="center"
        sx={{
          margin: "auto",
        }}
      >
        {imageUrls.length > 0 && (
          <Box
            component="div"
            ref={generatedImageRef}
            sx={{
              margin: "auto",
              width: "100%",
              maxWidth: "fit-content",
              display: "contents",
              marginBottm: "2em",
            }}
          >
            {imageUrls.map((image, index) => (
              <Paper
                key={index}
                sx={{
                  margin: "1%",
                  padding: "1%",
                  width: "250px",
                  display: "inline-table",
                  shadow: 3,
                }}
              >
                <a href="/designs">
                  <CustomHTMLToolTip
                    title="Click to view in Design Explorer"
                    body="Upscale, remove background, generate descriptions, organize into projects, export to docx files and more!"
                    children={
                      <Box
                        key={index}
                        component="img"
                        sx={{
                          height: "auto",
                          width: "250px",
                          cursor: "pointer",
                        }}
                        alt={`${image.name} Generated with MerchDesign AI`}
                        src={image.url}
                      />
                    }
                  />
                </a>
                <RateImage
                  image={image}
                  email={profile?.email}
                  showLabels={true}
                />
                <Visibility image_id={image.id} />
              </Paper>
            ))}
          </Box>
        )}
        {noAccessAllowed && !activeSubscription && (
          <Box
            sx={{
              width: "fit-content",
              margin: "auto",
              marginTop: "2em",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                padding: "1em",
                margin: "1em",
                backgroundColor: "lightyellow",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              {subscriptionCancelsAt !== "" ? (
                <span>
                  Your subscription has been cancelled since{" "}
                  <strong>{subscriptionCancelsAt}</strong>{" "}
                  <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
                  Go to your{" "}
                  <Link
                    className="plausible-event-name=App-Reactivate-Subscription"
                    href="/profile"
                    underline="hover"
                  >profile</Link>{" "} to reactivate.
                </span>
              ) : (
                <span>
                  <Typography variant="h5">
                    Trial Complete. Please{" "}
                    <Link
                      className="plausible-event-name=App-Page-JOY10"
                      href="/pricing"
                      underline="hover"
                    >
                      subscribe
                    </Link>{" "}
                    and keep the creative juices flowing.
                  </Typography>
                  <Typography variant="body2">
                    Pay only $6.75/mth when you use the code{" "}
                    <strong>JOY10</strong> and select the annual "Nib" plan.
                  </Typography>
                </span>
              )}
            </Paper>
          </Box>
        )}

        {noAccessAllowed && activeSubscription && (
          <Box
            sx={{
              width: "fit-content",
              margin: "auto",
              marginTop: "2em",
            }}
          >
            <Typography variant="h5">
              You've exceeded subscription limits. <br />
              You can generate more images when the daily or monthly limit
              resets. <br />
              If you've reached storage limit, you will need to upgrade.
            </Typography>
            <Typography variant="body1">
              Please upgrade if you need more images.
            </Typography>
            <Button href="/pricing">Upgrade</Button>
          </Box>
        )}

        {Object.keys(selectedOption).length !== 0 && !isUsageLimited && (
          <Box
            sx={{
              width: isLargerThan1200 ? "30%" : "50%",
              margin: "auto",
              marginTop: "2em",
            }}
          >
            {" "}
            <Typography>Number of Images </Typography>
            <Slider
              aria-label="Number of Images to Create"
              // defaultValue={1}
              step={1}
              marks
              min={1}
              max={4}
              value={sliderValue}
              valueLabelDisplay="on"
              onChange={handleSetSliderImageCount}
              sx={{
                display: !isLandingPage ? "block" : "inline-grid",
                width: isLargerThan1200 ? "100%" : "100%",
                margin: "auto",
                marginBottom: "1em",
                // marginLeft: "1rem",
              }}
            />
            {!noAccessAllowed && (
              <PromptInput
                showSubjectAlert={showSubjectAlert}
                handleSubjectChange={handleSubjectChange}
                handleShowTuner={handleShowTuner}
              />
            )}
          </Box>
        )}

        {Object.keys(selectedOption).length !== 0 &&
          !waitingForResponse &&
          !isUsageLimited &&
          showTuner && (
            <RadioPromptTuner
              isLargerThan1200={isLargerThan1200}
              merchType={selectedOption}
              promptTuningCallback={promptTuningCallback}
              promptSubmitted={promptSubmitted}
              coloringBookAnimation={customColoringBookAnimation}
              setColoringBookAnimation={setCustomColoringBookAnimation}
              coloringBookArt={customColoringBootArt}
              setColoringBookArt={setCustomColoringBookArt}
              coloringBookTheme={customColoringBookTheme}
              setColoringBookTheme={setCustomColoringBookTheme}
              customMood={customMood}
              setCustomMood={setCustomMood}
              customArtStyle={customArtStyle}
              setCustomArtStyle={setCustomArtStyle}
              customColorStyle={customColorStyle}
              setCustomColorStyle={setCustomColorStyle}
              wallArtStyle={customWallArtStyle}
              setWallArtStyle={setCustomWallArtStyle}
              wallArtColorStyle={customWallArtColorStyle}
              setWallArtColorStyle={setCustomWallArtColorStyle}
            />
          )}

        <Box
          component="div"
          sx={{
            margin: "auto",
          }}
        >
          {Object.keys(selectedOption).length !== 0 &&
            !waitingForResponse &&
            !isUsageLimited &&
            !noAccessAllowed && (
              <Button
                ref={optionsRef}
                id="create-button"
                variant="contained"
                type="submit"
                title={
                  imageUrls.length === 0
                    ? "Create an image that is closely matched to the subject"
                    : "Regenerate an image that is closely matched to the subject"
                }
                sx={{
                  fontSize: "1.5rem",
                  borderRadius: 5,
                  marginTop: "5%",
                  marginBottom: "5%",
                  marginRight: "1%",
                  backgroundColor: "#13b5cf",
                  "&:hover": {
                    color: "#13b5cf",
                    backgroundColor: "#ffffff",
                  },
                }}
                disabled={
                  subject?.length === 0 ||
                  waitingForResponse ||
                  Object.keys(selectedOption).length === 0
                }
              >
                {imageUrls.length === 0 ? "Create!" : "Create More"}
              </Button>
            )}

          {/* {imageUrls.length > 0 &&  */}
          {selectedOption?.simpleName && !waitingForResponse && (
            <Button
              id="restart-button"
              variant="contained"
              onClick={revertToInitialState}
              sx={{
                fontSize: "1.5rem",
                borderRadius: 5,
                marginTop: "5%",
                marginBottom: "5%",
                marginRight: "1%",
                backgroundColor: "#13b5cf",
                "&:hover": {
                  color: "#13b5cf",
                  backgroundColor: "#ffffff",
                },
              }}
              disabled={
                // subject?.length === 0 ||
                waitingForResponse || Object.keys(selectedOption).length === 0
              }
            >
              Go Back
            </Button>
          )}
        </Box>
      </Box>

      {/* <FooterActionButtons
        isLandingPage={isLandingPage}
        handleIsLanding={handleIsLanding}
      />
      <Box sx={{ marginBottom: "2em" }}></Box> */}
      <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
      <FooterNavLinks />
      <CommunityImageLinks />
      <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
    </>
  );
}
