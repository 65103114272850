import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ConfettiExplosion from 'react-confetti-explosion';
import CustomGoogleLoginButton from "../components/buttons/CustomGoogleLoginButton";
import LinkStripeAccount from "../components/account/LinkStripeAccount";
import { Divider } from "@mui/material";



export default function ThankYouNewSub() {  
  var email = "";
  try {
    email = localStorage.getItem("userInfo");
    email = JSON.parse(email).email;
  } catch (error) {
    // console.log("info:", error);
  }

  const handleCallback = () => {
    console.log("link stripe account activated");
  }
  return (
    <>
    <Paper
      elevation={3}      
      sx={{
        margin: "auto",
        padding: "2em",
        textAlign: "center",
        // maxWidth: "70%",
        width: { xs: '90%', sm: '80%', md: '70%' }, 
      }}
    >
      <Typography   variant="h2">Thank you and Welcome to MerchDesign AI!</Typography>

      <br />
      <Box sx={{ display: "block", width: "100%", margin: "auto" }}>
      <ConfettiExplosion
              particleCount={250}
              force={0.8}
              duration={3000}
              width={1600}
            />
      </Box>
      <Box sx={{ display: "flex", flexDirection:"row", width: "100%", margin: "auto", justifyContent:"center" }}>
       <Typography variant="h5" sx={{paddingTop:".25em", marginRight:".5em"}}>Login to your account</Typography><CustomGoogleLoginButton />
      </Box>
      <Divider sx={{marginTop: "2em", marginBottom:"2em"}} />
      {!email && (
      <Typography variant="body1" align="center">
          If you used a Stripe email address that differs from your MerchDesign
          email address, then login and navigate to your profile to link them.
        </Typography>
        )}
      {email && email.length > 0 && 
      <LinkStripeAccount mdAccountEmail={email} handleCallback={handleCallback}/>
  }
    </Paper>
    
    </>
  );
}
