import React, { useState, useEffect, useContext } from "react";
import userContext from "../../context/UserContext";
import {
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import NavButtons from "../../components/navigation/NavButtons";
import BookList from "./BookList";
import { useNavigate } from "react-router-dom";


const BookShelf = () => {
  const [bookList, setBookList] = useState([]);
  const [showBookSettings, setShowBookSettings] = useState(false);
  const [newBook, setNewBook] = useState({
    title: "",
    author: "",
    page_count: 25,
    format: "",
    back_page_style: "blank",
  });
  const [formatOptions, setFormatOptions] = useState([]);
  const { profile, APIToken, setSelectedBook } = useContext(userContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);

  const MAX_TITLE_LENGTH = 30;
  const MAX_AUTHOR_LENGTH = 20;

  const navigate = useNavigate();

  const BackPageStyleValues = Object.freeze({
    BLANK: "blank",
    BLACK: "black",
    NONE: "none",
  });

  
  const handleShowBookInterior = (bookId) => {
    console.log("Show book interior for book:", bookId);
    setSelectedBook(bookId);
    navigate(`/book-interior`);
  };

  useEffect(() => {
    const fetchBooks = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/books?email=${profile?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      ).then((response) => response.json());

      setBookList(response);
    };

    if (APIToken && profile?.email) {
      if (newBook.title === "") {
        fetchBooks();
      }
    }
  }, [APIToken, profile?.email, newBook]);

  useEffect(() => {
    const fetchFormatOptions = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/bookshelf/specifications?email=${profile?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      ).then((response) => response.json());

      setFormatOptions(response);
    };

    if (APIToken && profile?.email && formatOptions.length === 0) {
      fetchFormatOptions();
    }
    // eslint-disable-next-line
  }, [APIToken, profile?.email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBook((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New book:", newBook);
    fetch(`${process.env.REACT_APP_API_BE}/api/book/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify({
        email: profile?.email,
        title: newBook.title,
        author: newBook.author,
        specification: newBook.format,
        back_page_style: newBook.back_page_style,
        page_count: parseInt(newBook.page_count),
        global_font: "Arial",
        global_font_size: 12,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("New book created:", data);
        setNewBook({ title: "", author: "", page_count: "25", format: "" });
        setShowBookSettings(false);
      })
      .catch((error) => {
        console.error("Error creating new book:", error);
      });
  };

  const handleDeleteClick = (book) => {
    setBookToDelete(book);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setBookToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (bookToDelete) {
      fetch(`${process.env.REACT_APP_API_BE}/api/book/${bookToDelete.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            setBookList(bookList.filter((book) => book.id !== bookToDelete.id));
            console.log("Book deleted successfully");
          } else {
            throw new Error("Failed to delete book");
          }
        })
        .catch((error) => {
          console.error("Error deleting book:", error);
        });
    }
    handleCloseDeleteDialog();
  };

  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <NavButtons showTagLine={false} />
        {!showBookSettings && (
          <>
            <Typography variant="h4" gutterBottom>
              Your Bookshelf
            </Typography>
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
              {bookList.length === 0 ? (
                <Typography variant="body1" gutterBottom>
                  You don't have any books yet.
                </Typography>
              ) : (
                <BookList
                  books={bookList}
                  handleShowBookInterior={handleShowBookInterior}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </Paper>
            <Button
              variant="contained"
              onClick={() => setShowBookSettings(!showBookSettings)}
              sx={{
                fontSize: "1.5rem",
                borderRadius: 5,
                marginTop: "5%",
                marginBottom: "5%",
                marginRight: "1%",
                backgroundColor: "#13b5cf",
                "&:hover": {
                  color: "#13b5cf",
                  backgroundColor: "#ffffff",
                },
                cursor: "pointer",
              }}
            >
              Create New Book
            </Button>
          </>
        )}

        {showBookSettings && (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Book Settings
            </Typography>
            <TextField
              fullWidth
              maxLength={20}
              margin="normal"
              name="title"
              label="Title"
              value={newBook.title}
              onChange={handleInputChange}
              inputProps={{
                maxLength: MAX_TITLE_LENGTH
              }}
              helperText={`${newBook.title.length}/${MAX_TITLE_LENGTH}`}
              required
            />
            <TextField
              fullWidth
              maxLength={15}
              margin="normal"
              name="author"
              label="Author"
              value={newBook.author}
              onChange={handleInputChange}
              inputProps={{
                maxLength: MAX_AUTHOR_LENGTH
              }}
              helperText={`${newBook.author.length}/${MAX_AUTHOR_LENGTH}`}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="page_count"
              label="Number of Pages"
              type="number"
              value={newBook.page_count}
              onChange={handleInputChange}
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="format-label">Format</InputLabel>
              <Select
                labelId="format-label"
                name="format"
                value={newBook.format}
                onChange={handleInputChange}
                required
              >
                {formatOptions.map((format) => (
                  <MenuItem key={format.id} value={format.id}>
                    {format.name}: {format.description} ({format.trimsize_width}" x{" "}
                    {format.trimsize_height}")
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
            <InputLabel id="format-label">Back Page Style</InputLabel>
            <Select
              labelId="backpage-label"
              label="Back Page Style"
              name="backPageStyle"
              value={newBook.back_page_style || ''}
              onChange={handleInputChange}
              required
            >
              {Object.values(BackPageStyleValues).map((style) => (
                <MenuItem key={style} value={style}>
                  {style}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

            <Button
              onClick={() => setShowBookSettings(false)}
              variant="contained"
              sx={{
                mt: 2,
                marginRight: 2,
                borderRadius: 5,
                backgroundColor: "#13b5cf",
                "&:hover": {
                  backgroundColor: "#0f9eb5",
                },
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: 5,
                backgroundColor: "#13b5cf",
                "&:hover": {
                  backgroundColor: "#0f9eb5",
                },
              }}
            >
              Save Book
            </Button>
          </Box>
        )}

        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this book? Only the book will be deleted, not the source images.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default BookShelf;
