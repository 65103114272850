import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import UserContext from "../../context/UserContext";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { getPostHeaders } from "../../utils/headers";
import { callCheckImageCount } from "../../shared/callCheckImageCount";
// import { callCheckFeedbackStatus } from "../../shared/callCheckFeedbackStatus";

export default function FreeToTryButton({ minimal = false }) {
  const {
    // profile,
    setProfile,    
    setAPIToken,
    // setActiveSubscription,
    // setDesignLimit,
    setAccountHint,    
    setImageCount,  
    // setHasExtendedTrial,  
    setDailyImageCount,
    setMonthlyImageCount,
    setIsUsageLimited,
  } = useContext(UserContext);

  async function handleUser(profile) {
    let headers = getPostHeaders(
      {
        profile: profile,        
      },
      "POST"
    );
    const response = await fetch(`${process.env.REACT_APP_API_BE}/api/user`, {
      ...headers,
    });
    const resp = await response.json();
    if (response.status === 200) {
      setAccountHint(resp?.email);
    }
  }

  
  // async function checkSubscription(data) {
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_BE}/api/subscription/${data?.email}/`
  //   );
  //   await response.json().then((resp) => {
  //     console.log(`checkSub, ${resp}`);
  //     setActiveSubscription(resp[0]?.subscription_active || false);
  //     setDesignLimit(resp[0]?.design_limit || 0);
  //     localStorage.setItem("activeSubscription", resp[0]?.subscription_active);
  //     localStorage.setItem(
  //       "designLimit",
  //       resp[0]?.design_limit ? data[0]?.design_limit : 0
  //     );
  //   });
  // }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((response) => {
          if (response.status === 200) {  
            // debugger;          
            setProfile(response?.data);
            setAccountHint(response?.data?.email);
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            handleUser(response?.data).then((response) => {           
              setAPIToken(response);              
            });
            // checkSubscription(response?.data);            
            const count_promise = callCheckImageCount(response?.data?.email);
            count_promise
              .then((counts) => {
                setImageCount(counts.image_count);
                setDailyImageCount(counts.daily_image_count);
                setMonthlyImageCount(counts.monthly_image_count);
                setIsUsageLimited(counts.usage_limited);
              })
              .catch((error) => {
                // console.error("Error fetching image counts:", error);                
                setImageCount(0); // Assuming you want to set default values in case of error
                setDailyImageCount(0);
                setIsUsageLimited(false);
              });            
            localStorage.setItem("_md_k_", tokenResponse.access_token);            
            // if (profile?.email) {
            //   const feedback_promise = callCheckFeedbackStatus(
            //     profile?.email
            //   );
            //   feedback_promise
            //     .then((feedback) => {
            //       console.log(`FreeToTryButton.js, feedback?.feedback: ${feedback?.feedback}`);
            //       setHasExtendedTrial(feedback?.feedback ? true : false);
            //     })
            //     .catch((error) => {
            //       console.error("Error fetching feedback:", error);
            //       setHasExtendedTrial(false);
            //     });       
            // }
            window.location.href = "/app";
          }
        });
    },
    onError: (errorResponse) =>
      console.log(`Error in googleLogin, message: ${errorResponse}`),
  });

  return (
    <Box sx={{ marginBottom: "2em" }}>
      <Box sx={{ marginBottom: "1em", marginTop: "1em" }}>
        {minimal === false && (
        <Button
          variant="contained"
          onClick={googleLogin}
          sx={{
            fontSize: "1.5rem",
            borderRadius: 5,
            marginTop: "1em",
            marginBottom: "1em",
            marginRight: "1%",
            backgroundColor: "#ff6f00",
            "&:hover": {
              color: "#13b5cf",
              backgroundColor: "#ffffff",
            },
            // disabled: openSpinner === true ? true : false,
            // cursor: openSpinner === true ? "not-allowed" : "pointer",
          }}
        >
          Try now for free!
          <TouchAppIcon />
        </Button>)}
        {minimal === true && (
           <Button size="small" onClick={googleLogin}>Try Now</Button>
           )}

      </Box>
    </Box>
  );
}
