import React, {useRef, useEffect} from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchBox({searchTerm, setSearchTerm}) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (searchTerm === "") {
      inputRef.current.value = "";
    }
  }, [searchTerm]);


  const handleSearch = (e) => {
    // setSearchTerm(e.currentTarget.previousElementSibling.value);    
    setSearchTerm(inputRef.current.value);
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchTerm(e.target.value);          
    }
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <TextField
        inputRef={inputRef}
        fullWidth
        placeholder="What are you looking for?"
        onKeyDown={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{cursor:"pointer"}} onClick={handleSearch}>
              <SearchIcon />
            </InputAdornment>
          ),
          style: {
            textAlign: 'center',
          },
        }}
        inputProps={{
          style: {
            textAlign: 'center',
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '4rem',
            borderStyle: 'solid',
            borderWidth: '6px',
            borderColor: '#0cb5ce',
            backgroundColor: 'white',
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
          },
          '& input::placeholder': {
            textAlign: 'center',
          },
        }}
      />
    </Box>
  );
}

export default SearchBox;