import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const BookList = ({ books, handleShowBookInterior, handleDeleteClick }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
      {books.map((book) => (
        <Box
          key={book.id}
          sx={{
            position: 'relative',
            width: '1.5in',
            height: '2in',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#f9f9f9',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
        >
          <Box
            onClick={() => handleShowBookInterior(book.id)}
            sx={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              {book.title}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', fontStyle: 'italic' }}>
              by {book.author}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 'auto', overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap' }}>
              {book.page_count} pages
            </Typography>
          </Box>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(book);
            }}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '4px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default BookList;

// TODO Add List View
// import { List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';

// const BookList = ({ books, handleShowBookInterior, handleDeleteClick }) => {
//   return (
//     <List>
//       {books.map((book) => (
//         <ListItem
//           key={book.id}
//           secondaryAction={
//             <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(book)}>
//               <DeleteIcon />
//             </IconButton>
//           }
//         >
//           <ListItemText
//             primary={
//               <Typography
//                 variant="body1"
//                 style={{ cursor: 'pointer' }}
//                 onClick={() => handleShowBookInterior(book.id)}
//               >
//                 {book.title} by {book.author}
//               </Typography>
//             }
//             secondary={`${book.page_count} pages`}
//           />
//         </ListItem>
//       ))}
//     </List>
//   );
// };

// export default BookList;
// import React from 'react';
// import { Box, Typography } from '@mui/material';


// const BookList = ({ books, handleShowBookInterior }) => {
//   return (
//     <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
//       {books.map((book) => (
//         <Box
//           key={book.id}
//           onClick={()=>handleShowBookInterior(book.id)}
//           sx={{
//             width: '1in',
//             height: '1.5in',
//             border: '1px solid #ccc',
//             borderRadius: '4px',
//             boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//             padding: '20px',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             backgroundColor: '#f9f9f9',
//             transition: 'transform 0.2s',
//             '&:hover': {
//               transform: 'scale(1.02)',
//             },
//           }}
//         >
//           <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
//             {book.title}
//           </Typography>
//           <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', fontStyle: 'italic' }}>
//             by {book.author}
//           </Typography>
//           <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 'auto' }}>
//             {book.page_count} pages
//           </Typography>
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default BookList;