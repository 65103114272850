import React, { useState, useEffect } from "react";

import { Box, Rating } from "@mui/material";

export default function RateImage({ image, email, showLabels = true }) {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(-1);
  const image_id = image?.image_id ? image.image_id : image.id;
  const labels = {
    0.0: "Not Rated",
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value) {
    return `${rating} Star${rating !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  useEffect(() => {
    if (image?.rating !== undefined) {
      setRating(image.rating);
    }
  }, [image]);

  const handleSetRating = (value) => {
    setRating(value);
    if (value !== 0 && image?.id !== undefined) {
      console.log(`saving rating for image_id = ${image_id}`);
      fetch(`${process.env.REACT_APP_API_BE}/api/rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          image_id: image_id,
          rating: value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Rating saved: ", data);
        });
    }
  };

  // get the rating from the database
  useEffect(() => {
    if (image?.image_id !== undefined) {
      fetch(`${process.env.REACT_APP_API_BE}/api/rate?image_id=${image_id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setRating(data[0]?.rating);
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Rating
        name="hover-feedback"
        value={rating}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          handleSetRating(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {showLabels && rating !== null && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
      )}
    </div>
  );
}
