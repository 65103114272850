import React, { useContext } from "react";
// import axios from "axios";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import IconButton from "@mui/material/IconButton";
import UserContext from "../../context/UserContext";
import { callUpscale } from "../../shared/callUpscale";


// handleVariationUpscaleId
function UpscaleButton({
  item,
  isUpscaled,
  handleIsUpscaled,
  waitingForResponse,
  handleIsWaitingForResponse,
  handleSetVariationUrl,
}) {
  const { profile, APIToken } = useContext(UserContext);

  const handleUpscaleClick = () => {
    const getUpscaledGeneration = async () => {
      handleIsWaitingForResponse(true);      
      callUpscale({
        item: item,
        profile: profile,
        APIToken: APIToken,
        handleSetVariationUrl: handleSetVariationUrl,
        handleIsUpscaled: handleIsUpscaled,
      })
        .then((response) => {          
          handleIsWaitingForResponse(false);
          window.open(`${response.data.upscale_url}`, "_blank");
        })
        .catch((err) => {
          console.error(err);
          handleIsWaitingForResponse(false);
        });      
    };    
    if (profile?.given_name !== "Guest") {      
      getUpscaledGeneration();
    }
  };

  
  const getTitle = () => {
    if (profile?.given_name === "Guest") {
      return "Available with paid accounts only";
    } else {
      return "Upscale for printing on merch";
    }
  };

  return (
    <IconButton
      sx={{
        color: isUpscaled || profile?.given_name === "Guest" ? "rgba(0, 0, 0, 0.54)" : "rgba(0, 0, 0)",
        disabled:
          isUpscaled
            ? true
            : false,
        cursor:
          isUpscaled
            ? "not-allowed"
            : "pointer",
        animation:
          waitingForResponse && !isUpscaled ? "spin 7s linear infinite" : "",
      }}
      // disabled={isButtonDisabled()}
      aria-label={`upscale for printing on merch`}
      title={getTitle()}
      onClick={handleUpscaleClick}
    >
      <SettingsOverscanIcon
        sx={{
          marginRight: "0.5em",          
        }}
      />
      Upscale 
    </IconButton>
  );
}

export default UpscaleButton;
