// an array of objects containing the questions for the prompt

// STICKER TEMPLATE: Subject, STICKER, Mood, Style of Colors, Art Style, CONTOUR, VECTOR, WHITE BACKGROUND, Level of detail
// MOODS: Content, Joyful, adorable, lovely, cute, blissful, energetic, exhilirated
// STYLE of COLORS: warm colors, cool colors, satin colors, dark, muted colors, electric colors, primary colors, secondary colors, tertiary colors
// ART STYLE: Disney, Digital Art, Geometric, Minimal, Disney Pixar, Art Brut, Art Toy, Anime

export const moods = [  
  "Content",
  "Cute",
  "Blissful",
  "Energetic",
  "Exhilarated",
  "Grumpy",
  "Joyful",
  "Judgemental",
  "Lazy",
  "Sleepy",
];
export const art_styles = [
  "Anime",
  "Digital Art",
  "Geometric",
  "Minimal",    
  "Pixel Art",
  "Pop Art",
  "Realistic",
];
export const color_styles = [  
  "Cool Colors",
  "Dark Colors",
  "Electric Colors",
  "Muted Colors",
  "No Color",
  "Primary Colors",
  "Satin Colors",
  "Secondary Colors",
  "Warm Colors",
];
export const wall_art_styles = [  
  "Abstract",
  "Ink Wash",
  "Modern",
  "Photo-realistic",
  "Pop Culture",
  "Sleek",
  "Vintage",
];
export const wall_art_color_styles = [  
  "Black & White",
  "Blue & Yellow Colors",
  "Cool Colors",
  "Neon",
  "No Color",
  "Pastel Colors",
  "Turquoise Green & Pink Colors",
  "Warm Colors",
];
export const coloring_book_animation_styles = [    
  "Anime",
  "Cartoon",
  "Chibi",
  "Child's Drawing",
  "Comic Book",
  "Crayon",  
  "Illustration",  
  "Realistic",
];
export const coloring_book_art_styles = [  
  "None",
  "Floral and Nature",
  "Mandalas/Geometric",
  "Stained Glass",
  "Symmetry",  
  "Victorian/Vintage",
  "Zentangle",  
];
export const coloring_book_themes = ["Less Detail", "Minimal Detail", "More Detail"];

// var coloringBookPrompt = `A clean coloring book page illustration with clean, solid black outlines suitable for a coloring book page. The illustration should be of [SUBJECT] with a white background and black outline only.Ensure the image has no shading, color, or excessive detail, making it ideal for coloring.`;
export const visualDesignOptions = [
  {
    name: "Sticker",
    simpleName: "sticker",
    displayName: "Sticker",    
    image: "corgi.webp",
    sx: 'margin:"1em"',    
    prompt: `[SUBJECT], [MOOD], [ART_STYLE], [COLOR_STYLE] die cut sticker illustration. Must have a thick white border surrounding subject as is custom for die cut stickers`,
    negativePrompt:"",      
    widthAndHeight: { width: 512, height: 512 },
  },  
  {
    name: "Wall Art",
    simpleName: "wallart",
    displayName: "Notebook Cover",
    image: "WallArt.webp",
    sx: 'margin:"1em"',
    // prompt: `flat design, not too complex, modern, 4k, epic composition, flat vector art illustration,long shot landscape print of [SUBJECT]`,
    prompt: ` [SUBJECT] flat design, not too complex, [WALL_ART_STYLE], 4k, flat vector art illustration, [WALL_ART_COLOR_STYLE] epic composition,long shot landscape print`,
    negativePrompt:
      "wall, living room, visible text, visible words, text, fonts, letters, words, watermark, signature. noisy, text, writing, watermark, out of frame, bad anatomy, deformed, extra limbs, out of shot, out of focus,oversaturation",
    widthAndHeight: { width: 624, height: 936 },
  },  
  {
    name: "T-shirt",
    simpleName: "tshirtart",
    displayName: "T-shirt Art",
    image: "./bus_tshirt.webp",
    sx: `{{padding-left: '2em', padding-right: '2em', margin:'1em'}}`,
    prompt:
      "A vector art illustration of [SUBJECT], [MOOD], [ART_STYLE], [COLOR_STYLE]. Ensure that the illustration is visually appealing. The background should be pure white (#FFFFFF) to ensure high contrast with the subject: [SUBJECT]. Do not include elements outside the subject such as text unless explicitly mentioned as part of the subject",
    negativePrompt: "",      
    widthAndHeight: { width: 768, height: 768 },
  },  
  // {
  //   name: "Coloring Pages",
  //   simpleName: "coloring",
  //   displayName: "Coloring Page",
  //   image: "./flowers.webp",

  //   // prompt: ` coloring book, no background, black outline only, full white background, only use outline, line art, coloring book, clean line art of a [SUBJECT]`,
  //   // prompt: ` coloring book, [SUBJECT], [COLORING_BOOK_ANIMATION], [COLORING_BOOK_ART], [COLORING_BOOK_THEME], black and white line drawing, medium line weight`,
  //   // prompt: `Coloring book illustration of [SUBJECT] with clean, thick black outlines, white background, black outline only. Ensure there is no shading, color, or high detail, keeping the image simple and inviting for coloring. Preferred animation syle: [COLORING_BOOK_ANIMATION], preferred art syle: [COLORING_BOOK_ART], preferred theme: [COLORING_BOOK_THEME]`,
  //   // prompt: `A clean coloring book page illustration with clean, solid black outlines suitable for a coloring book page. The illustration should be of [SUBJECT] with a white background and black outline only.Ensure the image has no shading, color, or excessive detail, making it ideal for coloring.`,
  //   // prompt: `[SUBJECT] illustration for a coloring book page, with animation style [COLORING_BOOK_ANIMATION], art style [COLORING_BOOK_ART], theme [COLORING_BOOK_THEME], black outline only, white background, no shading, no color, no excessive detail`,
  //   // Do not show a color palette. Do not show hands unless they are mentioned in the subject. 
  //   // prompt: `Using the subject: [SUBJECT], create a [COLORING_BOOK_THEME] illustration for a coloring book page, in the [COLORING_BOOK_ANIMATION] style, and [COLORING_BOOK_ART] style, black outline only, white background, no shading, no color, no excessive detail. The subject should be centered and fill the frame. Only include the subject, do not stray from the subject. Do not include extras not mentioned in the subject. Surround the subject with whitespace. Please do not include any hands, pencils, or color palettes in the image, and focus solely on the subject`,

  //   prompt: `a line art illustration for a coloring book page of a [SUBJECT] with [COLORING_BOOK_THEME], in the [COLORING_BOOK_ANIMATION] style, and [COLORING_BOOK_ART] style. Black outline only, white background, no shading, no color, no excessive detail. Ensure there is enough whitespace to guarantee a pleasurable coloring experience`,
  //   negativePrompt: "",
  //   // negativePrompt:
  //   //   "color palette, pink, green, yellow, blue, grey, gray, colors, shadows, shading, text, fonts, letters, words, watermark",
  //   widthAndHeight: { width: 512, height: 768 },
  // },
  // {
  //   name: "Logo",
  //   simpleName: "logo",
  //   image:
  //     "https://cdn.leonardo.ai/users/fa172ef4-0f18-4154-b77f-f29580bed52d/generations/a6e7b2f1-d058-4f67-a81c-902e10412eda/variations/Default_in_the_beautiful_old_town_beautiful_street_cool_tuned_1_a6e7b2f1-d058-4f67-a81c-902e10412eda_1.jpg",
  //   prompt: 'stunning logo for marketing agency, using a {subject}, minimalist style, vibrant, ux, ui',
  //   negativePrompt: "no text, fonts, letters, words, watermark",
  //   widthAndHeight: { width: 512, height: 512 },
  // },
];

// export default visualDesignOptions;
