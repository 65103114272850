import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import NavButtons from "../../../components/navigation/NavButtons";
import FooterNavLinks from "../../../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../../../components/navigation/CommunityImageLinks";
import VideoCard from "../../../components/VideoCard";


export default function RemoveElementsFromImage() {
    const title = "How to Quickly Remove Elements from an Image";
    const steps = [
        "From the design explorer, select the image you want to edit, then click the 3 dot menu and select 'Edit Image'",
        "In the editor, select the Draw tool",
        "Use the color picker to select the color closest to the element you want to remove",
        "Draw over the element you want to remove",
        "Click the 3 dot menu and select 'Save Image'",
        "Your edited image will be saved to your design library",
        "That's it! You've successfully removed elements from an image",
        "Download your edited image using the download button",
];
  return (
    <>
    <NavButtons showTagLine={false}/>
      <Paper
        elevation={3}
        sx={{
          margin: "auto",
          padding: "2em",
          textAlign: "left",          
          width: { xs: "90%", sm: "80%", md: "70%" },
        }}
      >
        <Typography variant="h2">How to Quickly Remove Elements from an Image</Typography>

        <br />
        <VideoCard video_id={"w9gwxt6pDLU"} title={title} steps={steps}/>
      </Paper>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}
