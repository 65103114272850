import React, { useState } from "react";
import { Alert } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Input } from "@mui/material";
import { Typography } from "@mui/material";
import axios from "axios";

export default function LinkStripeAccount({ mdAccountEmail, handleCallback, showDescription=true }) {
  const [stripeEmail, setStripeEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled ] = useState(true);

  // fetch the user's stripe email address
  // when they type it in the input field, update the stripe email address in the state
  // when they click the button, call the handleLinkAccount function
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStripeEmail((prevStripeEmail) => ({
      ...prevStripeEmail,
      [name]: value,
    }));
    setIsButtonDisabled(false);
    setError(false);
    setSuccess(false);
  };

  
  
const handleLinkAccount = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BE}/api/linkstripe/`,
        {
          stripe_email: stripeEmail.email,
          md_email: mdAccountEmail,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setError(false);        
      } else {
        setError(true);
        setSuccess(false);
      }
    } catch (error) {
      setError(true);
      setSuccess(false);
    }
    setIsButtonDisabled(true);
    handleCallback();
  };

  return (
    <Box component="div">
      <Typography variant="h4">Link Stripe Account</Typography>
      <Box component="div" sx={{
    marginLeft: { xs: 0, sm: "8em" },
    marginRight: { xs: 0, sm: "4em" },
    marginBottom: { xs: "2em", sm: 0 },
  }}>
      {showDescription && (
        <Typography variant="body1" align="left">
          If you used a Stripe email address that differs from your MerchDesign
          email address: {mdAccountEmail}, then enter your Stripe email below to
          link them.
        </Typography>
      )}
      </Box>
      <Input
        name="email"
        onChange={handleInputChange}
        type="email"
        placeholder="Email"
        sx={{ width: "300px" }}
      />
      <Button
        variant="outlined"
        onClick={handleLinkAccount}
        sx={{ marginLeft: "2em", marginTop: "1em" }}
        disabled={isButtonDisabled}
      >
        Link Stripe Account
      </Button>
      {error && (
        <Typography variant="body1" color="error">
          Error linking stripe account, please reach out to support@merchdesign.ai
        </Typography>
      )}
      {success && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Successfully linked stripe account! You can now access your subscription.
        </Alert>
      )}
    </Box>
  );
}
