import React  from "react";

import {
  Box,
  Typography,
} from "@mui/material";
// import ColorLensIcon from '@mui/icons-material/ColorLens';
// import FooterActionButtons from "../components/FooterActionButtons";
import Header from "../components/Header";

// Tunes prompts for stickers with 1 image selected only cost 2 tokens
// or prob about 6 tokens with the prod api without promptmagic and
// 8 tokens with promptmagic

export default function Landing() { 
  return (
    <>
      <Header
        isLandingPage={true}
        waitingForResponse={false}
      />
        <Box sx={{ width: "fit-content", margin: "auto" }}>
          <Typography variant="h5">
            Thanks for stopping by!             
          </Typography>
          <br/>
          <Box sx={{ width: "40%", margin: "auto" }}>
          <Typography variant="h5">
          Sorry for the disruption, we are polishing up some sharp edges to improve your experience.<br/>Please stop by later!
          </Typography>
          </Box>
        </Box>                   
      <Box sx={{ marginBottom: "2em" }}></Box>
    </>
  );
}
