import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import NavButtons from "../components/navigation/NavButtons";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";


export default function Support() {
  return (
    <>
    <NavButtons showTagLine={false}/>
      <Paper
        elevation={3}
        sx={{
          margin: "auto",
          padding: "2em",
          textAlign: "left",
          // maxWidth: "70%",
          width: { xs: "90%", sm: "80%", md: "70%" },
        }}
      >
        <Typography variant="h2">MerchDesign AI Support</Typography>

        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="support-content"
            id="support-content"
          >
            <Typography>How to Contact Support</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Please email us at{" "}
              <Box component="span" sx={{ fontWeight: 800 }}>
                merchdesignai at gmail dot com{" "}
              </Box>
              Please include your name, email address, and a description of the
              problem you are experiencing. Please put [SUPPORT] in the header
              for the fastest response. We will get back to you as soon as
              possible, typically within 24 hours.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>New Subscribers Read This</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              After subscribing, you will need to{" "}
              <Box component="span" sx={{ fontWeight: 800 }}>
                log out and log back in
              </Box>{" "}
              to see the full functionality of the site. You may also need to
              refresh your browser after logging in. If you are still having
              trouble, please contact support.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="subcription-email"
          >
            <Typography>
              I subscribed but still can't create new images
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We use Stripe to process payments. If you used a different email
              during the checkout with Stripe than the one you used to log in to
              MerchDesign.AI, you won't see your account reflect the change. You
              will need to contact us. Please send the email address you used
              for payment and the related email address for MerchDesign.AI, and
              we will adjust your account accordingly. Use '[Upgrade Help]' in
              the subject for the fastest response. Contact email:
              support@merchdesign.ai.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="subcription-usecase"
          >
            <Typography>
I just need to use to use MerchDesign AI for a project, can I subscribe for one month?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
Yes! Simply select the monthly subscription option and cancel before the next billing cycle. You will have access to the full functionality of the site for the remainder of the month. You can cancel at any time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography>When editing image, selected font not displaying as expected</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              If you are having trouble with the fonts displaying as expected,
              please download and install. On a Mac you can double click the
              font file and click install. <br />
              On Windows you can right click the font file and click install. If
              you are still having trouble, please contact support.
              <br />
              <br />
              <a
                href="https://dl.dafont.com/dl/?f=hand_stencil"
                target="_blank"
                rel="noreferrer"
              >
                Hand Stencil Font
              </a>
              <br />
              <a
                href="https://dl.dafont.com/dl/?f=fegan"
                target="_blank"
                rel="noreferrer"
              >
                Fegan Font
              </a>{" "}
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography>
              Can I use the images I create with MerchDesign AI for commercial
              purposes?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, you can use the images you create with MerchDesign AI for
              commercial purposes, including reprinting or selling them. Both
              Open AI and Leonardo, which we utilize, allow for commercial use
              subject to their content policies and terms, which we have linked
              below.
              <br />
              <br />
              You own the images you generate, but you are ultimately
              responsible for ensuring that they do not infringe on the
              copyrights of others. For instance, if you create an image
              resembling a protected logo, such as the Nike logo, this would be
              considered infringement. We do not support or authorize the use of
              our platform to circumvent or facilitate any non-compliant use.
              <br />
              <br />
              As AI technology evolves, we may incorporate other providers that
              allow for commercial use. If this happens, we will update our
              Terms of Service page accordingly. <br />
              <br />
              <a
                href="https://openai.com/policies/usage-policies/"
                target="_blank"
                rel="noreferrer"
              >
                OAI Usage Policies
              </a>
              <br />
              <a
                href="https://openai.com/policies/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                OAI Terms of Use
              </a>
              <br />
              <a
                href="https://leonardo.ai/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Leonardo Terms of Service
              </a>
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}
