import React from "react";
// import FontFaceObserver from 'fontfaceobserver';
import { Select, MenuItem } from "@mui/material";
import { Box } from "@mui/system";

function FontSelector({ fontFamily, handleSetFontFamily }) {
  const availableFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    'Fegan',
    "Georgia",
    "Helvetica",
    "Impact",    
    "Lucida Console",
    "Hand Stencil",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Palatino Linotype",        
    "Verdana",
    
  ];

  return (
    <Box component="div">
      <Select
        onChange={(e) => handleSetFontFamily(e.target.value)}
        value={fontFamily}
      >
        {availableFonts.map((font) => (
          <MenuItem key={font} value={font}>
            {font}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default FontSelector;
