import React from "react";
import {
  getBackgroundColor,
  getTextColor,
  getFontWeight,
} from "../../properties/ui-properties";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import RotatingImageCard from "./RotatingImageCard";

export default function ChooseYourAdventure({
  handleChooseMerchCategory,
  selectedOption,
}) {
  return (
    <Container maxWidth="lg" >
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <RotatingImageCard
              images={[
                "./slothtshirt.webp",
                "./hoodie.webp",
                "./frog.webp",
                "./oceanwave.webp",
              ]}
              title={["Merch", "Hoodie & T-Shirt Art", "Sticker Art", "Notebook & Wall Art"]}
              onClick={() => handleChooseMerchCategory("merch")}
              isSelected={selectedOption === "merch"}
              getBackgroundColor={getBackgroundColor}
              getTextColor={getTextColor}
              getFontWeight={getFontWeight}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <RotatingImageCard
              images={["./celticknots.webp", "./flowers.webp", "./beachcolor.webp"]}
              title={["Coloring", "Pages & Books", "Upload Your Own Photo"]}
              onClick={() => handleChooseMerchCategory("coloring")}
              isSelected={selectedOption === "coloring"}
              getBackgroundColor={getBackgroundColor}
              getTextColor={getTextColor}
              getFontWeight={getFontWeight}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}