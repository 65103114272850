import React from "react";
import NavButtons from "../navigation/NavButtons";
import FooterNavLinks from "../navigation/FooterNavLinks";
import CommunityImageLinks from "../navigation/CommunityImageLinks";

function FooterActionButtons({isLandingPage, handleIsLanding}) {
  
  return (
    <>
      <NavButtons isLandingPage={isLandingPage} handleIsLanding={handleIsLanding} />
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}

export default FooterActionButtons;
