import React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import StickerTunerOptions from "./StickerTunerOptions";
import WallArtTunerOptions from "./WallArtTunerOptions";
import ColoringBookTunerOptions from "./ColoringBookTunerOptions";
import TShirtTunerOptions from "./TShirtTunerOptions";
// TODO: Uadd unset
// STICKER TEMPLATE: Subject, STICKER, Mood, Style of Colors, Art Style, CONTOUR, VECTOR, WHITE BACKGROUND, Level of detail

export default function RadioPromptTuner({
  isLargerThan1200,
  merchType,
  promptTuningCallback,
  coloringBookAnimation,
  setColoringBookAnimation,
  coloringBookArt,
  setColoringBookArt,
  coloringBookTheme,
  setColoringBookTheme,
  customMood,
  setCustomMood,
  customArtStyle,
  setCustomArtStyle,
  customColorStyle,
  setCustomColorStyle,
  wallArtStyle,
  setWallArtStyle,
  wallArtColorStyle,
  setWallArtColorStyle,
}) {
  const handleMoodChange = (event) => {
    setCustomMood(event.target.value);
    handlePromptTuningCallback("customMood", event.target.value);
  };

  const handleArtStyleChange = (event) => {
    setCustomArtStyle(event.target.value);
    handlePromptTuningCallback("customArtStyle", event.target.value);
  };

  const handleColorStyleChange = (event) => {
    setCustomColorStyle(event.target.value);
    handlePromptTuningCallback("customColorStyle", event.target.value);
  };

  const handleWallArtStyleChange = (event) => {
    setWallArtStyle(event.target.value);
    handlePromptTuningCallback("wallArtStyle", event.target.value);
  };

  const handleWallArtColorStyleChange = (event) => {
    setWallArtColorStyle(event.target.value);
    handlePromptTuningCallback("wallArtColorStyle", event.target.value);
  };

  const handleColoringBookAnimationChange = (event) => {
    setColoringBookAnimation(event.target.value);
    handlePromptTuningCallback("coloringBookAnimation", event.target.value);
  };

  const handleColoringBookArtChange = (event) => {
    setColoringBookArt(event.target.value);
    handlePromptTuningCallback("coloringBookArt", event.target.value);
  };

  const handleColoringBookThemeChange = (event) => {
    setColoringBookTheme(event.target.value);
    handlePromptTuningCallback("coloringBookTheme", event.target.value);
  };

  function handlePromptTuningCallback(tuner, value) {
    if (tuner === "customMood") {
      promptTuningCallback({ customMood: value });
    } else if (tuner === "customArtStyle") {
      promptTuningCallback({ customArtStyle: value });
    } else if (tuner === "customColorStyle") {
      promptTuningCallback({ customColorStyle: value });
    } else if (tuner === "wallArtStyle") {
      promptTuningCallback({ customWallArtStyle: value });
    } else if (tuner === "wallArtColorStyle") {
      promptTuningCallback({ customWallArtColorStyle: value });
    } else if (tuner === "coloringBookAnimation") {
      promptTuningCallback({ customColoringBookAnimation: value });
    } else if (tuner === "coloringBookArt") {
      promptTuningCallback({ customColoringBookArt: value });
    } else if (tuner === "coloringBookTheme") {
      promptTuningCallback({ customColoringBookTheme: value });
    } else {
      console.log("no tuner value");
    }
  }

  return (
    <Box
      sx={{
        width: isLargerThan1200 ? "40%" : "90%",
        margin: "auto",
        marginTop: "3em",
        display: "block",
        transform: isLargerThan1200 ? "translate(2%, 0%)" : "translate(0%, 0%)",
      }}
    >
      <Divider sx={{ marginBottom: "2em" }} />

      {merchType?.name === "Sticker" && (
        <StickerTunerOptions
          customMood={customMood}
          customArtStyle={customArtStyle}
          customColorStyle={customColorStyle}
          handleMoodChange={handleMoodChange}
          handleArtStyleChange={handleArtStyleChange}
          handleColorStyleChange={handleColorStyleChange}
        />
      )}
      {merchType?.name === "T-shirt" && (
        <TShirtTunerOptions
          customMood={customMood}
          customArtStyle={customArtStyle}
          customColorStyle={customColorStyle}
          handleMoodChange={handleMoodChange}
          handleArtStyleChange={handleArtStyleChange}
          handleColorStyleChange={handleColorStyleChange}
        />
      )}
      {merchType?.name === "Wall Art" && (
        <WallArtTunerOptions
          wallArtStyle={wallArtStyle}
          wallArtColorStyle={wallArtColorStyle}
          handleWallArtStyleChange={handleWallArtStyleChange}
          handleWallArtColorStyleChange={handleWallArtColorStyleChange}
        />
      )}
      {merchType?.name === "Coloring Pages" && (
        <ColoringBookTunerOptions
          coloringBookAnimation={coloringBookAnimation}
          coloringBookArt={coloringBookArt}
          coloringBookTheme={coloringBookTheme}
          handleColoringBookAnimationChange={handleColoringBookAnimationChange}
          handleColoringBookArtChange={handleColoringBookArtChange}
          handleColoringBookThemeChange={handleColoringBookThemeChange}
        />
      )}
    </Box>
  );
}
