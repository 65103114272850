import React from "react";

import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import FreeToTryButton from "../components/buttons/FreeToTryButton";
import VideoCard from "../components/VideoCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavButtons from "../components/navigation/NavButtons";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";


export default function VideoBrowser() {
  const profile = localStorage.getItem("userInfo");
  const isMobile = useMediaQuery("(max-width:600px)");  

  return (
    <>
      <NavButtons showTagLine={profile === null ? true : false}/>
      <Box
        component="div"
        sx={{
          margin: "auto",          
          width: isMobile ? "95%" : "60%",
          maxWidth: "100%",
          overflow: "wrap", // or "auto" for scrollbars
          whiteSpace: "wrap", // Prevent line breaks
          textOverflow: "ellipsis", // Add ellipsis for truncated text
          marginTop: "2em",
        }}
      >
       
      </Box>
     
      
      <Box
        component="div"
        sx={{
          margin: "auto",          
          width: isMobile ? "95%" : "60%",
          maxWidth: "100%",
          overflow: "wrap", // or "auto" for scrollbars
          whiteSpace: "wrap", // Prevent line breaks
          textOverflow: "ellipsis", // Add ellipsis for truncated text
          marginTop: "4em",
        }}
      >
      <Typography
        variant="h4"
        color="#08b0cc"
        sx={{ maxWidth: isMobile ? "95%" : "80%",width: isMobile ? "95%" : "80%",  margin: "auto", fontWeight: "800", fontSize: "2rem" }}
      >
        Watch and Learn How to Create Coloring Book and Sticker Designs in Seconds
      </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          margin: "auto",
          width: "100%",
          paddingLeft: "1em",
          paddingRight: "1em",
          // maxWidth: "100%",
          overflow: "wrap", // or "auto" for scrollbars
          whiteSpace: "wrap", // Prevent line breaks
          textOverflow: "ellipsis", // Add ellipsis for truncated text
        }}
      >    
      <VideoCard
          video_id="-UM_sTKrpyQ"
          title="Use the Book Builder to Create Coloring Books"
          subheader="Ready-to-print interiors for Amazon KDP"
          body="Create coloring book interiors for Amazon Kindle Direct Publishing (KDP) with MerchDesign AI. Our Book Builder lets you create and arrange your book in minutes."          
        />   
        <VideoCard
          video_id="OwYDHNJ606c"
          title="Design Creation"
          subheader="Unleash Your Creativity Across Multiple Mediums"
          body="Easy print-on-demand design creation, craft stunning designs that can be used for t-shirts, stickers, phone grips, wall art, totes, phone cases and more! MerchDesign AI, the right AI design tool for your print-on-demand business"
        />
        
         <Box
        component="div"
        sx={{
          margin: "auto",          
          width: isMobile ? "95%" : "60%",
          maxWidth: "100%",
          overflow: "wrap", // or "auto" for scrollbars
          whiteSpace: "wrap", // Prevent line breaks
          textOverflow: "ellipsis", // Add ellipsis for truncated text
          marginTop: "4em",
        }}
      >
  <Typography
        variant="h4"
        color="#08b0cc"
        sx={{ maxWidth: isMobile ? "95%" : "80%",width: isMobile ? "95%" : "80%",  margin: "auto", fontWeight: "800", fontSize: "2rem" }}
      >
        Watch and Learn How to Remove Backgrounds for Printworthy T-Shirt Designs
      </Typography>
      </Box>
        <VideoCard
          video_id="fPfPdp3F0wM"
          title="Remove the Background"
          subheader="Perfect for Stickers and T-Shirt Art"
          body="Scale up, remove background, create merch! MerchDesign AI can help you create stickers and t-shirt art in minutes."
        />
       
        {profile !== null && (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = "/app";
            }}
            sx={{
              fontSize: "1.5rem",
              borderRadius: 5,
              marginTop: "2em",
              marginBottom: "1em",
              marginRight: "1%",
              backgroundColor: "#ff6f00",
              "&:hover": {
                color: "#13b5cf",
                backgroundColor: "#ffffff",
              },
            }}
          >
            Continue to App
            <TouchAppIcon />
          </Button>
        )}
        {profile === null && <FreeToTryButton />}


      </Box>

  

      {/* <FooterActionButtons isSafari={isSafari} /> */}
      <FooterNavLinks />
      <CommunityImageLinks />
      
      <Box sx={{ marginBottom: "2em" }}></Box>
      
    </>
  );
}
