import React from 'react';
import { Breadcrumbs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';


export default function BreadcrumbNav({here, prev, prevprev}) {
    
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href={prevprev === "Home" ? "/app" : prevprev} >
            {prevprev}
        </Link>,
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href={`/${prev}`}
        >
          {prev}
        </Link>,
        <Typography key="3" color="text.primary">
          {here}
        </Typography>,
      ];

    return (
        <Box component="div" sx={{ marginBottom: "2em" }}>
        <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
        </Box>

    )
}