import React, { useState, useContext } from "react";
import { Paper, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Box } from "@mui/material";
import { useDrag } from "react-dnd";
import UserContext from "../../context/UserContext";
import ProjectPickerComponent from "../../components/isolated/ProjectPickerComponent";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const DraggableImage = ({ image, isUsed }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "image",
    item: { image },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const renderImageVariations = () => {
    const variations = [];

    if (image.edited_url) {
      variations.push(
        <Avatar
          key="edited"
          src={image.edited_url}
          variant="square"
          sx={{ mr: 1 }}
        />
      );
    }

    variations.push(
      <Avatar
        key="original"
        src={image.low_res_image_url ? image.low_res_image_url : image.image_url}
        variant="square"
      />
    );

    return variations;
  };

  return (
    <ListItem
      ref={drag}
      disablePadding
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}
    >
      <ListItemAvatar>
        <Box display="flex">
          {renderImageVariations()}
        </Box>
      </ListItemAvatar>
      <ListItemText 
        primary={image.name}
        secondary={
          image.edited_url ? "Edited available" : 
          image.variation_url ? "Upscaled" : 
          "Original"
        }
      />
      {isUsed && (
        <CheckCircleIcon 
          sx={{ 
            position: 'absolute', 
            top: 5, 
            right: 5, 
            color: 'green', 
            fontSize: '1rem' 
          }} 
        />
      )}
    </ListItem>
  );
};

const BookImageSelector = ({usedImages}) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectImages, setProjectImages] = useState([]);
  const [showImages, setShowImages] = useState(true);
  const { profile } = useContext(UserContext);

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setProjectImages(project?.images);
    setShowImages(true);
  };


  const toggleImageView = () => {
    setShowImages(!showImages);
  }

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "#eff8ff",
        overflow: "auto",
        p: 2,
        pr: 4,
      }}
    >
      <ProjectPickerComponent
        email={profile?.email}
        selectedProject={selectedProject}
        onProjectSelect={handleProjectSelect}
      />

{selectedProject && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 1 }}>
          <Typography variant="subtitle1">
            {showImages ? "Hide Images" : "Show Images"}
          </Typography>
          <IconButton onClick={toggleImageView}>
            {showImages ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      )}

      <Collapse in={showImages}>
      <List>
        {projectImages && projectImages.length > 0 ? (
          projectImages.map((image) => (
            <Box key={image.id}>
              <DraggableImage key={`original-${image.id}`} image={image} isUsed={usedImages.has(image.id)}/>              
            </Box>
          ))
        ) : (
          <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
            {!selectedProject ? "Select a project" : "No images available in this project."}
          </Typography>
        )}
      </List>
      </Collapse>
    </Paper>
  );
};

export default BookImageSelector;