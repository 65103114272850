import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top"/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const CustomHTMLToolTip = React.memo(function CustomHTMLToolTip({ title, body, children }) {
    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit">{title}</Typography>
                    {body}
                </React.Fragment>
            }
        >
            {children}
        </HtmlTooltip>
    );
});

CustomHTMLToolTip.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

export default CustomHTMLToolTip;
