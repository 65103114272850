import "./App.css";
import React, { useState } from "react";
import UserContext from "./context/UserContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import BackSoon from "./pages/BackSoon";
import MaintenanceModeToggle from "./pages/admin/MaintenanceModeToggle";


function AppOffline() {
  const [profile, setProfile] = useState({});
  const [imageCount, setImageCount] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [designLimit, setDesignLimit] = useState(0);
  const [accountHint, setAccountHint] = useState("");
  

  return (
    
    <GoogleOAuthProvider clientId="163385259830-er9kro8l6b6769e8cb2icu8hpst0h41e.apps.googleusercontent.com">
      
      <UserContext.Provider value={{
          profile,
          setProfile,
          imageCount,
          setImageCount,
          activeSubscription,
          setActiveSubscription,
          designLimit,
          setDesignLimit,          
          accountHint,
          setAccountHint,
      }}
        >
        
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<BackSoon />} />                  
                <Route path="/admin/mtc" element={<MaintenanceModeToggle  />} />                
            </Routes>
          </BrowserRouter>
        
      </UserContext.Provider>
    </GoogleOAuthProvider>
    // </AITokenProvider>
  );
}

export default AppOffline;
