import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateSEOListingButton from "./CreateSEOListingButton";
import IconButton from "@mui/material/IconButton";
import UpscaleButton from "./UpscaleButton";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveBackgroundButton from "./RemoveBackgroundButton";
import DownloadNoBackgroundImageButton from "./DownloadNoBackgroundImageButton";
import ProjectSelectionDialog from "../projects/ProjectSelectionDialog";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteImageButton from "./DeleteImageButton";
import CaptionThisButton from "./CaptionThisButton";
import EditImageButton from "./EditImageButton";
import Divider from "@mui/material/Divider";

export default function MenuImageCard({
  item,
  handleConfirmDeleteClick,
  isUpscaled,
  handleIsUpscaled,
  waitingForResponse,
  handleIsWaitingForResponse,
  handleSetVariationUrl,
  handleDownloadClick,
  backgroundButtonEnabled,
  handleBackGroundButtonEnabled,
  handleSetNoBackgroundUrl,
  nobackgroundUrl,
  variationUrl,
  openProjectList,
  handleSetOpenProjectList,
  projectList,
  handleRefreshProjects,
  handleSetOpenCaptions,
}) {  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAndHideMenu = (event) => {
    setAnchorEl(null);
    handleSetOpenCaptions(true);
    
    
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{  
          zIndex: "1",        
          color: !isUpscaled ? "#1a76d3" : "#2e7d33",          
          backgroundColor: "#fafafa", 

          outlineColor: "white",
          "&:hover": {
            color: "white",
            backgroundColor: "#b2ebf2",

            
          },
          position: "absolute",
          top: "0",
          right: "0",
          minWidth: "fit-content",
          paddingLeft: "0em",
          paddingRight: "0em",
          marginTop: "0.5em",
          marginRight: "0.45em",
          disabled: false,
          cursor: "pointer",
        }}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <UpscaleButton
            item={item}
            isUpscaled={isUpscaled}
            handleIsUpscaled={handleIsUpscaled}
            waitingForResponse={waitingForResponse}
            handleIsWaitingForResponse={handleIsWaitingForResponse}
            handleSetVariationUrl={handleSetVariationUrl}
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <EditImageButton image_id={item.image_id} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <RemoveBackgroundButton
            item={item}
            variationUrl={variationUrl}
            handleSetVariationUrl={handleSetVariationUrl}
            handleIsUpscaled={handleIsUpscaled}
            backgroundButtonEnabled={backgroundButtonEnabled}
            handleBackGroundButtonEnabled={handleBackGroundButtonEnabled}
            handleSetNoBackgroundUrl={handleSetNoBackgroundUrl}
            waitingForResponse={waitingForResponse}
            handleIsWaitingForResponse={handleIsWaitingForResponse}
          />
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleClose}>
          <CreateSEOListingButton image_id={item.image_id} />
        </MenuItem>
        <MenuItem onClick={handleClickAndHideMenu}>
          <CaptionThisButton image_id={item.image_id} handleSetOpenCaptions={handleSetOpenCaptions}/>
        </MenuItem>  

        <Divider />
        <MenuItem onClick={handleClose}>
          <IconButton
            sx={{
              color: "rgba(0, 0, 0)",
            }}
            aria-label={`download image`}
            title="Download image"
            onClick={() => handleDownloadClick()}
          >
            <DownloadIcon sx={{ marginRight: "0.5em" }} />
            Download
          </IconButton>
        </MenuItem>      
        <MenuItem onClick={handleClose}>
          <DownloadNoBackgroundImageButton
            item={item}
            nobackgroundUrl={nobackgroundUrl}
          />
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleClose}>
          <IconButton
            sx={{ color: "rgba(0, 0, 0)" }}
            aria-label="add to project"
            title="Add to Project"
            onClick={handleSetOpenProjectList}
          >
            <DriveFileMoveIcon sx={{ marginRight: "0.5em" }} />
            Add to Project
          </IconButton>
        </MenuItem>

        <Divider />
        
        <MenuItem onClick={handleClose}>         
          <DeleteImageButton 
            handleConfirmDeleteClick={handleConfirmDeleteClick}
             />
        </MenuItem>

        
      </Menu>
      {projectList && (
        <ProjectSelectionDialog
          item={item}
          projectList={projectList}
          openProjectList={openProjectList}
          handleOpen={handleSetOpenProjectList}
          handleRefreshProjects={handleRefreshProjects}
        />
      )}
    </div>
  );
}
