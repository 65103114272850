import axios from "axios";

export const callUpscale = ({ item, profile, APIToken, handleSetVariationUrl, handleIsUpscaled }) => {
    return new Promise((resolve, reject) => { // Return a Promise
  
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_BE}/api/upscale/`,
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      data: {
        image: item,
        email: profile?.email,
      },
    };

    axios
      .request(options)
      .then((response) => {
        item.variation_url = response.data.upscale_url;
        item.isUpscaled = true;
        handleSetVariationUrl(response.data.upscale_url);
        handleIsUpscaled();
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        reject(err); // Reject the Promise if there's an error
      });
    }
    );
};
