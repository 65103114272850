import React, {useContext} from "react";
import { Card, CardContent } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// import CardActions from "@mui/material/CardActions";
import UserContext from "../context/UserContext";
import Box from "@mui/material/Box";
import FreeToTryButton from "./buttons/FreeToTryButton";



function VideoCard({ video_id, title, subheader, body, steps }) {
    const { profile } = useContext(UserContext);
    const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Card sx={{ width: isMobile ? "100%" : "50%", margin: "auto", marginTop: "2em" }} xs={12} sm={0} md={1.5}>
      <CardContent sx={{maxWidth:isMobile ? "100%" : "100%", margin:"auto"}}>
        <div
          style={{
            position: "relative",
            paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
            
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={`https://www.youtube.com/embed/${video_id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <CardHeader title={title} subheader={subheader} sx={{fontWeight:"800"}}/>
        <Box sx={{marginLeft:"2em", marginRight:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body}
        </Typography>
        {/* if steps, create a bulleted list */}
        {steps && <ul>
          {steps.map((step, index) => {
            return <li key={index}>{step}</li>;
          })}
        </ul>}
        </Box>
      </CardContent>
      
      <Box sx={{ display: 'block', alignItems: 'center', pl: 1, pb: 1 }}>
      {profile === null && 
        // <Button size="small" onClick={googleLogin}>Try Now</Button>}
        <FreeToTryButton minimal={true}/>}
        {/* {profile !== null && <Button size="small" onClick={() => {
            window.location.href = "/app";
          }}>Start Creating</Button>} */}
        </Box>
      {/* </CardActions> */}
      
    </Card>
  );
}

export default VideoCard;
