let headerHeaders = { 
    "Content-Type": "application/json"};
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Not logged in");      
    }
    
    // debugger;
    if (token) {
        headerHeaders["Authorization"] = `Token ${token}`;
  }

export function getPostHeaders(body, method="POST") {        
    let headers = headerHeaders;        
    return {headers, "method": method, "body": JSON.stringify(body)};
}

export function getPutHeaders(body, method="PUT") {        
    let headers = headerHeaders;        
    return {headers, "method": method, "body": JSON.stringify(body)};
}

export function getDeleteHeaders(body) {
    let headers = headerHeaders;        
    return {headers, "method": "DELETE", "body": JSON.stringify(body)};
}

export function getMediaPostHeaders(body, method) {
    let headers = headerHeaders;    
    headers["Content-Type"] = "multipart/form-data";
    return {headers, "method": method, "body": JSON.stringify(body)};
}

export function getFilePostHeaders(body, method) {
    let headers = headerHeaders;
    headers["Content-Type"] = "multipart/form-data";
    return {headers, "method": method, "body":JSON.stringify(body)};
}



// export function getGetHeaders() {
//   return  headerHeaders;    
// }
