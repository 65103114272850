export const getBackgroundColor = (option, selectedOption) => {
  console.log("option", option);
  console.log("selectedOption", selectedOption);
    if (selectedOption?.simpleName === option.simpleName) {
      return "#08acc8";
    } else {
      return "#ffffff";
    }
  };

export const getTextColor = (option, selectedOption) => {
    if (selectedOption?.simpleName === option.simpleName) {
      return "#ffffff";
    } else {
      return "#000000";
    }
  };

export const getFontWeight = (option, selectedOption) => {
    if (selectedOption?.simpleName === option.simpleName) {
      return "500";
    } else {
      return "100";
    }
  };