import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';


export default function DeleteImageButton({handleConfirmDeleteClick}) {
    return (
        <Box>
         <IconButton
            sx={{ color: "rgba(0, 0, 0)" }}
            aria-label="delete"
            title="Delete"
            onClick={handleConfirmDeleteClick}
          >
            <DeleteIcon sx={{ marginRight: "0.5em" }} />
            Delete
          </IconButton>
        </Box>
    );
    }
   