import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";

const PricingTable = () => {
  const userInfo = localStorage.getItem("userInfo");
  const email = JSON.parse(userInfo)?.email;
  const hasCalledNudge = useRef(false);


  const callNudgeCount = () => {
    if (!hasCalledNudge.current) {
    fetch(`${process.env.REACT_APP_API_BE}/api/nudge`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });
    hasCalledNudge.current = true;
    }
  };

  useEffect(() => {
    callNudgeCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    // PROD
    <Box
      sx={{
        maxWidth: "100%",
        padding: ".25em",
        margin: "auto",
        borderStyle: "solid",
        borderColor: "lightgray",
        borderWidth: "1px",
      }}
    >
      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <stripe-pricing-table
        pricing-table-id="prctbl_1PFz86E5U7V8hactuutRY5Yj"
        publishable-key="pk_live_51O2lPWE5U7V8hactdmUDx6u9O7eIY7JiIL1ARgkZWvctwmnpCtYq6VclSiGEJ6TAZFXGBt8Ob6HAtEucrKqVDfkK002cIvciZq"
      ></stripe-pricing-table>
    </Box>
  );
};

export default PricingTable;
