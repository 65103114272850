import React from 'react';
import { Grid, Badge, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';  // Import from react-router-dom

const chipColor = "#04a9c8";
const badgeColor = "#025c6ead"; // Darker shade of the chip color


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: badgeColor,
    color: 'white',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  color: chipColor,
  borderColor: chipColor,
  '&:hover': {
    backgroundColor: `${chipColor}14`, // 14 is 8% opacity in hex
  },  
}));

export default function CategoryChip({ category, count, to }) {
  return (
    <Grid item>
      <Link to={to} style={{ textDecoration: "none" }}>
        <StyledBadge 
          badgeContent={count} 
          color="primary"
          max={999}
          sx={{marginRight: "0.5em"}}
        >
          <StyledChip
            variant="outlined"
            label={category}
            sx="marginLeft: 1em"
            component="div"
          />
        </StyledBadge>
      </Link>
    </Grid>
  );
}

// Usage:
// <CategoryChip 
//   category={categories[category].normalized_name}
//   count={categories[category].image_count}
//   to={`/category/${categories[category].normalized_name}/`}
// />