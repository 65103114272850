import React, { useContext } from "react";
import { Box, Avatar, Button } from "@mui/material";
import UserContext from "../../context/UserContext";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { getPostHeaders } from "../../utils/headers";

function CustomGoogleLoginButton() {
  const {
    profile,
    setProfile, 
    setAPIToken,   
    setAccountHint,
  } = useContext(UserContext);
  

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            setProfile(response?.data);
            setAccountHint(response?.data?.email);
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            handleUser(response?.data).then((response) => {           
              setAPIToken(response);              
            });
            window.location.href = "/app";
          }
        });
    },
    onError: (errorResponse) =>
      console.log(`Error in googleLogin, message: ${errorResponse}`),
  });

  function loginUser() {
    googleLogin();
  }


  const handleUserLogin = () => {
    loginUser();
  };

  const handleShowProfile = () => {
    window.location.href = "/profile";
  };

  async function handleUser(profile) {
    try {
      let headers = getPostHeaders(
        {
          profile: profile,
        },
        "POST"
      );
      const response = await fetch(`${process.env.REACT_APP_API_BE}/api/user`, {
        ...headers,
      });

      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const resp = await response.json();            
      setAccountHint(resp?.email);      
    } catch (error) {
      // Handle or log the error
      console.error("Error in handleUser:", error);
      // Additional error handling logic can be added here
    }
  }

  return (
    <>    
      {profile?.email ? (
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
        <Button>
          <Avatar
            alt={profile?.name}
            src={profile ? profile?.picture : ""}
            srcSet={profile ? profile?.picture : ""}
            sx={{
              width: "3rem",
              height: "3rem",
              marginLeft: ".25em",
              cursor: "pointer",
              marginBottom: ".25em",
            }}
            // onClick={handleUserLogout}
            onClick={handleShowProfile}
            title={`View ${profile?.name}'s profile`}
          />
        </Button>
        </Box>
      ) : (
        <>
          <img
            src="/btn_google_signin_light_pressed_web@2x.png"
            alt="Sign in with Google"
            height="46"
            width="191"
            onClick={handleUserLogin}
          />
        </>
      )}
    </>
  );
}

export default CustomGoogleLoginButton;
