import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";

export default function UnlockBoxDialog({ open, setOpen, secret, handleDeleteUser }) {
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <Dialog open={open} onClose={handleClose} 
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const submittedSecret = formJson.secret;
              console.log(secret.email, submittedSecret);
              if (submittedSecret === secret.email) {
                console.log("Unlocking box");
                handleDeleteUser();
              }
              handleClose();
            },
          }}>
        <DialogTitle>
            Unlock Box
        </DialogTitle>
        <DialogContent>
            <Box component="div">
            <Typography variant="body1">
                Enter secret code
            </Typography>
            <TextField
            autoFocus
            required
            margin="dense"
            id="secret"
            name="secret"
            label="Secret"            
            variant="standard"
          />           
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Delete User</Button>
        </DialogActions>
        </Dialog>
    );
    }