import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const RotatingImageCard = ({
  images,
  title,
  onClick,
  isSelected,
  getFontWeight,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        maxWidth: 345,
        flex: "1 1 auto",
        minWidth: 250,        
        paddingLeft: ".5em",
        paddingRight: ".5em",
        paddingTop: "1em",        
        color: "#13b5cf",
        backgroundColor: "white",
        fontWeight: "bold",
        fontSize: "1.5em",
        textAlign: "center",
        padding: "1em",
        margin: "1em",
        borderRadius: "1em",        
        boxShadow: "0 0 10px 0 #13b5cf",
        position: "relative",
        cursor: "pointer", // Add this to indicate it's clickable
      }}
    >
      <CardMedia
        component="img"
        height="245"
        image={images[currentImageIndex]}
        alt={title[currentImageIndex]}
        sx={{ objectFit: "contain", backgroundColor: "white" }}
      />
      <IconButton
        className={`plausible-event-name=Rotate-${title[currentImageIndex]}-Scroll-Left`}
        sx={{
          position: "absolute",
          left: 5,
          top: "50%",
          transform: "translateY(-50%)",                       
          color: "#13b5cf",
          backgroundColor: "white",
        }}
        onClick={handlePrevImage}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        className={`plausible-event-name=Rotate-${title[currentImageIndex]}-Scroll-Right`}
        sx={{
          position: "absolute",
          right: 5,
          top: "50%",
          transform: "translateY(-50%)",            
          color: "#13b5cf",
          backgroundColor: "white",
        }}
        onClick={handleNextImage}
      >
        <ArrowForwardIos />
      </IconButton>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: getFontWeight(title[currentImageIndex].toLowerCase(), isSelected) }}
        >
          {title[currentImageIndex]}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RotatingImageCard;