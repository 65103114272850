import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";

export default function StackedAreaChartMD({ resultsByDay }) {
  const { series, xAxis } = resultsByDay;
  
  const uData = new Array(xAxis?.data?.length).fill(0);
  const coloringData = new Array(xAxis?.data?.length).fill(0);
  const wallartData = new Array(xAxis?.data?.length).fill(0);
  const pData = new Array(xAxis?.data?.length).fill(0);
  const amtData = new Array(xAxis?.data?.length).fill(0);
  // console.log(`series: ${JSON.stringify(series)} `)
  // Aggregate data for each category
  if (!series) {
    return null;
  }
  series.forEach(({ name, data }) => {
    data.forEach((count, index) => {
      uData[index] += count; // Always add to the total

      if (name.toLowerCase().includes("sticker")) {
        pData[index] += count;
      }

      if (name.toLowerCase().includes("coloring")) {
        coloringData[index] += count;
      }

      if (name.toLowerCase().includes("wall")) {
        wallartData[index] += count;
      }

      if (name.toLowerCase().includes("t-shirt") || name.toLowerCase().includes("tshirtart")) {
        amtData[index] += count;
      }
    });
  });

  return (
    <>   
      <LineChart
        // width={500}
        height={300}
        series={[
          {
            data: uData,
            label: "Total Items",
            area: true,
            stack: "total",
            showMark: false,
          },
          {
            data: pData,
            label: "Stickers",
            area: true,
            stack: "total",
            showMark: false,
          },
          {
            data: amtData,
            label: "T-Shirts",
            area: true,
            stack: "total",
            showMark: false,
          },
          {
            data: coloringData,
            label: "Coloring",
            area: true,
            stack: "total",
            showMark: false,
          },
          {
            data: wallartData,
            label: "Wall Art",
            area: true,
            stack: "total",
            showMark: false,
          },
        ]}
        xAxis={[{ scaleType: "point", data: xAxis.data }]}
        sx={{
          ".MuiLineElement-root": {
            display: "none",
          },
        }}
      />
    </>
  );
}