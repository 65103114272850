import React from "react";
import { Button } from "@mui/material";


export default function GenerateSEOListingButton({refresh, handleRefreshCopy, openSpinner}) {
    return (
        <Button
              variant="contained"
              onClick={handleRefreshCopy}
              sx={{
                fontSize: "1.5rem",
                minWidth: "30%",
                borderRadius: 5,
                marginTop: "5%",
                marginBottom: "5%",
                // marginRight: "1%",
                margin:"auto",
                backgroundColor: "#13b5cf",
                "&:hover": {
                  color: "#13b5cf",
                  backgroundColor: "#ffffff",
                },
                disabled: openSpinner === true ? true : false,
                cursor: openSpinner === true ? "not-allowed" : "pointer",
              }}
            >
              {refresh ? "Refresh Listing" : "Generate"}
            </Button>
    )
}