import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import UserContext from "../context/UserContext";
import PaywallPopup from "./PaywallPopup";



export default function ExportToWordDialog({
  selectedProject,
  showDocExportDialog,
  handleShowDocExportDialog,
  showPaywall,
  handleShowPaywall,
}) {
  const [docSize, setDocSize] = useState("letter");
  const [useBackpage, setUseBackpage] = useState("black");
  const [addReviewRequest, setAddReviewRequest] = useState(false);
  const [reviewRequestText, setReviewRequestText] = useState(
    "If you love our book, please leave a review on Amazon. It helps us a lot!"
  );
  const [showProgress, setShowProgress] = useState(false);

  const { profile, APIToken } = useContext(UserContext);

  
  const handleDocSizeChange = (event) => {    
    setDocSize(event.target.value);
  };

  const handleUseBackpageChange = (event) => {       
    setUseBackpage(event.target.name);
  };

  const handleAddReviewRequestChange = () => {
    setAddReviewRequest(!addReviewRequest);
  };

  const handleReviewRequestTextChange = (event) => {    
    setReviewRequestText(event.target.value);
  };

  useEffect(() => {
    async function checkExportUsage() {
      if (profile?.email) {
        const response = await fetch(
          `${process.env.REACT_APP_API_BE}/api/usage/${profile?.email}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            },
          }
        );
        await response.json().then((response) => {          
          const doc_gen_usage = response?.feature_usage?.docgen_usage;
          const subscription_status = response?.subscription_status?.subscription_active;
          if (doc_gen_usage >= 1 && subscription_status === false) {
            handleShowPaywall();
          }
        } );
      }
    }
    checkExportUsage();
    // eslint-disable-next-line
  }, [profile?.email]);
  

  async function handleExport() {
    setShowProgress(true);
    await fetch(`${process.env.REACT_APP_API_BE}/api/doc/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify({
        project_id: selectedProject?.id,
        email: profile?.email,
        configuration: {
          size: docSize,
          use_backpage: useBackpage,
          add_review_request: addReviewRequest,
          review_request_text: reviewRequestText,        
        }        
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL from the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link and set the URL as the href
        const a = document.createElement("a");
        a.href = url;
        a.download = "document.docx"; // You can set the filename here
        document.body.appendChild(a);

        // Trigger the download
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setShowProgress(false);
        handleShowDocExportDialog();
      })
      .catch((error) => console.error("Error:", error));
  }

  

  return (
    <div>
    {showPaywall ?
      (<PaywallPopup 
      message="You have reached the maximum limit for document generation. Please upgrade to a paid plan to continue." 
      handleShowPaywall={handleShowPaywall}
      showPaywall={showPaywall}
      />) : (
    <Dialog open={showDocExportDialog} onClose={handleShowDocExportDialog}>
      {showProgress && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showProgress}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DialogTitle>Export to Word Document</DialogTitle>
      <DialogContent>
        <DialogContentText>Configure Document Settings</DialogContentText>
        {/* {showPaywall ? (         
          <PaywallPopup 
            message="You have reached the maximum limit for document generation. Please upgrade to a paid plan to continue." 
            handleShowPaywall={handleShowPaywall}
            showPaywall={showPaywall}
            />          
        )
        : (
       <> */}
        <Box
          noValidate
          component="form"
          sx={{
            display: "inline-block",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
            padding: "1em",
          }}
        >
          <FormControl>
            <FormLabel id="document-size">Document Size</FormLabel>
            <RadioGroup
              aria-labelledby="document-size"
              name="document-size-buttons-group"
              value={docSize}
              onChange={handleDocSizeChange}
            >
              <FormControlLabel
                value="letter"
                control={<Radio />}
                label="Letter (8.5 x 11)"
                
              />
              <FormControlLabel
                value="square"
                control={<Radio />}
                label="Square (8.25 x 8.25)"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          noValidate
          component="form"
          sx={{
            display: "inline-block",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
            // border: "1px solid lightgrey",
            padding: "1em",
            marginLeft: "1em",
          }}
        >
          
          <FormControl>
          <FormLabel id="image-backpage">Image Back Page</FormLabel>
            <FormControlLabel control={<Checkbox  checked={useBackpage==="black"}
              onChange={handleUseBackpageChange}
              name="black" />} label="Black" />                         
              <FormControlLabel control={<Checkbox  checked={useBackpage==="blank"}
              onChange={handleUseBackpageChange}
              name="blank" />} label="Blank" />                         
              <FormControlLabel control={<Checkbox  checked={useBackpage==="none"}
              onChange={handleUseBackpageChange}
              name="none" />} label="None" />                         
          </FormControl>
        </Box>
        <Box
          noValidate
          component="form"
          sx={{
            display: "inline-block",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
            // border: "1px solid black",
            padding: "1em",
          }}
        >
          <FormControl>
          <FormLabel id="image-backpage">Include Amazon Review Request?</FormLabel>
          <FormControlLabel control={<Checkbox  checked={addReviewRequest}
              onChange={handleAddReviewRequestChange}
              name="image-backpage-checkbox" />} label="Add Review Request" />                         
           
            {addReviewRequest && (
              <TextField
                id="review-request-text"
                label="Review Request Text"
                multiline
                fullWidth
                value={reviewRequestText}
                onChange={handleReviewRequestTextChange}
                helperText="If you love our book, please leave a review on Amazon. It helps us a lot!"
              />
            )}
          </FormControl>
        </Box>
        {/* </>
        )} */}
      </DialogContent>
      {/* {!showPaywall && ( */}
      <DialogActions>
        <Button onClick={handleExport}>Create</Button>
        <Button onClick={handleShowDocExportDialog}>Cancel</Button>
      </DialogActions>
      {/* )} */}
    </Dialog>)}
    
    </div>     
  );
  
}
