import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function AdminNav({ profile }) {
  return (
    <>
      {profile?.email === "merchdesignai@gmail.com" ? (
        <Box sx={{ width: "fit-content", margin: "auto" }}>
          <Typography variant="h5">Welcome, Joann!</Typography>
          <Button onClick={() => (window.location.href = "/metrics")}>
            View Metrics
          </Button>
          {/* <Button onClick={() => (window.location.href = "/admin/subs")}>
            Subscribers
          </Button> */}
          <Button onClick={() => (window.location.href = "/admin/browser")}>
            Admin Browser
          </Button>
          <Button onClick={() => (window.location.href = "/admin/feedback")}>
            Feedback
          </Button>
          <Button
            onClick={() => (window.location.href = "/admin/feedback_metrics")}
          >
            Feedback Metrics
          </Button>
          <Button onClick={() => (window.location.href = "/admin/mtc")}>
            Maintenance
          </Button>
          <Button onClick={() => (window.location.href = "/admin/gensitemap")}>
            Generate Sitemap
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography variant="h5">
            Welcome, {profile?.name}! You are lost
          </Typography>

          <Button onClick={() => (window.location.href = "/app")}>
            Go back
          </Button>
        </Box>
      )}
    </>
  );
}
