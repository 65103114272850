import React, { useContext } from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import IconButton from "@mui/material/IconButton";
import UserContext from "../../context/UserContext";
import { callUpscale } from "../../shared/callUpscale";

function RemoveBackgroundButton({
  item,
  variationUrl,
  handleSetVariationUrl,
  handleIsUpscaled,
  backgroundButtonEnabled,
  handleBackGroundButtonEnabled,
  handleSetNoBackgroundUrl,
  waitingForResponse,
  handleIsWaitingForResponse,
}) {
  // const [waitingForRBGResponse, setWaitingForRBGResponse] = useState(false);
  const { profile, APIToken } = useContext(UserContext);

  const handleRemoveBackgroundClick = () => {
    const removeBackground = async () => {
      // handleIsWaitingForResponse(true);      
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
        body: JSON.stringify({
          img: variationUrl
            ? variationUrl
            : item.variation_url
            ? item.variation_url
            : item.image_url,
          image_id: item.image_id,
          email: profile?.email,
        }),
      };

      fetch(`${process.env.REACT_APP_API_BE}/api/removebg/`, options)
        .then((response) => response.json())
        .then((response) => {
          handleSetNoBackgroundUrl(response[0]);
          handleIsWaitingForResponse(false);
          handleBackGroundButtonEnabled(false);
          window.open(response[0], "_blank");
        })
        .catch((err) => {
          handleIsWaitingForResponse(false);
          console.error(err);
        });
    };
    if (profile?.given_name !== "Guest") {
      handleIsWaitingForResponse(true);
      callUpscale({
        item: item,
        profile: profile,
        APIToken: APIToken,
        handleSetVariationUrl: handleSetVariationUrl,
        handleIsUpscaled: handleIsUpscaled,
      })
        .then(() => {
          removeBackground();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getTitle = () => {
    if (profile?.given_name === "Guest") {
      return "Remove background available with paid accounts only";
    } else {
      return "Remove background";
    }
  };

  return (
    <IconButton
      sx={{
        // color: backgroundButtonEnabled || profile?.given_name === "Guest"
        //   ? "rgba(0, 0, 0)"
        //   : "rgba(0, 0, 0, 0.54)",
        color:
          !backgroundButtonEnabled || profile?.given_name === "Guest"
            ? "rgba(0, 0, 0, 0.54)"
            : "rgba(0, 0, 0)",
        animation: waitingForResponse ? "spin 7s linear infinite" : "",
        disabled:
          profile?.given_name === "Guest" || !backgroundButtonEnabled
            ? true
            : false,
        cursor:
          profile?.given_name === "Guest" || !backgroundButtonEnabled
            ? "not-allowed"
            : "pointer",
      }}
      aria-label={`remove background`}
      title={getTitle()}
      onClick={handleRemoveBackgroundClick}
    >
      <CancelPresentationIcon
        sx={{
          marginRight: "0.5em",
        }}
      />
      Remove Background
    </IconButton>
  );
}

export default RemoveBackgroundButton;
