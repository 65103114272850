import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Box, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UserContext from "../../context/UserContext";

import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

// isolated projectlist component with one job, display a list of projects
const ProjectPickerComponent = ({ selectedProject, onProjectSelect }) => {  
  const [projectCollection, setProjectCollection] = useState([]);
  const [expandProjects, setExpandProjects] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isIpad = useMediaQuery("(max-width:1200px");

  const { profile, APIToken } = useContext(UserContext);

  const handleExpandProjects = () => {
    setExpandProjects(!expandProjects);
  };

  
  useEffect(() => {
    handleExpandProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  useEffect(() => {
    if (isMobile) {
      setExpandProjects(false);
    } else {
      setExpandProjects(true);
    }
  }, [isMobile]);

  useEffect(() => {
   

  async function handleGetProjectCollection(page = 1) {
    if (!profile?.email || profile?.email === "undefined") {
      console.log("no email");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BE}/api/projects?email=${profile?.email}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized, please log in first");
        return;
      } else if (response.status === 200) {        
        const data = await response.data;
        if (data === null || data?.results?.length === 0) {
          setProjectCollection([]);
        } else {
          setProjectCollection(data);          
        }
      }
    } catch (error) {
      console.error("Error fetching project collection:", error);
      return;
    }
  }

  if (profile?.email && APIToken) {
    handleGetProjectCollection();
  }
}, [profile?.email, APIToken]);
  
  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          padding: ".25em",
        }}
      >
        <Typography
          variant={isIpad ? "h5" : "h4"}
          component="div"
          sx={{
            flexGrow: 1,
            paddingLeft: ".1em",
            flexShrink: 0,
            minWidth: "fit-content",
          }}
        >
          Projects
        </Typography>
        <Box sx={{ display: "flex", flexShrink: 0, minWidth: "fit-content" }}>
          <IconButton onClick={handleExpandProjects}>
            {expandProjects ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      </Box>

      <Divider sx={{ marginBottom: ".5em" }} />

      {expandProjects && (
        <Box sx={{ flexGrow: 1, width: "auto", marginTop: ".5em" }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}
          >
            {projectCollection.map((project) => (
              <Item
                key={project.id}
                sx={{ width: "100%", cursor: "pointer" }}
                onClick={() => onProjectSelect(project)}
              >
                {`${truncateString(project?.name, 12)} (${
                  project?.images?.length || 0
                })`}
              </Item>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ProjectPickerComponent;
