import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  Typography,
  Input,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { FormControlLabel, Checkbox } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

// Ensure to set the app element for accessibility reasons
Modal.setAppElement("#root");

function ExtendTrialForm({ handleShowExtendTrial, showExtendTrial, profile }) {
  const [formCompleted, setFormCompleted] = useState(false);
  const [feedback, setFeedback] = useState({
    favoriteFeatures: [],
    useCase: "",
    missingFeature: "",
    otherTools: "",
    subscribing: "choose",
    killerFeature: "",
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    const validateFormCompleted = () => {
      if (
        feedback.favoriteFeatures.length > 0 &&
        feedback.useCase &&
        feedback.missingFeature &&
        feedback.otherTools &&
        feedback.subscribing !== "choose"
      ) {
        if (
          (feedback.subscribing === "no" ||
            feedback.subscribing === "undecided") &&
          feedback.killerFeature === ""
        ) {
          setFormCompleted(false);
        } else {
          setFormCompleted(true);
        }
      } else {
        setFormCompleted(false);
      }
    };
    validateFormCompleted();
  }, [feedback]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleFeatureChange = (event) => {
    const { name, value, checked } = event.target;

    setFeedback((prevFeedback) => {
      // Create a new array by spreading the previous favoriteFeatures
      let updatedFeatures = [...prevFeedback.favoriteFeatures];

      if (checked && !updatedFeatures.includes(value)) {
        // If the checkbox is checked and the value is not already in the array, add it
        updatedFeatures.push(value);
      } else if (!checked) {
        // If the checkbox is unchecked, remove the value from the array
        updatedFeatures = updatedFeatures.filter(
          (feature) => feature !== value
        );
      }

      return {
        ...prevFeedback,
        [name]: updatedFeatures,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation(); // Add this line to stop event propagation

    try {
      console.log(
        `${process.env.REACT_APP_API_BE}/api/feedback/${profile?.email}/`
      );
      await axios.post(
        `${process.env.REACT_APP_API_BE}/api/feedback/${profile?.email}/`,
        feedback
      );
      alert("Thanks for your Feedback! Your trial has been extended.");

      // Reset the form and close the modal
      setFeedback({
        useCase: "",
        favoriteFeatures: [],
        missingFeature: "",
        otherTools: "",
        subscribing: "",
        killerFeature: "N/A",
      });
      handleShowExtendTrial();
      // navigate to / to refresh
      window.location.href = "/app";
    } catch (error) {
      // alert("Failed to submit feedback. Please try again later.");
      console.error(error);
    }
  };

  const callHasSeenFeedbackForm = () => {
    axios.post(
      `${process.env.REACT_APP_API_BE}/api/user/showfeedback/${profile?.email}/`
    );
  };

  useEffect(() => {
    callHasSeenFeedbackForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (    
    <Modal
      isOpen={showExtendTrial}
      onRequestClose={() => handleShowExtendTrial}
      contentLabel="Feedback Form"
      shouldCloseOnOverlayClick={false} // Add this to prevent closing the modal by clicking outside
      aria-labelledby="modal-extend-trial"
      aria-describedby="modal-extend-trial-form"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 'fit-content',        
        maxWidth: '600px',
        maxHeight: '90vh',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 3,
      }}>
    <Typography variant={isMobile ? "h4" : "h2"} align="center">

        Extend Trial 🚀
        <br /> Just Answer a Few Questions 🎉
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",          
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <FormGroup>         
        <InputLabel sx={{ fontSize: isMobile ? "1.5em" : "2em", width: "100%", mb: 1, textAlign:"left" }} htmlFor="usecase">
        Why are you trying MerchDesign AI?
            </InputLabel>
            <Input
              fullWidth
              multiline={true}
              rows={1}
              placeholder="I need MerchDesign AI because..."
              type="text"
              id="usecase"
              name="useCase"
              value={feedback.useCase}
              onChange={handleInputChange}
              sx={{ mb: 3 }}
            />
         
          
         <InputLabel sx={{ fontSize: isMobile ? "1.5em" : "2em", mb: 1, textAlign:"left" }} htmlFor="favoriteFeatures">
              Features you've tried
            </InputLabel>
           
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
              {['Edit', 'SEO', 'Captions', 'Upscaling', 'Background-Remove', 'Manuscript', 'Projects'].map((feature) => (
                <FormControlLabel
                  key={feature}
                  control={
                    <Checkbox
                      name="favoriteFeatures"
                      value={feature}
                      checked={feedback.favoriteFeatures.includes(feature)}
                      onChange={handleFeatureChange}
                    />
                  }
                  label={feature}
                  sx={{ width: isMobile ? '50%' : 'auto' }}
                />
              ))}
            </Box>

          
         
         {['otherTools', 'missingFeature'].map((field) => (
              <Box key={field} sx={{ mb: 3, textAlign:"left", width:"100%" }}>
                
                <InputLabel sx={{ fontSize: isMobile ? "1.5em" : "2em",width: "100%", mb: 1 }} htmlFor={field} >
                  {field === 'otherTools' ? "Other tools you've tried?" : 'Feature you need?'}
                </InputLabel>
                <Input
                  fullWidth
                  // sx={{width: isMobile ? '90%' : 'fit-content'}}
                  multiline
                  rows={1}
                  placeholder={field === 'otherTools' ? "I've tried.." : "I wish MerchDesign AI could..."}
                  id={field}
                  name={field}
                  value={feedback[field]}
                  onChange={handleInputChange}
                />
              </Box>
            ))}

          
           <Box sx={{ mb: 3, width:"100%" }}>
              <InputLabel sx={{ fontSize: isMobile ? "1.5em" : "2em", mb: 1, textAlign:"left" }} htmlFor="subscribing">
                Planning to subscribe?
              </InputLabel>
              <Select
                fullWidth
                // sx={{display:"block", margin:"auto", width:"fit-content", textAlign:"left", alignSelf:"left"}}
                id="subscribing"
                name="subscribing"
                value={feedback.subscribing}
                onChange={handleInputChange}
              >
                <MenuItem value="choose">Select an option</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
                <MenuItem value="undecided">Undecided</MenuItem>
              </Select>
            </Box>

          
          {(feedback.subscribing === "no" || feedback.subscribing === "undecided") && (
              <Box sx={{ mb: 3, width:"100%" }}>
                <InputLabel sx={{ fontSize: isMobile ? "1.5em" : "2em", mb: 1, textAlign:"left" }} htmlFor="killerFeature">
                  What would change your mind?
                </InputLabel>
                <Input
                  fullWidth
                  multiline
                  rows={1}
                  id="killerFeature"
                  name="killerFeature"
                  value={feedback.killerFeature}
                  placeholder="I don't part with my money easily, but when I do it's because..."
                  onChange={handleInputChange}
                />
              </Box>
            )}

          {/* <Button
            variant="contained"
            type="submit"
            sx={{
              fontSize: "1.5rem",
              borderRadius: 5,
              marginTop: "1em",
              marginBottom: "1em",
              marginRight: "1%",
              backgroundColor: !formCompleted ? "#bdbdbd" : "#ff6f00",
              "&:hover": {
                color: !formCompleted ? "#ffffff" : "#13b5cf",
                backgroundColor: !formCompleted ? "#bdbdbd" : "#ffffff",
              },

              cursor: !formCompleted ? "not-allowed" : "pointer",
            }}
            disabled={!formCompleted}
          >
            Extend Trial
          </Button> */}
           <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                fontSize: isMobile ? "1.2rem" : "1.5rem",
                borderRadius: 5,
                mt: 2,
                mb: 1,
                backgroundColor: !formCompleted ? "#bdbdbd" : "#ff6f00",
                "&:hover": {
                  color: !formCompleted ? "#ffffff" : "#13b5cf",
                  backgroundColor: !formCompleted ? "#bdbdbd" : "#ffffff",
                },
                cursor: !formCompleted ? "not-allowed" : "pointer",
              }}
              disabled={!formCompleted}
            >
              Extend Trial
            </Button>
        </FormGroup>
      </Box>
      </Box>
    </Modal>
    
  );
}

export default ExtendTrialForm;
