import React,  { useEffect, useState, useContext }  from 'react';
import UserContext from '../../context/UserContext';
import { Box } from '@mui/material';
import AdminNav from '../../components/navigation/AdminNav';

export default function FeedbackMetrics() {
    const [users_who_submit_feedback, setUsersWhoSubmitFeedback] = useState(0);
    const [subscribers_after_feedback_displayed, setSubscribersAfterFeedbackDisplayed] = useState(0);
    const [subscribers_after_feedback_submitted, setSubscribersAfterFeedbackSubmitted] = useState(0);
    const [showError, setShowError] = useState(false);
    const { APIToken, profile } = useContext(UserContext);
    

    // {"users_who_submit_feedback": users_who_submit_feedback, "subscribers_after_feedback_displayed": subscribers_after_feedback_displayed, "subscribers_after_feedback_submitted": subscribers_after_feedback_submitted}
    // call api/admin/feedback_metrics

    useEffect(() => {
        async function handleGetFeedbackMetrics() {
            const response = await fetch(
                `${process.env.REACT_APP_API_BE}/api/admin/feedback_metrics`,
                { method: "GET", 
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${APIToken}`
                } 
            });

            if (response.status === 200) {
                const data = await response.json();
                console.log("Feedback Metrics data: ", data);
                setUsersWhoSubmitFeedback(data?.users_who_submit_feedback);
                setSubscribersAfterFeedbackDisplayed(data?.subscribers_after_feedback_displayed);
                setSubscribersAfterFeedbackSubmitted(data?.subscribers_after_feedback_submitted);
                setShowError(false);
            } else if (response.status === 401) {
                console.error("Unauthorized, please log in first");
                setShowError(true);
            }
        }
        if (APIToken !== "") {
            handleGetFeedbackMetrics();
        }
        
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [APIToken]);

    return (
        <div>
        {showError && (<Box>Unauthorized, admin access only</Box>)}
        {!showError && (
        <Box>
            <h1>Feedback Metrics</h1>
            <AdminNav profile={profile} />
            <p>Submitted feedback after it is displayed: {users_who_submit_feedback.toFixed(2)}%</p>
            <p>Subscribed after feedback displayed but not submitted: {subscribers_after_feedback_displayed.toFixed(2)}%</p>
            <p>Subscribed after feedback displayed and submitted: {subscribers_after_feedback_submitted.toFixed(2)}%</p>
        </Box>
        )}
        </div>
    );
}

// export default FeedbackMetrics;