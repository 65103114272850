import React, { useEffect, useState, useContext } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import MenuImageCard from "../../components/buttons/MenuImageCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Paper from "@mui/material/Paper";
import CustomHTMLToolTip from "../../components/CustomHTMLToolTip";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import RateImage from "../../components/RateImage";
import UserContext from "../../context/UserContext";
import Visibility from "../Visibility";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageModifierChips from "../image_display/ImageModifierChips";

function ImageCard({
  item,
  projectList,
  handleUpdateProjects,
  handleRefreshProjects,
  selectedProject,
  handleAddToProject,
  multiSelectProjectName,
  currentPage,
}) {
  const [primaryUrl, setPrimaryUrl] = useState("");
  const [variationUrl, setVariationUrl] = useState("");
  const [isUpscaled, setIsUpscaled] = useState(false);
  const [nobackgroundUrl, setNobackgroundUrl] = useState("");
  const [backgroundButtonEnabled, setBackgroundButtonEnabled] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [openProjectList, setOpenProjectList] = useState(false);
  const [openCaptions, setOpenCaptions] = useState(false);
  const [checked, setChecked] = useState(false);
  const [accordianExpanded, setAccordianExpanded] = useState(false);
  const { profile, APIToken } = useContext(UserContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setAccordianExpanded(isExpanded ? panel : false);
  };

  const handleFeatureUsage = async (feature) => {
    // post to api/usage/acccess with email and feature
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/feature/access/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: item?.user,
          feature: feature,
        }),
      }
    );
    await response.json();
  };

  const handleChecked = () => {
    setChecked(!checked);
    handleAddToProject(item);
  };

  const StyledDialog = styled(Dialog)(() => ({
    position: "absolute",
    left: 10,
    top: 50,
  }));

  const handleBackGroundButtonEnabled = (enabled) => {
    setBackgroundButtonEnabled(enabled);
  };

  const handleSetNoBackgroundUrl = (url) => {
    setNobackgroundUrl(url);
  };

  const handleSetVariationUrl = (url) => {
    setVariationUrl(url);
  };

  const handleIsUpscaled = () => {
    setIsUpscaled(true);
  };

  const handleIsWaitingForResponse = (value) => {
    setWaitingForResponse(value);
  };

  const handleInfoClick = () => {
    if (!showInfo) {
      handleFeatureUsage("view_prompt");
    }
    setShowInfo(!showInfo);
  };

  const handleSetOpenProjectList = () => {
    setOpenProjectList(!openProjectList);
  };

  const handleSetOpenCaptions = () => {
    setOpenCaptions(!openCaptions);
  };
  const handleImageClick = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.href = variationUrl ? variationUrl : item?.image_url;
    link.target = "_blank";
    link.download = item.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleConfirmDeleteClick = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleRemoveFromProject = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/project`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
        body: JSON.stringify({
          image_id: item.id,
          project_id: selectedProject?.id,
          email: profile?.email,
        }),
      }
    );
    await response.json();
    handleUpdateProjects();    
  };

  const callback = async () => {  
    const encodedEmail = encodeURIComponent(profile.email);  
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/image?image_id=${item.image_id}&email=${encodedEmail}`,
      { method: "GET", headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      }}
    );
    const data = await response.json();
    setVariationUrl(data[0]?.variation_url);
    setPrimaryUrl(data[0]?.image_url);
  };

  const handleDeleteClick = () => {
    async function deleteImage() {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/image/${item.image_id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      );
      await response.json();
      callback(item);
      setIsDeleted(true);
      handleRefreshProjects(currentPage);
    }
    deleteImage();
    setOpenConfirmDeleteDialog(false);
  };

  useEffect(() => {
    item?.variation_url
      ? setVariationUrl(item.variation_url)
      : setVariationUrl("");
    setPrimaryUrl(item.image_url);
    setIsUpscaled(item.resolution === "high" ? true : false);
    setNobackgroundUrl(item.no_background_url);
    item?.no_background_url
      ? setBackgroundButtonEnabled(false)
      : setBackgroundButtonEnabled(true);
  }, [item]);

  useEffect(() => {
    const handlePromptInfo = async () => {
      if (item?.pixie_dust_prompt) {
        const cleanedPixie = item?.pixie_dust_prompt
          ?.replace("'role'", '"role"')
          .replace("'assistant'", '"assistant"')
          .replace("'content'", '"content"')
          .replace("Prompt: ", "")
          .replace('Title: "', "")
          .replace('"\n\n"', "")
          .replace(": '", ': "')
          .replace(': ""', ': "')
          .replace("\\'s", "s")
          .replace("\\s", "s")
          .replace("'}", '"}')
          .replace('""}', '"}');
        try {
          const JSONPixie = cleanedPixie ? JSON.parse(cleanedPixie) : null;
          setPrompt(JSONPixie.content);
        } catch {
          setPrompt(item.pixie_dust_prompt);
        }
      } else {
        setPrompt(item.description);
      }
    };
    if (item) {
      handlePromptInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.pixie_dust_prompt, item?.image_id]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={waitingForResponse}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isDeleted && item && (
        <>
          <StyledDialog
            open={openConfirmDeleteDialog}
            onClose={handleCloseConfirmDeleteDialog}
          >
            <DialogContent>
              Are you sure you want to delete this image?
            </DialogContent>
            <DialogActions>
              <Button name="delete_image" onClick={handleDeleteClick}>Yes</Button>
              <Button name="cancel_delete_image" onClick={handleCloseConfirmDeleteDialog}>No</Button>
            </DialogActions>
          </StyledDialog>
          {/* <RateImage image={item} email={profile?.email} showLabel={false}/> */}
          <ImageListItem key={item?.image_id} sx={{ cursor: "pointer" }}>
            <Paper
              elevation={3}
              sx={{
                minHeight: "3em",
                backgroundColor: "#f5f2ed",
              }}
            >
              {/* <RateImage image={item} email={profile?.email}/>   */}
              <Box sx={{ display: "block" }}>
                <Accordion onChange={handleAccordianChange("panel1")}>
                  <AccordionSummary                    
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="show-rating-and-visibility"
                    id="show-rating-and-visibility"
                    sx={{
                      // left: isMobile ? "-13em" : "-9em",
                      marginRight: "3.5em",
                      position: "sticky",
                    }}
                  ></AccordionSummary>
                  <AccordionDetails>
                    {accordianExpanded && (
                    <RateImage
                      image={item}
                      email={profile?.email}
                      showLabels={false}
                    />
                    )}
                    <Visibility image_id={item?.image_id} />
                  </AccordionDetails>
                </Accordion>
              </Box>
              {multiSelectProjectName !== "All" &&
                multiSelectProjectName !== "" && (
                  <CustomHTMLToolTip
                    title={
                      isMobile
                        ? `Tap to add to the ${multiSelectProjectName} project`
                        : `Click to add to the ${multiSelectProjectName} project`
                    }
                    body={
                      isMobile
                        ? "Projects help you organize your designs. Tap to add this design to a project."
                        : "Projects help you organize your designs. Click to add this design to a project."
                    }
                    children={
                      <PostAddIcon
                        sx={{
                          position: "absolute",
                          top: 11,
                          left: 74,
                          color: checked ? "#9e9e9e" : "#04a9c9",
                          cursor: checked ? "not-allowed" : "pointer",
                          // enabled: checked ? "false" : "true",
                          // ":disabled": { color: "grey" },
                        }}
                        onClick={() => {
                          if (!checked) {
                            handleChecked(); // Call handleChecked only if not checked
                          }
                        }}
                      />
                    }
                  ></CustomHTMLToolTip>
                )}
            </Paper>

            {!showInfo && (
              <img
                srcSet={
                  item?.low_res_image_url
                    ? item?.low_res_image_url
                    : variationUrl
                    ? variationUrl
                    : primaryUrl
                }
                src={
                  item?.low_res_image_url
                    ? item?.low_res_image_url
                    : variationUrl
                    ? variationUrl
                    : primaryUrl
                }
                alt={item.name}
                loading="lazy"
                onClick={handleImageClick}
              />
            )}

            <Box sx={{ display: "block" }}>
              <CustomHTMLToolTip
                title={
                  isUpscaled ? "Ready for print" : "Upscale to use for merch"
                }
                body={
                  isUpscaled
                    ? "The image has been upscaled and can be used to print on merch such as T-Shirts."
                    : "The image is not suitable for printing on merch such as T-Shirts in its current state. Click the menu icon and select 'Upscale' from the options."
                }
                children={
                  isUpscaled ? (
                    <CheckCircleIcon
                      sx={{
                        color: "green",
                        position: "absolute",
                        top:
                          selectedProject?.name !== "All" &&
                          selectedProject !== null &&
                        selectedProject !== "Search"
                            ? 10
                            : 12,
                        left:
                          selectedProject?.name !== "All" &&
                          selectedProject !== null &&
                        selectedProject !== "Search"
                            ? 8
                            : 8,
                      }}
                    />
                  ) : (
                    <WarningAmberIcon
                      sx={{
                        // ebe6f3
                        color: "orange",
                        position: "absolute",
                        top:
                          selectedProject?.name !== "All" &&
                          selectedProject !== null &&
                        selectedProject !== "Search"
                            ? 10
                            : 12,
                        left:
                          selectedProject?.name !== "All" &&
                          selectedProject !== null &&
                        selectedProject !== "Search"
                            ? 8
                            : 8,
                      }}
                    />
                  )
                }
              ></CustomHTMLToolTip>
            </Box>

            {selectedProject?.name !== "All" && selectedProject !== null && selectedProject?.name !== "Search" && (
              <CustomHTMLToolTip
                title={
                  isMobile
                    ? `Tap to remove from project`
                    : `Click to remove from project`
                }
                body="This will only remove the image from the current project."
                children={
                  <CancelIcon
                    sx={{
                      color: "#04a9c9",
                      position: "absolute",
                      top: 10,
                      left: 75,
                      cursor: "pointer",
                    }}
                    onClick={handleRemoveFromProject}
                  />
                }
              ></CustomHTMLToolTip>
            )}

            <Box>
              <CustomHTMLToolTip
                title={isMobile ? `Tap to delete` : `Click to delete`}
                body="This will permanently delete the image from your account."
                children={
                  <IconButton
                    sx={{
                      zIndex: "1",
                      color: "#04a9c9",
                      position: "absolute",
                      top:
                        selectedProject?.name !== "All" &&
                        selectedProject !== null &&
                        selectedProject !== "Search"

                          ? 2
                          : 4,
                      right:
                        selectedProject?.name !== "All" &&
                        selectedProject !== null &&
                        selectedProject !== "Search"
                          ? 21
                          : 21,
                      cursor: "pointer",
                    }}
                    aria-label="delete"
                    onClick={handleConfirmDeleteClick}
                  >
                    <DeleteIcon sx={{ marginRight: "0.5em" }} />
                  </IconButton>
                }
              ></CustomHTMLToolTip>

              <CustomHTMLToolTip
                title="View Prompt"
                body="View the prompt that was used to generate this image."
                children={
                  <IconButton
                    sx={{
                      color: "#04a9c9",
                      position: "absolute",
                      top:
                        selectedProject?.name !== "All" &&
                        selectedProject !== null &&
                        selectedProject !== "Search"
                          ? 2
                          : 4,
                      left:
                        selectedProject?.name !== "All" &&
                        selectedProject !== null &&
                        selectedProject !== "Search"
                          ? 36
                          : 32,
                      cursor: "pointer",
                    }}
                    aria-label="view prompt"
                    onClick={handleInfoClick}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              ></CustomHTMLToolTip>
            </Box>

            {showInfo && (
              <Box
                sx={{
                  paddingBottom: "5em",
                }}
              >
                <Typography variant="h6" component="div" textAlign={"right"}>
                  PROMPT
                </Typography>
                <Typography variant="body1" component="div">                 
                  <ImageModifierChips image_modifiers={item.image_modifiers} />
                  <span style={{fontWeight:"800"}}>
                  INPUT PROMPT<br/>
                  </span> {item?.name}
                  <br />
                  <span style={{fontWeight:"800"}}>GENERATED PROMPT</span>
                  <br /> {prompt}
                </Typography>
              </Box>
            )}

            <MenuImageCard
              item={item}
              isUpscaled={isUpscaled}
              handleIsUpscaled={handleIsUpscaled}
              waitingForResponse={waitingForResponse}
              handleIsWaitingForResponse={handleIsWaitingForResponse}
              handleSetVariationUrl={handleSetVariationUrl}
              handleConfirmDeleteClick={handleConfirmDeleteClick}
              handleDownloadClick={handleDownloadClick}
              variationUrl={variationUrl}
              backgroundButtonEnabled={backgroundButtonEnabled}
              handleBackGroundButtonEnabled={handleBackGroundButtonEnabled}
              handleSetNoBackgroundUrl={handleSetNoBackgroundUrl}
              nobackgroundUrl={nobackgroundUrl}
              handleUpdateProjects={handleUpdateProjects}
              projectList={projectList}
              openProjectList={openProjectList}
              handleSetOpenProjectList={handleSetOpenProjectList}
              handleRefreshProjects={handleRefreshProjects}
              handleSetOpenCaptions={handleSetOpenCaptions}
            />

            <ImageListItemBar
              title={item.name.substring(0, 30) + "..."}
              subtitle={item.merch}
            ></ImageListItemBar>
          </ImageListItem>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogContent>
              <img
                src={
                  item?.low_res_image_url
                    ? item?.low_res_image_url
                    : variationUrl
                    ? variationUrl
                    : primaryUrl
                }
                alt={item.name}
                style={{ maxWidth: "100%", height: "auto" }}
                sx={{
                  borderBottomLeftRadius: "10px", // Adjust as needed
                  borderBottomRightRadius: "10px", // Adjust as needed
                  // boxShadow: '3px -3px 5px rgba(0, 0, 0, 0.3)', // Adjust as needed
                }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}

export default ImageCard;
