import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import UserContext from "../../context/UserContext";
import ImageListCollection from "./ImageListCollection";
import axios from "axios";

export default function ImageList({
  profile,
  selectedProject,
  projectList,
  isAdmin = false,
}) {
  const [imageCollection, setImageCollection] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { imageCount, setImageCount, APIToken, logoutUser } =
    useContext(UserContext);
  const pageSize = 12;
  const pageCount = Math.ceil(imageCount / pageSize);

  
  async function handleGetImageCollection(page = 1) {   
    if (profile?.email === undefined) {
      return;
    }
    
    if (page === 1 && selectedProject?.id !== undefined && selectedProject.name === "Search") {            
      const filteredProjectList = projectList.filter((proj) => proj.name === "Search");
      const sortedImages = filteredProjectList[0]?.images?.sort((a, b) => b.id - a.id);
      setImageCollection(sortedImages);      
      setImageCount(sortedImages.length)
      return
    }
    
    
     if (page !== 1 && selectedProject?.name !== "Search") {       
      var url = "";            
      url = `${process.env.REACT_APP_API_BE}/api/images?email=${profile?.email}&page=${page}&project_id=${selectedProject?.id}&shouldLog=${isAdmin}`;
    } else {
      url = `${process.env.REACT_APP_API_BE}/api/images?email=${profile?.email}&page=${page}&shouldLog=${isAdmin}`;
    }

    const response = await axios.get(url, { 
      headers: {
        "Content-Type": "application/json",        
        Authorization: `Token ${APIToken ? APIToken : localStorage.getItem("token")}`,
      }
     });

     if (response.status === 401) {
      console.error("Unauthorized, please log in first");
      logoutUser();
    }
    else if (response.status === 200) {
      const data = await response.data;
      if (data === null || data?.results?.length === 0) {
        setImageCollection([]);
      } else {
        setImageCollection(data?.results);
        setImageCount(data?.count);
      }
    }
  }
  // }

  async function handleGetAllImages(page = 1) {
    if (profile?.email === undefined) {
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_BE}/api/images?email=${profile?.email}&page=${page}&shouldLog=${isAdmin}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${
            APIToken ? APIToken : localStorage.getItem("token")
          }`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.data;
      if (data === null || data?.results?.length === 0) {
        setImageCollection([]);
      } else {
        setImageCollection(data?.results);
        setImageCount(data?.count);
      }
    } else if (response.status === 401) {
      console.error("Unauthorized, please log in first");
      logoutUser();
    }
  }


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    handleGetImageCollection(value);
  };

  useEffect(() => {
    if (profile?.email !== undefined && selectedProject?.id !== undefined  ) {
      handleGetImageCollection();
    } else if (profile?.email !== undefined) {
      handleGetAllImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, profile?.email]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        {imageCount > 4 && (
          <Stack spacing={2}>
            <Pagination
              onChange={handlePageChange}
              count={pageCount}
              variant="outlined"
              color="primary"
              sx={{ paddingBottom: "1em" }}
            />
          </Stack>
        )}
      </Box>
      <ImageListCollection
        imageCollection={imageCollection}
        currentPage={currentPage}
      />      
    </div>
  );
}
