import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import UserContext from "../../context/UserContext";
import Header from "../../components/Header";
import ImageListCollection from "../../components/designs/ImageListCollection";
import AdminNav from "../../components/navigation/AdminNav";

export default function FeedbackViewer() {
  const [feedback, setFeedback] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [imageCollection, setImageCollection] = useState([]);
  // const [imageCount, setImageCount] = useState(0);
  const { APIToken, logoutUser, profile } = useContext(UserContext);

  const isAdmin = true;

  const handleRowClick = (row) => {
    setSelectedRow(selectedRow === row ? null : row);
    // handleGetAllImages();
  };

  useEffect(
    () => {
      async function fetchFeedback() {
        const response = await fetch(
          `${process.env.REACT_APP_API_BE}/api/admin/feedback?page=${currentPage}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken ? APIToken : localStorage.getItem("token")}`,
            },
          }
        );
        await response.json().then((resp) => {
          setFeedback([...feedback, ...resp.results]);
          setHasMore(resp?.next !== null);
        });
      }
      fetchFeedback();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );

  async function handleGetNextFeedback() {
    if (hasMore) {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(
    () => {
      async function handleGetAllImages(page = 1) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BE}/api/images?email=${selectedRow}&page=${page}&shouldLog=${isAdmin}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${
                APIToken ? APIToken : localStorage.getItem("token")
              }`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.data;
          if (data === null || data?.results?.length === 0) {
            setImageCollection([]);
          } else {
            setImageCollection(data?.results);
            // setImageCount(data?.count);
          }
        } else if (response.status === 401) {
          console.error("Unauthorized, please log in first");
          logoutUser();
        }
      }
      if (selectedRow !== null) {
        handleGetAllImages();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  return (
    <div>
      <Header title="Feedback Viewer" />
      <AdminNav profile={profile} />
      <Paper className="container">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow key={"abc123"}>
              <TableCell>Date</TableCell>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">Subscribed?</TableCell>
              <TableCell align="right">Use Case</TableCell>
              <TableCell align="right">Other tools</TableCell>
              <TableCell align="right">Missing Features</TableCell>
              {/* <TableCell align="right">Pay Band</TableCell> */}
              <TableCell align="right">P2 Subscribe?</TableCell>
              <TableCell align="right">KF Missing</TableCell>
              <TableCell align="right">Used Features</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedback &&
              feedback.map((row) => (
                <React.Fragment key={row.user}>
                  <TableRow
                    key={row.user}
                    onClick={() => handleRowClick(row.user)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(row.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">{row.user}</TableCell>
                    <TableCell align="right">
                      {row.subscription_active}
                    </TableCell>
                    <TableCell align="right">{row.use_case}</TableCell>
                    <TableCell align="right">{row.other_tools}</TableCell>
                    <TableCell align="right">{row.missing_features}</TableCell>
                    {/* <TableCell align="right">{row.preferred_payment_band}</TableCell> */}
                    <TableCell align="right">{row.plan_to_subscribe}</TableCell>
                    <TableCell align="right">
                      {row.missing_killer_feature}
                    </TableCell>
                    <TableCell align="right">
                      Edit: {row.edit && true ? "Y" : "N"} | SEO:{" "}
                      {row.seo && true ? "Y" : "N"} | Captions:{" "} 
                      {row.caption && true ? "Y" : "N"} | Upscale:{" "}
                      {row.upscaling && true ? "Y" : "N"} | RemoveBG:{" "}
                      {row.removebg && true ? "Y" : "N"} | KDPDoc:{" "}
                      {row.manuscript && true ? "Y" : "N"} | Projects:{" "}
                      {row.projects && true ? "Y" : "N"}
                    </TableCell>
                  </TableRow>
                  {selectedRow === row.user && (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <ImageListCollection
                          imageCollection={imageCollection}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        {hasMore && (
          <Button
            variant="contained"
            sx={{
              fontSize: "1.5rem",
              borderRadius: 5,
              marginTop: "1em",
              marginBottom: "1em",
              marginRight: "1%",
              backgroundColor: "#13b5cf",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#ff6f00",
              },
            }}
            onClick={handleGetNextFeedback}
          >
            Load More
          </Button>
        )}
      </Paper>
    </div>
  );
}
