import React, { useState, useEffect, useContext } from "react";
import UserContext from "../context/UserContext";
import { Checkbox, FormControlLabel } from "@mui/material";
import PaywallPopup from "./PaywallPopup";

export default function Visibility({ image_id }) {
  const [isVisible, setIsVisible] = useState(false);
  const [showPaywall, setShowPaywall] = useState(false);
  const { activeSubscription, APIToken, profile } = useContext(UserContext);

  const handleShowPaywall = () => {
    setShowPaywall(!showPaywall);
  };

  useEffect(() => {  
    if (!APIToken) {
      return;
    }  
    const encodedEmail = encodeURIComponent(profile.email);
    fetch(`${process.env.REACT_APP_API_BE}/api/image?image_id=${image_id}&email=${encodedEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsVisible(data[0]?.is_visible);
      });            
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image_id, APIToken]);

  const handleVisibility = () => {
    if (!activeSubscription) {
      handleShowPaywall();
      return;
    }
    setIsVisible(!isVisible);
    handleIsVisible(image_id, !isVisible);
  };

  const handleIsVisible = () => {
    fetch(`${process.env.REACT_APP_API_BE}/api/image`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken ? APIToken : localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        image_id: image_id,
        is_visible: !isVisible,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Visibility saved: ", data);
      });
  };

  return (
    <div>
      
      {showPaywall && !activeSubscription && (
        <PaywallPopup
          message="Upgrade to set visibility."
          handleShowPaywall={handleShowPaywall}
          showPaywall={showPaywall}
        />
      )}

      <FormControlLabel        
        control={<Checkbox checked={isVisible} onChange={handleVisibility} />}
        label="Public"
      />
    </div>
  );
}
