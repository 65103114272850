import  { createContext } from "react";

const UserContext = createContext({
  profile: {},  
  setProfile: () => {},
  APIToken: "",
  setAPIToken: () => {},
  hasExtendedTrial: false,
  setHasExtendedTrial: () => {},
  imageCount: 0,
  setImageCount: () => {},
  dailyImageCount: 0,
  setDailyImageCount: () => {},
  monthlyImageCount: 0,
  setMonthlyImageCount: () => {},
  storageCount: 0,
  setStorageCount: () => {},
  isUsageLimited: false,
  setIsUsageLimited: () => {},
  activeSubscription: false,
  setActiveSubscription: () => {},
  subscriptionCancelsAt: "",
  setSubscriptionCancelsAt: () => {},
  designLimit: 0,
  setDesignLimit: () => {},  
  accountHint: "",
  setAccountHint: () => {},
  noAccessAllowed: false,
  setNoAccessAllowed: () => {},
  loginUser: () => {},
  logoutUser: () => {},  
  selectedBook: null,
  setSelectedBook: () => {},
});

export default UserContext;
