import React, { useContext } from "react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import UserContext from "../../context/UserContext";
import SubscriptionLinks from "./SubscriptionLinks";

function FooterNavLinks() {
  const { activeSubscription } = useContext(UserContext);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ marginTop: "1em", marginBottom: "1em" }}
      >
        <Button href="/support" sx={{ color: "#04a9c8" }} title="Support">
          Support
        </Button>
        <Button href="/guides" sx={{ color: "#04a9c8" }} title="Guides">
          Guides
        </Button>
        <Button href="https://merchdesignai.com" target="_blank" sx={{ color: "#04a9c8" }} title="Blog">
          Blog
        </Button>
        {/* <Button href="/labs" sx={{ color: "#04a9c8" }} title="Labs">
          Labs
        </Button> */}

        <SubscriptionLinks activeSubscription={activeSubscription} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ marginTop: "1em", marginBottom: "1em" }}
      >
       
        <Button
          href="/privacy"
          sx={{ color: "#04a9c8" }}
          title="Privacy Policy"
        >
          Privacy Policy
        </Button>
        <Button href="/tos" sx={{ color: "#04a9c8" }} tile="Terms of Service">
          Terms of Service
        </Button>
        <Button
          href="/service-status"
          sx={{ color: "#04a9c8" }}
          tile="Service Status"
        >
          Service Status
        </Button>

        
      </Grid>
    </>
  );
}

export default FooterNavLinks;
