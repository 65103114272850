import "./App.css";
import React, { useState, useEffect } from "react";
import UserContext from "./context/UserContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivacyPolicy } from "./pages/Footer/PrivacyPolicy";
import PricingPage from "./pages/PricingPage";
import Landing from "./pages/LandingDalle3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TermsOfService } from "./pages/Footer/TermsOfService";
import { callCheckImageCount } from "./shared/callCheckImageCount";
import { callCheckFeedbackStatus } from "./shared/callCheckFeedbackStatus";
import DesignContainer from "./pages/DesignContainer";
import SEOListingGen from "./components/SEOListingGen";
import CaptionThisGen from "./components/CaptionThisGen";
import JoinHero from "./pages/JoinHero";
import Metrics from "./pages/admin/Metrics";
import Support from "./pages/Support";
import Debug from "./pages/Debug";
import DesignBrowser from "./pages/admin/DesignBrowser";
import MaintenanceModeToggle from "./pages/admin/MaintenanceModeToggle";
import ProfilePage from "./pages/ProfilePage";
import FeedbackViewer from "./pages/admin/FeedbackViewer";
import EditImagePage from "./pages/edit/EditImagePage";
// import SubscriberTable from "./pages/admin/SubscriberTable";
import ThankYouNewSub from "./pages/ThankYouNewSub";
import VideoBrowser from "./pages/VideoBrowser";
import GalleryBrowser from "./pages/gallery/GalleryBrowser";
import FeedbackMetrics from "./pages/admin/FeedbackMetrics";
import Guides from "./pages/how-to/Guides";
import RemoveElementsFromImage from "./pages/how-to/pages/RemoveElementsFromImage";
import UsePromptModifiers from "./pages/how-to/pages/UsePromptModifiers";
import DoNotPromptLikeThis from "./pages/how-to/pages/DoNotPromptLikeThis";
import ServiceStatus from "./pages/ServiceStatus";
// import TestIP from "./pages/admin/deprecated/TestIP";
import ImageDetail from "./pages/gallery/ImageDetail";
import PromptGuide from "./pages/PromptGuide";
import YourPhotoToColoringPage from "./pages/YourPhotoToColoringPage";
import Categories from "./components/Categories"
import CategoryBrowser from "./pages/gallery/CategoryBrowser";
import SiteMapGen from "./pages/admin/SiteMapGen";
import BookInterior from "./pages/book-builder/BookInterior";
import BookShelf from "./pages/book-builder/BookShelf";
// import Labs from "./pages/Labs";


function AppOnline() {
  const [profile, setProfile] = useState({});
  const [APIToken, setAPIToken] = useState("");
  const [hasExtendedTrial, setHasExtendedTrial] = useState(false);
  const [imageCount, setImageCount] = useState(0);  
  const [dailyImageCount, setDailyImageCount] = useState(0);
  const [monthlyImageCount, setMonthlyImageCount] = useState(0);
  const [storageCount, setStorageCount] = useState(0);
  const [isUsageLimited, setIsUsageLimited] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [subscriptionCancelsAt, setSubscriptionCancelsAt] = useState("");
  const [designLimit, setDesignLimit] = useState(0);
  const [accountHint, setAccountHint] = useState("");
  const [noAccessAllowed, setNoAccessAllowed] = useState(false);  
  const [selectedBook, setSelectedBook] = useState(null);
  const userInfo = localStorage.getItem("userInfo");

  function logoutUser() {
    setAccountHint(profile?.email);
    setProfile({});
    setAPIToken("");
    setActiveSubscription(false);
    localStorage.removeItem("activeSubscription");
    localStorage.removeItem("_md_k_");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  const HumanReadableDate = ({ date }) => {
    const formatDate = (date) => {
      return new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(date);
    };
  
    return <div>{formatDate(date)}</div>;
  };

  async function checkSubscription(userinfo) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/subscription/${userinfo?.email}/`
    );
    await response.json().then((resp) => {            
      setActiveSubscription(resp[0]?.subscription_active || false);     
      if (resp[0]?.cancel_at) {
        const niceDate = HumanReadableDate({ date: new Date(resp[0]?.cancel_at) });
        setSubscriptionCancelsAt(niceDate);
      } 
      // setSubscriptionCancelsAt(resp[0]?.cancel_at || "");
      localStorage.setItem("activeSubscription", resp[0]?.subscription_active);            
    });
  }

  useEffect(() => {
    function initializeUserInfo() {
      if (userInfo !== null || userInfo !== undefined) {
        setProfile(JSON.parse(userInfo));        
      }
      const local_userInfo = localStorage.getItem("userInfo");
      const local_sub_id = local_userInfo
        ? JSON.parse(local_userInfo).sub_id
        : null;
      if (local_sub_id !== null) {       
        const count_promise = callCheckImageCount(profile?.email);
    count_promise
      .then((counts) => {        
        setImageCount(counts?.image_count);
        setDailyImageCount(counts?.daily_image_count);
        setMonthlyImageCount(counts?.monthly_image_count);
        setStorageCount(counts?.storage_count);
        setIsUsageLimited(counts?.usage_limited);
      })
      .catch((error) => {
        console.error("Error fetching image counts:", error);        
      });      
  
        checkSubscription(JSON.parse(userInfo)); 
      }
      
    }
    initializeUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {  
  if (profile?.email) {
    const feedback_promise = callCheckFeedbackStatus(profile?.email);
    feedback_promise
      .then((feedback) => {     
        setHasExtendedTrial(feedback.has_extended_trial);
      })
      .catch((error) => {
        console.error("Error fetching feedback:", error);
        setHasExtendedTrial(false);
      });
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [profile?.email]);


useEffect(() => {
  if (!profile?.email) { 
    return;
  }
    // call /api/handle_token to get the user's token
    fetch(`${process.env.REACT_APP_API_BE}/api/handle_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: profile?.email }),
    })
      .then((response) => {
        if (response.status === 400 || response.status === 401) {
         console.log("Unauthorized, please log in first");
         return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setAPIToken(data?.token);
          localStorage.setItem("token", data?.token);
        }
      })
      .catch((error) => {
        console.error("Error fetching token:", error);
      });
  
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [profile?.email]);

  

  return (
    <GoogleOAuthProvider clientId="163385259830-er9kro8l6b6769e8cb2icu8hpst0h41e.apps.googleusercontent.com">
      <UserContext.Provider
        value={{
          profile,
          setProfile,
          APIToken,
          setAPIToken,
          hasExtendedTrial,
          setHasExtendedTrial,
          imageCount,
          setImageCount,
          dailyImageCount,
          setDailyImageCount,
          monthlyImageCount,
          setMonthlyImageCount,
          storageCount,
          setStorageCount,
          isUsageLimited,
          setIsUsageLimited,
          activeSubscription,
          setActiveSubscription,
          subscriptionCancelsAt,
          setSubscriptionCancelsAt,
          designLimit,
          setDesignLimit,
          accountHint,
          setAccountHint,
          noAccessAllowed,
          setNoAccessAllowed,
          logoutUser,
          selectedBook,
          setSelectedBook,
        }}
      >
        <BrowserRouter>
          <Routes>            
            <Route default path="/" element={<JoinHero />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/category/:category" element={<CategoryBrowser />} />
            <Route path="/videos" element={<VideoBrowser />} />
            <Route path= "/book-interior" element={<BookInterior />} />
            {/* <Route path= "/bookshelf" element={<BookShelf />} />  */}
            <Route path="/free-coloring-pages/:imageName" element={<ImageDetail />} />
            <Route path="/free-coloring-pages" element={<GalleryBrowser category="coloring" />} /> 
            <Route path="/free-sticker-designs" element={<GalleryBrowser category="stickers"/>} />          
            <Route path="/free-tshirt-designs" element={<GalleryBrowser category="tshirts" />} />
            {/* <Route path="/free_coloring_pages" element={<GalleryBrowser category="coloring" />} /> 
            <Route path="/free_sticker_designs" element={<GalleryBrowser category="stickers"/>} />          
            <Route path="/free_tshirt_designs" element={<GalleryBrowser category="tshirts" />} /> */}
            <Route path="/prompt-guide" element={<PromptGuide />} />
            <Route path="/guides" element={<Guides />} />
            <Route path="/guides/remove-elements-from-image" element={<RemoveElementsFromImage />} />
            <Route path="/guides/use-prompt-modifiers" element={<UsePromptModifiers />} />
            <Route path="/guides/do-not-prompt-like-this" element={<DoNotPromptLikeThis />} />
            {/* <Route path="/tshirt/:keyword" element={<Gallery />} />
            <Route path="/wallart/:keyword" element={<Gallery />} />     */}
            <Route path="/thankyou" element={<ThankYouNewSub />} />        
            <Route
              path="/app"
              element={
                <Landing
                  profile={profile}  
                  APIToken={APIToken}                
                  setImageCount={setImageCount}                                    
                  dailyImageCount={dailyImageCount}
                  setDailyImageCount={setDailyImageCount}    
                  monthlyImageCount={monthlyImageCount}
                  setMonthlyImageCount={setMonthlyImageCount}              
                  storageCount={storageCount}
                  setStorageCount={setStorageCount}
                  setHasExtendedTrial={setHasExtendedTrial}                  
                  isUsageLimited={isUsageLimited}
                  setIsUsageLimited={setIsUsageLimited}
                  activeSubscription={activeSubscription}  
                  subscriptionCancelsAt={subscriptionCancelsAt}                                  
                />
              }
            />
            <Route path="/designs" element={<DesignContainer />} />
            {/* <Route path="/labs" element={<Labs />} /> */}
            <Route path="/your-photo-to-coloring-page" element={<YourPhotoToColoringPage />} />            
            <Route path= "/bookshelf" element={<BookShelf />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/seolisting/:itemId" element={<SEOListingGen />} />
            <Route path="/captionthis/:image_id" element={<CaptionThisGen />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/pricing" element={<PricingPage showHeader={true} />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/auth/google" element={<Landing />} />            
            <Route path="/support" element={<Support />} />
            <Route path="/service-status" element={<ServiceStatus />} />
            {/* <Route path="/test-ip/" element={<TestIP />} /> */}
            
            <Route path="/debug" element={<Debug />} />
            <Route path="/edit/:image_id" element={<EditImagePage />} />      
            {profile?.email === "merchdesignai@gmail.com" && (
              <React.Fragment>
                <Route path="/metrics" element={<Metrics />} />
                <Route path="/admin/browser" element={<DesignBrowser />} />
                <Route path="/admin/mtc" element={<MaintenanceModeToggle />} />
                <Route path="/admin/feedback" element={<FeedbackViewer />} />
                <Route path="/admin/feedback_metrics" element={<FeedbackMetrics />} />
                {/* <Route path="/admin/subs" element={<SubscriberTable />} />     */}
                <Route path="/admin/gensitemap" element={<SiteMapGen />} />            
              </React.Fragment>
            )}
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default AppOnline;
