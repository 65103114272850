import React, { useContext, useCallback } from "react";
import Button from "@mui/material/Button";
import UserContext from "../../context/UserContext";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { getPostHeaders } from "../../utils/headers";
import { callCheckImageCount } from "../../shared/callCheckImageCount";

export default function HeroButton({ message, isLink }) {
  const {
    profile,
    setProfile,
    setAPIToken,    
    setAccountHint,
    setImageCount,    
    setDailyImageCount,
    setMonthlyImageCount,
    setIsUsageLimited,
  } = useContext(UserContext);

  const handleUser = useCallback(async (profile) => {
    try {
      let headers = getPostHeaders(
        {
          profile: profile,
        },
        "POST"
      );
      const response = await fetch(`${process.env.REACT_APP_API_BE}/api/user`, {
        ...headers,
      });
      const resp = await response.json();
      if (response.status === 200) {
        setAccountHint(resp?.email);
        return resp.token; 
      }
    } catch (error) {
      console.error("Error in handleUser:", error);
    }
  }, [setAccountHint]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        });
        console.log("response:", response);
        if (response.status === 200) {
          const userData = response.data;
          setProfile(userData);
          setAccountHint(userData.email);
          localStorage.setItem("userInfo", JSON.stringify(userData));

          const apiToken = await handleUser(userData);
          console.log("apiToken:", apiToken);
          setAPIToken(apiToken);

          try {
            const count_promise = callCheckImageCount(response?.data?.email);
            count_promise
              .then((counts) => {
                setImageCount(counts.image_count);
                setDailyImageCount(counts.daily_image_count);
                setMonthlyImageCount(counts.monthly_image_count);
                setIsUsageLimited(counts.usage_limited);
              })
              .catch((error) => {                
                setImageCount(0); // Assuming you want to set default values in case of error
                setDailyImageCount(0);
                setIsUsageLimited(false);
              });
          } catch (error) {
            console.error("Error in callCheckImageCount:", error);
          }


          localStorage.setItem("_md_k_", tokenResponse.access_token);            
          window.location.href = "/app";
        }
      } catch (error) {
        console.error("Error in googleLogin:", error);
      }
    },
    onError: (errorResponse) => console.error(`Error in googleLogin: ${errorResponse}`),
  });

  return (
    <>
      {isLink && !profile?.email && (
        <Button sx={{ marginLeft:"-9px"}} color="inherit" onClick={googleLogin}>Login</Button>      
      )}
      {!isLink && (
        <Button
          onClick={googleLogin}
          variant="contained"
          sx={{
            marginTop: "1em",
            fontWeight: "800",
            letterSpacing: "2px",
            backgroundColor: "#ff6f00",
            borderRadius: "100px",
            "&:hover": {
              backgroundColor: "#08b2cd",
            },
          }}
          size="large"
        >
          {message}
        </Button>
      )}
    </>
  );
}
