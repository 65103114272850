export const callCheckFeedbackStatus = (email) => {
  return new Promise(async (resolve, reject) => {
    if (!email) {
      console.error("Email is undefined");
      return; // Make sure to reject or resolve to handle this case appropriately.
    }
    try {
      // console.log(`Calling API for feedback status for email: ${email}`);
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/feedback/${email}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const feedbackComplete = data[0]?.id > 0;
      localStorage.setItem("has_extended_trial", feedbackComplete);
      resolve({
        has_extended_trial: feedbackComplete,
      });
    } catch (error) {
      console.error("Error in callCheckFeedbackStatus:", error);
      reject(error);
    }
  });
};
