export const callCheckImageCount = (email) => {    
    return new Promise(async (resolve, reject) => {
      if (!email) {
        // reject(new Error("Email is undefined"));
        return;
      }
      const token = localStorage.getItem("token");
      if (!token) {
        // reject(new Error("Token is undefined"));
        return;
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BE}/api/images/created/${email}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`
            },
          }
        );
        const data = await response.json();
        // this is unused i believe, todo: remove
        
        localStorage.setItem("imageCount", data?.image_count || '0');
        localStorage.setItem("dailyImageCount", data?.daily_count || '0');     
        localStorage.setItem("monthlyImageCount", data?.monthly_count || '0');  
        localStorage.setItem("storageCount", data?.storage_count || '0'); 
        resolve({
          image_count: data?.image_count,
          daily_image_count: data?.daily_count,
          monthly_image_count: data?.monthly_count,
          storage_count: data?.storage_count,
          usage_limited: data?.usage_limited,         
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  