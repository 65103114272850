import React from "react";
import { Alert } from "@mui/material";
import { AlertTitle } from "@mui/material";
import { Box } from "@mui/material";


export default function UserAlert({ title, message, severity }) {
    return (
        <Box sx={{ display:"block", margin:"auto", width: "fit-content" }}>
        <Alert severity={severity} sx={{ width: "100%" }}>
            <AlertTitle>{title}</AlertTitle>
        {message}
        </Alert>
        </Box>
    );
    }