import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import { Chip } from "@mui/material";
import { Box, Button, Typography, Grid, Paper } from "@mui/material";
import Header from "../../components/Header";
import { styled } from "@mui/material/styles";
import BarChartMD from "../../components/metrics/BarChartMD";
import StackedAreaChartMD from "../../components/metrics/StackedAreaChartMD";
import FooterNavLinks from "../../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../../components/navigation/CommunityImageLinks";
import AdminNav from "../../components/navigation/AdminNav";

export default function Metrics() {
  const [userCount, setUserCount] = useState(0);
  const [newUsersToday, setNewUsersToday] = useState(0);
  const [userDeltaYesterday, setUserDeltaYesterday] = useState(0);
  const [newUsersOverTime, setNewUsersOverTime] = useState({});
  const [merchTypeUsageOverTime, setMerchTypeUsageOverTime] = useState({}); // [TODO: add merch type usage over time data here
  // const [featureUsageOverTime, setFeatureUsageOverTime] = useState({});
  const [imageCount, setImageCount] = useState(0);
  const [newImagesToday, setNewImagesToday] = useState(0);
  const [imageDeltaYesterday, setImageDeltaYesterday] = useState(0);
  const [subscriptions, setSubscriptions] = useState(0);
  const [newSubscriptionsToday, setNewSubscriptionsToday] = useState(0);
  const [newSubsYesterday, setNewSubsYesterday] = useState(0);
  const [feedbackSubmittedCount, setFeedbackSubmittedCount] = useState(0);
  const [nudgeCount, setNudgeCount] = useState(0);
  const [newNudgesToday, setNewNudgesToday] = useState(0);
  const [nudgesYesterday, setNudgesYesterday] = useState(0);
  const [subscriberCost, setSubscriberCost] = useState(0);
  const [subscriberCostLast30Days, setSubscriberCostLast30Days] = useState(0);
  const { profile, APIToken } = useContext(UserContext);

  useEffect(() => {
    async function handleGetMetrics() {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/metrics`,
        { method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${APIToken}`,
         }
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        console.log("Metrics data: ", data);
        console.log(JSON.parse(data?.new_users_over_time));
        setUserCount(data?.total_user_count);
        setNewUsersToday(data?.new_users_today);
        setUserDeltaYesterday(data?.user_count_vs_prev_day);
        setNewUsersOverTime(JSON.parse(data?.new_users_over_time));
        // setFeatureUsageOverTime(JSON.parse(data?.feature_usage_over_time));
        setMerchTypeUsageOverTime(data?.merch_usage_over_time);
        setImageCount(data?.total_image_count);
        setNewImagesToday(data?.new_images_today);
        setImageDeltaYesterday(data?.image_count_vs_prev_day);
        setSubscriptions(data?.subscription_count);
        setNewSubscriptionsToday(data?.new_subscriptions_today);
        setNewSubsYesterday(data?.subscription_count_yesterday);
        setFeedbackSubmittedCount(data?.feedback_submitted_count);
        setNudgeCount(data?.total_nudge_count);
        setNewNudgesToday(data?.new_nudges_today);
        setNudgesYesterday(data?.nudges_yesterday);        
        setSubscriberCost(data?.subscriber_image_cost.cost_of_images);
        setSubscriberCostLast30Days(
          data?.subscriber_image_cost.cost_of_images_last_30_days
        );
      }
    }
    handleGetMetrics();
  }, [APIToken]);

  const getColor = () => {
    if (newUsersToday > 0) {
      return "success";
    } else {
      return "info";
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    padding: "1rem",
    textAlign: "end",
    color: "black",
  }));

  function consolidateCategories() {
    // call /api/consolidate_categories
    fetch(`${process.env.REACT_APP_API_BE}/api/consolidate_categories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${APIToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

      

    

  const fixUserImageAssociation = (email) => {
    // call api/fix_collection/ to fix user image association
    // the call is a POST request
    fetch(`${process.env.REACT_APP_API_BE}/api/fix_collection/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  // if the user is not an admin, redirect them to the landing page
  // useEffect(() => {
  //   const checkAdmin = async () => {
  //     if (profile && profile?.email !== "merchdesignai@gmail.com") {
  //       window.location.href = "/";        
  //     }
  //   }
  //   if (profile?.email) {
  //     checkAdmin();
  //   }
  // }
  // , [profile?.email]);


  return (
    <>    
      <Header isLandingPage={false} waitingForResponse={false} />
      <AdminNav profile={profile} />
      <Typography variant="h4" sx={{ textAlign: "center" }}>Fix User Image Association</Typography>
      {/* email input with button */}
      <Box sx={{ width: "60%", margin: "auto" }}>
        <input type="text" id="email" name="email" placeholder="Enter email" />
        <Button variant="contained" onClick={() => fixUserImageAssociation(document.getElementById("email").value)} sx={{marginLeft:"2px"}}>Fix User Image Association</Button>
      </Box>
      <Box sx={{ width: "60%", margin: "auto" }}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>Consolidate Categories</Typography>
        <Button variant="contained" onClick={consolidateCategories}>Consolidate Categories</Button>
      </Box>
      <br />
      <br />
      <br />



      <Box sx={{ width: "60%", margin: "auto" }}>
        <Typography variant="h5">Metrics</Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6}>
            <Item>
              Total User Count: <Chip label={userCount} color={getColor()} />
              <br />
              New Users Today: <Chip label={newUsersToday} color={getColor()} />
              <br />
              vs Yesterday:{" "}
              <Chip label={userDeltaYesterday} color={getColor()} />
            </Item>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: "block" }}>
            <Item>
              Total Image Count: <Chip label={imageCount} color={getColor()} />
              <br />
              New Images Today:{" "}
              <Chip label={newImagesToday} color={getColor()} />
              <br />
              vs Yesterday:{" "}
              <Chip label={imageDeltaYesterday} color={getColor()} />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              Total Subscriber Count:{" "}
              <Chip label={subscriptions} color={getColor()} />
              <br />
              New Subs Today:{" "}
              <Chip label={newSubscriptionsToday} color={getColor()} />
              <br />
              vs Yesterday:{" "}
              <Chip label={newSubsYesterday} color={getColor()} />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              Total image Cost for Subs:{" "}
              <Chip label={subscriberCost} color={getColor()} />
              <br />
              Total image cost last 30 days for subs:{" "}
              <Chip label={subscriberCostLast30Days} color={getColor()} />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              Total Nudge Count: <Chip label={nudgeCount} color={getColor()} />
              <br />
              New Nudges Today:{" "}
              <Chip label={newNudgesToday} color={getColor()} />
              <br />
              Feedback Submitted Today: <Chip label={feedbackSubmittedCount} color={getColor()}/>
              <br />
              Nudges Yesterday:{" "}
              <Chip label={nudgesYesterday} color={getColor()} />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              Paid Conversion Rate:{" "}
              <Chip
                label={`${((subscriptions / userCount) * 100).toFixed(
                  2
                )}%`}
                color={getColor()}
              />
              <br />
              Complete Full Trial Rate:{" "}
              <Chip label={`${((nudgeCount / userCount) * 100).toFixed(
                  2
                )}%`} color={getColor()} />
              <br />
              Saw Nudge and Submitted Feedback : <Chip label={`${((feedbackSubmittedCount / nudgeCount) * 100).toFixed(
                  2
                )}%`} color={getColor()}/>
              <br />              
            </Item>
          </Grid>
        </Grid>
        
        {newUsersOverTime && <BarChartMD newUsersOverTime={newUsersOverTime} />}
        {merchTypeUsageOverTime && (
          <StackedAreaChartMD resultsByDay={merchTypeUsageOverTime} />
        )}
      </Box>
      <FooterNavLinks />
      <CommunityImageLinks />

      <Box sx={{ marginBottom: "2em" }}></Box>
    </>
  );
}
