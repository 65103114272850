import React, { useEffect, useState, useCallback } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const ImageComparisonSlider = ({ beforeImage, afterImage }) => {
  const [beforeImageUrl, setBeforeImageUrl] = useState('');


  const createImageUrl = useCallback((image) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return '';
  }, []);

  useEffect(() => {
    const newBeforeImageUrl = createImageUrl(beforeImage);

    setBeforeImageUrl(newBeforeImageUrl);    
    // Cleanup function
    // return () => {
    //   if (newBeforeImageUrl) URL.revokeObjectURL(newBeforeImageUrl);
    //   if (newAfterImageUrl) URL.revokeObjectURL(newAfterImageUrl);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beforeImage]);



  return (
    <ReactCompareSlider
      itemOne={
        <ReactCompareSliderImage
          src={beforeImageUrl}
          alt="Before image"
        />
      }
      itemTwo={
        <ReactCompareSliderImage
          src={afterImage}
          alt="After image"
          style={{
            backgroundColor: 'white',
            backgroundImage: `
              linear-gradient(45deg, #ccc 25%, transparent 25%),
              linear-gradient(-45deg, #ccc 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, #ccc 75%),
              linear-gradient(-45deg, transparent 75%, #ccc 75%)`,
            backgroundSize: `20px 20px`,
            backgroundPosition: `0 0, 0 10px, 10px -10px, -10px 0px`,
          }}
        />
      }
      style={{ width: '100%', height: '50%' }}
    />
  );
};

export default ImageComparisonSlider;