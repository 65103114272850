import React, { useEffect } from "react";
import { visualDesignOptions } from "../../options/visual-design-options";
import {
  getBackgroundColor,
  getTextColor,
  getFontWeight,
} from "../../properties/ui-properties";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { CardActionArea } from "@mui/material";

export default function CreateMerch({
  handleChooseMerchandiseType,
  selectedOption,
}) {
  useEffect(() => {
    if (selectedOption === null) {
      handleChooseMerchandiseType(visualDesignOptions[0]);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [selectedOption]);
  

  return (        
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"      
      spacing={{ xs: 1, sm: 2, md: 4 }}
      sx={{ flexWrap: 'wrap', width: '100%' }}
    >
      {visualDesignOptions.map((option, index) => (
        <Card
          id={option.name}
          key={option.name}
          sx={{
            maxWidth: 250,
            flex: '1 1 auto', // Allow cards to grow and shrink
            minWidth: 250, // Set a minimum width to prevent cards from becoming too small
            margin: '0.5em', // Add some margin around cards
            paddingLeft: ".5em",
            paddingRight: ".5em",
            paddingTop: "1em",
            backgroundColor: getBackgroundColor(option, selectedOption),
            color: getTextColor(option, selectedOption),
          }}
        >
          <CardActionArea onClick={() => handleChooseMerchandiseType(option)}>
            <CardMedia
              component="img"
              height="245"
              image={option.image}
              alt={option.name}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: getFontWeight(option, selectedOption) }}
              >
                {option.displayName}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
      
    </Stack>
    
  );
}
