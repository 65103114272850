import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import NavButtons from "../../../components/navigation/NavButtons";
import FooterNavLinks from "../../../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../../../components/navigation/CommunityImageLinks";
// import VideoCard from "../../../components/VideoCard";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";


export default function UsePromptModifiers() {
    const title = "How to use prompt modifiers to fine tune an image";
    const subTitle = "Lengthly prompts won't do you any good. Here's how to use prompt modifiers to get the best results from your AI generated images."
    const body1 = 
        "MerchDesign AI had a lot of options to help you fine tune your AI generated images. One of the most powerful tools is the prompt modifier.";
    const body2 = "This image was generated using a lengthly prompt with the default modifiers of 'Cartoon' and 'More Detail', which resulted in an image that had extra detail added beyond the subjct.";
    const body3 = "The image was then generated with a simple prompt of just the subject and with the 'Child Drawing' and 'Less Detail' modifiers. Notice how the image is much simpler and more focused on the subject.";
    const body4 = "By using prompt modifiers, you can quickly and easily fine tune your generated images to get the results you want.";
  return (
    <>
    <NavButtons showTagLine={false}/>
      <Paper
        elevation={3}
        sx={{
          margin: "auto",
          padding: "2em",
          textAlign: "left",          
          width: { xs: "90%", sm: "80%", md: "70%" },
        }}
      >
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"1em"}}>
        <Typography variant="h2">{title}</Typography>
        </Box>

        
        {/* <VideoCard video_id={"w9gwxt6pDLU"} title={title} steps={steps}/> */}
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", margin:"2em"}}>
        <Typography variant="h5">{subTitle}</Typography>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body1}
        </Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body2}
        </Typography>
        </Box>
        <Box component={"img"} src="/smiley-no-modifier.webp" alt="MerchDesign AI generated image with Cartoon and More Detail modifiers" sx={{width:"100%", maxWidth:"500px"}}/>
        <Link href="https://merchdesign.ai/free-coloring-pages">Image from our community generated free coloring pages repository</Link>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body3}
        </Typography>
        </Box>
        <Box component={"img"} src="/smiley-with-modifier.webp" alt="MerchDesign AI generated image with Child Drawing and Less Detail modifiers" sx={{width:"100%", maxWidth:"500px"}}/>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body4}
        </Typography>
        </Box>
      </Paper>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}
