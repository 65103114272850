import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import logo400 from "../../logo-400.webp";
import HeroButton from "../buttons/HeroButton";
import { Link as RouterLink } from "react-router-dom";

export default function PublicAppBar({ showPricing, setShowPricing }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleShowPricing = () => {
    setShowPricing(!showPricing);
  };
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== "null") {
      setIsLoggedIn(true);
    }
  }, []);

  const isMobile = useMediaQuery("(max-width:900px)");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#08b2cd" }}>
      <Toolbar>
        <Box
          component="img"
          sx={{
            maxWidth: "50px",
            width: "50px",
            height: "50px",
            marginRight: "16px",
            display: "block",
            animation: "spin 5s linear 1",
            cursor: "pointer",
          }}
          alt="MerchDesign AI logo"
          srcSet={`${logo400} 50w`}
          sizes="(max-width: 50px) 50px"
          src={logo400} // Fallback image
          width="50"
          height="50"
          onClick={() => (window.location.href = "/")}
        />
        <Typography
          variant="h5"
          component={RouterLink}
          to="/"
          sx={{
            flexGrow: 1,
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          MerchDesign AI
        </Typography>
        {isMobile ? (
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={handleMenuClose}
                component="a"
                href={isLoggedIn ? "/app" : "/"}
              >
                {isLoggedIn ? "App" : "Home"}
              </MenuItem>
              {showPricing !== undefined && (
                <MenuItem
                  onClick={() => {
                    handleShowPricing();
                    handleMenuClose();
                  }}
                >
                  Pricing
                </MenuItem>
              )}
              <MenuItem
                onClick={handleMenuClose}
                component="a"
                href="/prompt-guide"
              >
                Prompt Guide
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <HeroButton message="Login" isLink={true} />
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box
            component="div"
            sx={{
              flexGrow: 1,
              display: "flex",
              position: "absolute",
              right: "5px",
            }}
          >
            <Button color="inherit" href={isLoggedIn ? "/app" : undefined}>
              {isLoggedIn && "App"}
            </Button>
            {showPricing !== undefined && (
              <Button color="inherit" onClick={handleShowPricing}>
                Pricing
              </Button>
            )}
            <Button color="inherit" href="/prompt-guide">
              Prompt Guide
            </Button>
            <HeroButton message="Login" isLink={true} />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
