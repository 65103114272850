import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function BarChartMD({ newUsersOverTime }) {
  
  return (
    <div>
      <div>
        {newUsersOverTime?.series && (
          <BarChart
            series={[{ data: newUsersOverTime?.series[0]?.data, type: "bar" }]}
            height={290}
            xAxis={[{ data: newUsersOverTime?.xAxis[0].data, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
      </div>
      {/* <div>
        {featureUsageOverTime?.series && (
          <BarChart
            series={[{ data: featureUsageOverTime?.series[0]?.data, type: "bar" }]}
            height={290}
            xAxis={[{ data: featureUsageOverTime?.xAxis[0].data, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
      </div> */}
      {/* <div>
        {merchTypeUsageOverTime?.series && (
          <BarChart
            series={[{ data: merchTypeUsageOverTime?.series[0]?.data, type: "bar" }]}
            height={290}
            xAxis={[{ data: merchTypeUsageOverTime?.xAxis[0].data, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
      </div> */}
    </div>
  );
}

