import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";


function ImageHighlight({currentItem}) {
    const openInNewTab = (url) => {    
        if (!url) return;
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      };

      
  return (
    <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "1%",
      }}
    >
      <Box
        component="img"
        srcSet={
          currentItem?.low_res_image_url
            ? currentItem?.low_res_image_url
            : currentItem?.variation_url
            ? currentItem?.variation_url
            : currentItem?.image_url
        }
        src={
          currentItem?.low_res_image_url
            ? currentItem?.low_res_image_url
            : currentItem?.variation_url
            ? currentItem?.variation_url
            : currentItem?.image_url
        }
        alt="Generated with MerchDesign AI"
        sx={{
          width: "90%", // Adjust according to the parent container's width
          maxWidth: "90%", // Ensure it does not exceed the desired maximum width
          height: "auto",
          cursor: "pointer",
        }}
        onClick={() =>
          openInNewTab(
            currentItem?.image_url
          )
        }
      />
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <IconButton
          aria-label={`View upscaled image`}
          title={"View Upscaled Image"}
          onClick={() =>
            openInNewTab(
             currentItem?.variation_url
            )
          }
        >
          <ImageIcon sx={{
              color: currentItem?.variation_url ? "#13b5cf":"#bdbdbd",
              cursor: currentItem?.variation_url ? "pointer":"not-allowed"
            }}/>
        </IconButton>
        <IconButton
          aria-label={`View background removed image`}
          title={"View Background Removed Image"}
          onClick={() =>                        
            openInNewTab(
              currentItem?.no_background_url
            )
          }
        >
          <ImageOutlinedIcon 
            sx={{
              color: currentItem?.no_background_url ? "#13b5cf":"#bdbdbd",
              cursor: currentItem?.no_background_url ? "pointer":"not-allowed"
            }}/>
        </IconButton>
      </Box>
    </Box>
  </Box>

  );
}
export default ImageHighlight;