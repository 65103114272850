import React from "react";
import { Paper } from "@mui/material";
import FooterActionButtons from "../../components/buttons/FooterActionButtons";

export function PrivacyPolicy() {
  return (
    <Paper
      elevation={3}
      sx={{
        // marginLeft: "2em",
        // marginRight: "2em",
        padding: "2em",
        textAlign: "left",
      }}
    >
      {/* <Header showDesigns={true} /> */}
      <br />
      <h1>Privacy Policy for MerchDesign.ai</h1>
      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or make a purchase from
        MerchDesign.ai (the “Site”).
      </p>
      <h4>Effective Date: July 24, 2023</h4>
      <br />
      <p>
        At MerchDesign.ai, we are committed to protecting your privacy and
        ensuring the security of your personal information. This Privacy Policy
        outlines how we collect, use, disclose, and protect the information you
        provide while using our software product, MerchDesign.ai. Please read
        this Privacy Policy carefully to understand our practices regarding your
        personal data.
      </p>
      <br />
      <h2>Information We Collect</h2>
      <h4>1.1 Personal Information:</h4>
      <p>
        When you use MerchDesign.ai, we may collect certain personal information
        that you voluntarily provide to us, such as your name, email address,
        and any other information you provide while using the software.
      </p>
      <br />
      <h4>1.2 Usage Information:</h4>
      <p>
        We may collect certain information about your usage of MerchDesign.ai,
        including but not limited to your IP address, device information,
        browser type, operating system, and interactions with the software. This
        information is collected through cookies and similar technologies.
      </p>
      <br />
      <h4>Use of Information:</h4>
      2.1 Providing and Improving the Service: We use the information we collect
      to provide and improve MerchDesign.ai, personalize your experience, and
      respond to your inquiries and requests. This includes using OpenAI to
      craft prompts based on data entered by the user into the form and
      Leaonardo AI to generate images based on the prompts.
      <br />
      <h4>2.2 Analytics:</h4>
      MerchDesign.ai utilizes Google Analytics to collect and analyze usage data
      to help us understand how the software is being used and to improve its
      functionality. Google Analytics may collect information such as your IP
      address and usage patterns. Google Analytics has its own privacy policy,
      which you can review at
      https://policies.google.com/technologies/partner-sites.
      <br />
      <h4>2.3 Storage of Image Files:</h4>
      MerchDesign.ai stores links to created image files, actual image files are
      stored on Leonardo.AI, refer to their terms of service for further
      details, https://leonardo.ai/terms-of-service/. For free accounts, images
      are created as public on Leonardo.AI, for paid accounts, images are
      created as private on Leonardo.AI. Deleting your images deletes them on
      Leonardo.AI. For free accounts, images are deleted after 24 hours.
      <br />
      <h4>2.4 Collection of IP Addresses:</h4>
      We collect the IP addresses of users who access and interact with our
      services. This data is collected to monitor and prevent abuse of our trial
      accounts. Specifically, we use IP addresses to identify and prevent
      fraudulent activities, such as creating multiple trial accounts from the
      same user. This helps us ensure the fair usage of our services. 
      <h3 style={{fontSize:"initial", fontFamily:"Source Sans Pro, sans-serif"}}>2.4.1 Storage and Retention of IP Addresses</h3>
      IP addresses and related data are stored
      securely and are retained for as long as necessary to fulfill the purposes
      outlined in this policy, or as required by law. We take appropriate
      measures to protect the security of this data. 
      <h3 style={{fontSize:"initial", fontFamily:"Source Sans Pro, sans-serif"}}>2.4.2 Sharing and Disclosure</h3>
      We do not share IP addresses with third parties except as required by law
      or to protect our rights, property, or safety, or that of our users or the
      public. In such cases, we ensure that any third parties with whom we share
      this information are also committed to protecting your privacy. 
      <h3 style={{fontSize:"initial", fontFamily:"Source Sans Pro, sans-serif"}}>2.4.3 User Rights</h3> 
      You have the right to access, correct, or delete your personal
      data, including IP addresses we have collected. If you wish to exercise
      any of these rights, please contact us at support at merchdesign.ai.

      <h3 style={{fontSize:"initial", fontFamily:"Source Sans Pro, sans-serif"}}>2.4.4 Contact Information</h3>
      If you have any questions or concerns about our
      use of your IP address or other personal data, please contact us at support at merchdesign.ai.
      <br />
      <br />
      <h2>Data Sharing and Disclosure</h2>
      <h4>3.1 Service Providers:</h4>
      We may engage trusted third-party service providers to perform functions
      and provide services to us. These service providers may have access to
      your personal information solely for the purpose of carrying out their
      services on our behalf, and they are obligated to maintain the
      confidentiality and security of your information.
      <br />
      <h4>3.2 Legal Requirements:</h4>
      We may disclose your personal information if required to do so by law or
      if we believe that such disclosure is necessary to (a) comply with a legal
      obligation, (b) protect and defend our rights or property, (c) prevent or
      investigate possible wrongdoing in connection with MerchDesign.ai, (d)
      protect the safety of users of MerchDesign.ai or the public, or (e)
      detect, prevent, or otherwise address fraud, security, or technical
      issues.
      <br />
      <h4>Data Security:</h4>
      We take reasonable measures to protect the security of your personal
      information and implement appropriate technical and organizational
      safeguards to prevent unauthorized access, disclosure, alteration, or
      destruction of your information. However, no data transmission or storage
      system can be guaranteed to be 100% secure. Therefore, we cannot warrant
      the absolute security of your information.
      <br />
      <h4>Your Rights:</h4>
      You have certain rights regarding your personal information under
      applicable data protection laws. These rights may include the right to
      access, correct, or delete your personal information. If you would like to
      exercise any of these rights, please contact us using the contact
      information provided below.
      <br />
      <h4>Changes to the Privacy Policy:</h4>
      We reserve the right to modify this Privacy Policy at any time, so please
      review it periodically. If we make material changes to this Privacy
      Policy, we will notify you by email or through a notice on MerchDesign.ai.
      Your continued use of MerchDesign.ai after any modifications to this
      Privacy Policy will constitute your acceptance of such changes.
      <br />
      <h4>Contact Us:</h4>
      <p>Support at MerchDesign dot AI</p>
      <p>
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or our privacy practices, please contact us.
      </p>
      <FooterActionButtons />
    </Paper>
  );
}
