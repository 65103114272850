import React from "react";
import { Select, MenuItem } from "@mui/material";

const FontSizeSelector = ({
  bookConfig,
  textFontSize,
  handleSetTextFontSize,
}) => {
  const FontSizeOptions = Array.from({ length: 32 }, (_, i) => 8 + i * 4);

  const handleChange = (e) => {
    const { value } = e.target;
    handleSetTextFontSize(value);
  };

  return (
    <Select
      labelId="font-size-label"
      label="Font Size"
      name="FontSize"
      value={textFontSize || bookConfig?.globalFontSize || ""}
      onChange={handleChange}
      required
    >
      {FontSizeOptions.map((size) => (
        <MenuItem key={size} value={size}>
          {size}px
        </MenuItem>
      ))}
    </Select>
  );
};

export default FontSizeSelector;
