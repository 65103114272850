import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  CircularProgress,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloudUpload, Transform } from "@mui/icons-material";
import PhotoConvertSettings from "../components/PhotoConvertSettings";
import ImageComparisonSlider from "../components/ImageComparisonSlider";
import DownloadIcon from "@mui/icons-material/Download";
import Chip from "@mui/material/Chip";
import UserContext from "../context/UserContext";

const PhotoToColoring = ({ setImageSelected }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { profile, APIToken } = useContext(UserContext);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    setErrorMessage("");
    if (file && file.size > maxSize) {
      setErrorMessage(
        "File size exceeds 10 MB limit. Please choose a smaller file."
      );
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
      setConvertedImage(null);
      setErrorMessage("");
    }
  };

  const handleChooseNewPhoto = () => {
    setSelectedFile(null);
    setConvertedImage(null);
    setImageSelected(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("email", profile?.email);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/convert_to_coloring_page/`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Token ${APIToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        setConvertedImage(URL.createObjectURL(blob));
        setSettings(JSON.parse(response.headers.get("X-Image-Metadata")));
        setImageSelected(true);
      } else {
        console.error("Conversion failed");
        setImageSelected(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const applySettings = async (settings) => {
    try {
      // set cursor to loading
      setIsLoading(true);
      //  send selected file and settings to the backend
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("settings", JSON.stringify(settings));

      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/apply_settings_adjustments/`,
        {
          method: "POST",
          headers: {
            // Remove "Content-Type" header, let the browser set it with the boundary
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        setConvertedImage(URL.createObjectURL(blob));
      } else {
        console.error("Conversion failed");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error in applySettings:", error);
    }
  };

  const handleSettingsChange = (name, newValue) => {
    const newSettings = { ...settings, [name]: newValue };
    setSettings(newSettings);
    applySettings(newSettings);
  };

  useEffect(() => {
    return () => {
      if (convertedImage) {
        URL.revokeObjectURL(convertedImage);
      }
    };
  }, [convertedImage]);

  const createImageUrl = useCallback((image) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return "";
  }, []);

  const handleDownloadClick = () => {
    if (convertedImage) {
      fetch(convertedImage)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "converted_coloring_image.png";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error downloading the image:", error));
    }
  };

  return (
    <>
      {!convertedImage && (
        <Card
          sx={{
            maxWidth: 600,
            margin: "auto",
            mt: 5,
            borderStyle: "solid",
            borderColor: "#08acc8",
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography variant="h5" component="div" gutterBottom>
              Photo to Coloring Page Converter
            </Typography>
            <Box
              sx={{
                border: "2px dashed grey",
                borderRadius: 2,
                p: 3,
                textAlign: "center",
                cursor: "pointer",
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 180,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              component="label"
            >
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept="image/*"
              />
              <CloudUpload
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography variant="body1">
                Click to upload or drag and drop
              </Typography>
              <Typography variant="body2" color="text.secondary">
                PNG or JPG (MAX. 10 MB)
              </Typography>
            </Box>
            {selectedFile && (
              <>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  src={createImageUrl(selectedFile)}
                  alt="Converted Coloring"
                />
                <Typography variant="body2" color="text.secondary" mt={2}>
                  Selected file: {selectedFile.name}
                </Typography>
              </>
            )}
          </CardContent>
          <CardActions sx={{ p: 3, pt: 0 }}>
            <Button
              className="plausible-event-name=Labs-ConvertToColoring"
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#13b5cf",
                "&:hover": { backgroundColor: "#0e8fa3" },
                borderRadius: 5,
              }}
              fullWidth
              onClick={handleSubmit}
              disabled={!selectedFile || isLoading}
              startIcon={
                isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Transform />
                )
              }
            >
              {isLoading ? "Converting..." : "Convert to Coloring Art"}
            </Button>
          </CardActions>
        </Card>
      )}
      {convertedImage && (
        <Box sx={{ width: "100%", margin: "auto" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ flexWrap: "wrap", width: "100%" }}
          >
            <Card sx={{ maxWidth: 600, margin: "auto", mt: 5 }}>
              <CardContent sx={{ p: 3 }}>
                <ImageComparisonSlider
                  beforeImage={selectedFile}
                  afterImage={convertedImage}
                />
              </CardContent>

              <Chip
                sx={{
                  color: "#04a9c8",
                  marginBottom: 2,
                  "& .MuiChip-icon": {
                    color: "#04a9c8",
                  },
                }}
                icon={<DownloadIcon />}
                label="Download"
                onClick={handleDownloadClick}
              />
            </Card>
            <Card
              sx={{
                maxWidth: 300,
                margin: "auto",
                mt: 5,
                borderStyle: "solid",
                borderColor: "#08acc8",
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <PhotoConvertSettings
                  settings={settings}
                  handleSettingsChange={handleSettingsChange}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Stack>
          {selectedFile && (
            <Button
              variant="contained"
              size="large"
              onClick={handleChooseNewPhoto}
              sx={{
                fontSize: "1.5rem",
                //  marginLeft: 1,
                marginTop: 2,
                borderRadius: 5,
                color: "#ffffff",
                backgroundColor: "#13b5cf",
                "&:hover": {
                  backgroundColor: "#0e8fa3",
                },
              }}
            >
              Select New Photo
            </Button>
          )}
        </Box>
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          sx={{ width: "100%", bgcolor: "#f44336", color: "white" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PhotoToColoring;
