import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import UserContext from './UserContext';

const MaintenanceModeContext = createContext();

export const useMaintenanceMode = () => useContext(MaintenanceModeContext);

export const MaintenanceModeProvider = ({ children }) => {
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState("False");
  const { APIToken } = useContext(UserContext);

  useEffect(() => {  
    
    const fetchMaintenanceMode = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BE}/api/admin/sysvar/isInMaintenanceMode/`, {
          headers: {
            "Content-Type": "application/json",            
          }
        });

        if (response.status === 200) {
          setIsInMaintenanceMode(response.data[0].value);
        } else {
          setIsInMaintenanceMode("False");
        }
      } catch (error) {
        // Log the error for debugging purposes but do not expose it to the users
        console.error('Failed to fetch maintenance mode', error);
        // Fallback to "False" if there is an error
        setIsInMaintenanceMode("False");
      }
    };

    fetchMaintenanceMode();
  }, [APIToken]);

  return (
    <MaintenanceModeContext.Provider value={{ isInMaintenanceMode }}>
      {children}
    </MaintenanceModeContext.Provider>
  );
};
