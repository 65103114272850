import React, { useState } from "react";
import { Box } from "@mui/material";
import { Input } from "@mui/material";

const ColorPicker = ({ color, handleColorChange }) => {
  // set default color to transparent
  const [localColor, setLocalColor] = useState("#000000")
  const handleAllColorChange = (value) => {
    // console.log(`Color changed to: ${value}`);
    setLocalColor(value);
    handleColorChange(value);
  };

  return (
    <Box component="div">
      <Input
        type="color"
        sx={{ width: "80%", height: "40px" }}
        value={localColor}
        onChange={(event) => handleAllColorChange(event.target.value)}
      />      
    </Box>
  );
};

export default ColorPicker;
