// send request to openai to generate prompt for selected merchandize
// import axios from "axios";

function textToJson(text) {    
  const lines = text.split('\n').filter(line => line.trim() !== '');
  const productDescription = {};
  let keywords = [];

  lines.forEach(line => {
    if (line.startsWith("🔸Brand:")) {
      productDescription.Brand = line.replace("🔸Brand:", "").trim();
    } else if (line.startsWith("🔸Design Title:")) {
      productDescription.DesignTitle = line.replace("🔸Design Title:", "").trim();
    } else if (line.startsWith("🔸Feature Bullet 1:")) {
      productDescription.FeatureBullet1 = line.replace("🔸Feature Bullet 1:", "").trim();
    } else if (line.startsWith("🔸Feature Bullet 2:")) {
      productDescription.FeatureBullet2 = line.replace("🔸Feature Bullet 2:", "").trim();
    } else if (line.startsWith("🔸Description:")) {
      productDescription.Description = line.replace("🔸Description:", "").trim();
    } else if (line.startsWith("🔸Keywords:")) {
      keywords = line.replace("🔸Keywords:", "").trim().split(',').map(keyword => keyword.trim());
    }
  });

  // If keywords were found, add them to the object
  if (keywords.length > 0) {
    productDescription.Keywords = keywords;
  }

  // Convert the JavaScript object to a JSON string
  return JSON.stringify(productDescription, null, 2);
}


export const generateProductCopy = async (
  subject,
  designUrl,
  APIToken,
  email
) => {
  // call api/generate/listing endpoint
  const response = await fetch(
    `${process.env.REACT_APP_API_BE}/api/generate/listing`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify({
        subject,
        designUrl,
        email,
      }),
    }
  );
  const data = await response.json();  
  var jsonResp = textToJson(data.generated_listing);  
  return jsonResp;
};
