import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import IconButton from "@mui/material/IconButton";

export default function CreateSEOListingButton({image_id}) {
    
  return (
    <IconButton
      href={`/seolisting/${image_id}`}
      sx={{  
        color: "rgba(0, 0, 0)",         
      }}
    >
      <AutoAwesomeIcon 
      sx={{marginRight: "0.5em"}}/>SEO Listing
    </IconButton>

  );
}
