import React, {useState, useEffect, useContext} from "react";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import UserContext from "../context/UserContext";



  function PaywallPopup({ message, showPaywall, handleShowPaywall, title = "Subscribe for more great features", buttonTxt = "upgrade" }) {
    const [open, setOpen] = useState(true);
    const {profile} = useContext(UserContext);
    
    useEffect(() => {
      async function logAccess() {
        const response = await fetch(
          `${process.env.REACT_APP_API_BE}/api/paywall/access/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },       
            body: JSON.stringify({
              paywall_type: title,
              paywall_action: buttonTxt,
              email: profile?.email,
            }),
          }
        );        
        await response.json();
      }
      if (profile?.email) {
        logAccess();
      }      
    }, [profile?.email, buttonTxt, title]);

    useEffect(() => {
        setOpen(showPaywall);
      }
    , [showPaywall]);
    
  return (
    <Dialog open={open} onClose={handleShowPaywall}>
      <DialogTitle>{title}</DialogTitle>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1em",
        border: "1px solid red",
        borderRadius: "5px",
        backgroundColor: "rgba(255, 0, 0, 0.1)",
        margin: "1em",
      }}
    >
      <p>
       {message}
      </p>
      {(buttonTxt === "upgrade" || buttonTxt === "storage limit" || buttonTxt === "monthly limit" || buttonTxt === "Join Now") && (
        <Box component="div" sx={{display:"flex", justifyContent:"space-around", width:"100%"}}>
      <Button
        variant="contained"
        color="success"
        href="/pricing"
        target="_blank"
      >
        Upgrade Now
      </Button>
      <Button
      variant="contained"
      color="warning"
      onClick={handleShowPaywall}
      target="_blank"
    >
      Maybe Later
    </Button>
    </Box>
    )}
      {buttonTxt === "maintenance" && (
        <Button
        variant="contained"
        color="primary"
        onClick={handleShowPaywall}
      >
        OK
      </Button>)}
    </Box>
    </Dialog>

  );
}
export default PaywallPopup;