import React, { useState, useCallback, useEffect, useContext } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Box, Paper, Typography, Button, Grid } from "@mui/material";
import BookImageSelector from "./BookImageSelector";
import NavButtons from "../../components/navigation/NavButtons";
import UserContext from "../../context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookSettings from "./BookSettings";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { FormControl, InputLabel } from "@mui/material";
import StyledFontSelector from "./StyledFontSelector";
import FontSizeSelector from "./FontSizeSelector";


const BookInterior = () => {
  const [pages, setPages] = useState([]);
  const [bookConfig, setBookConfig] = useState({});
  const [pageOrder, setPageOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showBookSettings, setShowBookSettings] = useState(false);
  const [imageSelectionDialog, setImageSelectionDialog] = useState({
    open: false,
    pageIndex: null,
    image: null,
  });
  const [usedImages, setUsedImages] = useState(new Set());

  const { profile, APIToken, selectedBook } = useContext(UserContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAddTextToPage = useCallback(async (pageId, text, font, fontSize) => {
    try {      
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/page/element/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
          body: JSON.stringify({
            book_id: selectedBook,
            page_id: pageId,
            type: "text",
            content: text,
            position_x: 0,
            position_y: 0,
            width: 100,
            height: 100,
            font: font || bookConfig?.global_font || "Arial",
            font_size: fontSize || bookConfig?.global_font_size || 12,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add text element");
      }

      const newElement = await response.json();      

      setPages((prevPages) =>
        prevPages.map((page) =>
          page.id === pageId
            ? { ...page, elements: [...page.elements, newElement] }
            : page
        )
      );

    } catch (error) {
      console.error("Error adding text element:", error);
    }
    
  }, [APIToken, selectedBook, bookConfig]);


  const handleDeletePage = useCallback(
    async (pageId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BE}/api/page/${pageId}/delete`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Token ${APIToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete page");
        }

        setPages((prevPages) => prevPages.filter((page) => page.id !== pageId));
        setPageOrder((prevOrder) => prevOrder.filter((id) => id !== pageId));

        console.log("Page deleted:", pageId);
      } catch (error) {
        console.error("Error deleting page:", error);
      }
    },
    [APIToken]
  );

  const handleDeleteElement = useCallback(
    async (pageId, elementId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BE}/api/page/${pageId}/element/${elementId}/delete`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Token ${APIToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete element");
        }

        setPages((prevPages) =>
          prevPages.map((page) =>
            page.id === pageId
              ? {
                  ...page,
                  elements: page.elements.filter(
                    (elem) => elem.id !== elementId
                  ),
                }
              : page
          )
        );

        console.log("Element deleted:", elementId);
      } catch (error) {
        console.error("Error deleting element:", error);
      }
    },
    [APIToken]
  );

  useEffect(() => {
    const fetchBookData = async () => {
      if (!APIToken || !profile?.email || !selectedBook) {
        console.log("Missing required data for fetching book");
        return;
      }

      setIsLoading(true);
      try {        
        const bookResponse = await fetch(
          `${process.env.REACT_APP_API_BE}/api/book/${selectedBook}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            },
          }
        );
        const bookData = await bookResponse.json();
        setBookConfig(bookData);        

        // Fetch all pages for the book
        const pagesResponse = await fetch(
          `${process.env.REACT_APP_API_BE}/api/book/${selectedBook}/pages`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            },
          }
        );
        const pagesData = await pagesResponse.json();        
        setPages(pagesData);

        // If book.page_order is empty, create a default order based on page numbers
        if (bookData.page_order && bookData.page_order.length > 0) {
          setPageOrder(bookData.page_order);
        } else {
          const defaultOrder = pagesData
            .sort((a, b) => a.page_number - b.page_number)
            .map((page) => page.id);
          setPageOrder(defaultOrder);
        }
      } catch (error) {
        console.error("Error fetching book data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookData();
  }, [APIToken, profile?.email, selectedBook]);

  const movePage = useCallback((dragIndex, hoverIndex) => {
    setPageOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const [reorderedItem] = newOrder.splice(dragIndex, 1);
      newOrder.splice(hoverIndex, 0, reorderedItem);
      // console.log(`Moved page from ${dragIndex} to ${hoverIndex}`);
      // console.log(newOrder);
      return newOrder;
    });
  }, []);

  useEffect(() => {
    if (!pageOrder || pageOrder.length === 0) {
      return;
    }
    reorderPages(pageOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOrder]);

  const reorderPages = async (newOrder) => {
    const url = `${process.env.REACT_APP_API_BE}/api/reorder-page/`;
    const data = {
      book_id: selectedBook,
      page_order: newOrder,
    };    
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      // .then((data) => console.log(data))
      .catch((error) => console.error("Error moving page:", error));
  };

  const addImageToPage = useCallback(
    (pageIndex, image) => {
      if (image.edited_url || image.variation_url) {
        setImageSelectionDialog({ open: true, pageIndex, image });
      } else {
        addImageToPageHelper(pageIndex, image, image.image_url);
      }
      setUsedImages((prevUsedImages) => new Set(prevUsedImages).add(image.id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageOrder, selectedBook, APIToken]
  );

  const addImageToPageHelper = useCallback(
    (pageIndex, image, selectedUrl) => {
      const pageId = pageOrder[pageIndex];

      if (!pageId) {
        console.error(`No page found at index ${pageIndex}`);
        return;
      }

      setPages((prevPages) => {
        return prevPages.map((page) => {
          if (page.id === pageId) {
            return {
              ...page,
              elements: [
                ...page.elements,
                {
                  id: Date.now(),
                  type: "image",
                  content: selectedUrl,
                },
              ],
            };
          }
          return page;
        });
      });

      const url = `${process.env.REACT_APP_API_BE}/api/page/element/create`;
      const data = {
        book_id: selectedBook,
        page_id: pageId,
        type: "image",
        content: selectedUrl,
        position_x: 0,
        position_y: 0,
        width: 100,
        height: 100,
      };      

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        // .then((data) => {
        //   console.log("added:", data);
        //   // Optionally update state with the response
        // })
        .catch((error) => console.error("Error adding image to page:", error));
    },
    [pageOrder, selectedBook, APIToken]
  );

  const handleImageSelection = (selectedUrl) => {
    addImageToPageHelper(
      imageSelectionDialog.pageIndex,
      imageSelectionDialog.image,
      selectedUrl
    );
    setImageSelectionDialog({ open: false, pageIndex: null, image: null });
  };

  const handleAddPage = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/page/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
          body: JSON.stringify({
            book_id: selectedBook,
            page_number: pages.length + 1,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add page");
      }

      const newPage = await response.json();

      setPages((prevPages) => [...prevPages, newPage]);
      setPageOrder((prevOrder) => [...prevOrder, newPage.id]);

      console.log("New page added:", newPage);
    } catch (error) {
      console.error("Error adding page:", error);
    }
  };

  const handleExportBook = () => {
    const url = `${process.env.REACT_APP_API_BE}/api/book/export`;
    const data = {
      book_id: selectedBook,
      configuration: {
        size: bookConfig.specification.name.toLowerCase(),
        use_backpage: bookConfig.back_page_style,
        add_review_request: false,
        review_request_text: "",
      },
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL from the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link and set the URL as the href
        const a = document.createElement("a");
        a.href = url;
        a.download = `${bookConfig.title}.docx`; // You can set the filename here
        document.body.appendChild(a);

        // Trigger the download
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error exporting book:", error));
  };

  return (
    <>
      <NavButtons showTagLine={false} />
      <DndProvider backend={HTML5Backend}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 164px)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : 250,
                flexShrink: 0,
                mb: isMobile ? 2 : 0,
                mr: isMobile ? 0 : 2,
                overflow: "auto",
              }}
            >
              <BookImageSelector usedImages={usedImages} />
              <BookSettings
                showBookSettings={showBookSettings}
                setShowBookSettings={setShowBookSettings}
                bookConfig={bookConfig}
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                width: isMobile ? "100%" : "auto",
              }}
            >
              {!isLoading && pages.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="body1">
                    No pages found for this book.
                  </Typography>
                </Box>
              )}
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (                
                <Grid container rowSpacing={6} spacing={2} sx={{ mb: 2 }}>
                {pageOrder.map((pageId, index) => {
                  const page = pages.find((p) => p.id === pageId);
                  if (!page) {
                    console.warn(`Page with id ${pageId} not found`);
                    return null;
                  }
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={pageId}>
                      <Page
                        key={`${pageId}-${page.elements.length}`}
                        index={index}
                        page={page}
                        movePage={movePage}
                        addImageToPage={addImageToPage}
                        onDeletePage={handleDeletePage}
                        onDeleteElement={handleDeleteElement}
                        onAddText={handleAddTextToPage}
                        isMobile={isMobile}
                        bookConfig={bookConfig}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
              borderTop: "1px solid #ccc",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Button
              variant="contained"
              onClick={handleAddPage}
              sx={{
                fontSize: isMobile ? "1rem" : "1.5rem",
                borderRadius: 5,
                backgroundColor: "#13b5cf",
                "&:hover": {
                  color: "#13b5cf",
                  backgroundColor: "#ffffff",
                },
                width: isMobile ? "100%" : "auto",
              }}
            >
              Add Page
            </Button>
            {pages.length > 0 && (
              <Button
                variant="contained"
                onClick={handleExportBook}
                sx={{
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  borderRadius: 5,
                  backgroundColor: "#13b5cf",
                  "&:hover": {
                    color: "#13b5cf",
                    backgroundColor: "#ffffff",
                  },
                  width: isMobile ? "100%" : "auto",
                }}
              >
                Export Book
              </Button>
            )}
          </Box>
        </Box>

        <Dialog
          open={imageSelectionDialog.open}
          onClose={() =>
            setImageSelectionDialog({
              open: false,
              pageIndex: null,
              image: null,
            })
          }
        >
          <DialogTitle>Select Image Version</DialogTitle>
          <DialogContent>
            <Typography>Choose which version of the image to use:</Typography>
            {imageSelectionDialog.image?.edited_url && (
              <Button
                onClick={() =>
                  handleImageSelection(imageSelectionDialog.image.edited_url)
                }
              >
                Use Edited Version
              </Button>
            )}
            {imageSelectionDialog.image?.variation_url && (
              <Button
                onClick={() =>
                  handleImageSelection(imageSelectionDialog.image.variation_url)
                }
              >
                Use Upscaled Version
              </Button>
            )}
            <Button
              onClick={() =>
                handleImageSelection(imageSelectionDialog.image.image_url)
              }
            >
              Use Original Version
            </Button>
          </DialogContent>
        </Dialog>
      </DndProvider>
    </>
  );
};

const Page = ({
  page,
  index,
  movePage,
  addImageToPage,
  onDeletePage,
  onDeleteElement,
  onAddText,
  isMobile,
  bookConfig,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "page",
    item: { index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  
  const [, drop] = useDrop(
    () => ({
      accept: ["page", "image"],
      hover: (item, monitor) => {
        if (item.index !== undefined && item.index !== index) {
          movePage(item.index, index);
          item.index = index;
        }
      },
      drop: (item, monitor) => {
        if (monitor.getItemType() === "image") {
          addImageToPage(index, item.image);
        }
      },
    }),
    [index, movePage, addImageToPage]
  );

  return (
    <Paper
      ref={(node) => drag(drop(node))}
      elevation={3}
      sx={{
        p: 2,
        opacity: isDragging ? 0.5 : 1,
        height: isMobile ? "auto" : "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h6" component="h3">
          Page {index + 1}
        </Typography>
        <IconButton
          aria-label="delete page"
          onClick={() => onDeletePage(page.id)}
          sx={{ padding: 0 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        {page?.elements?.length > 0 &&
          page.elements.map((element) => (
            <PageElement
              key={element.id}
              element={element}
              onDelete={() => onDeleteElement(page.id, element.id)}
            />
          ))}
      </Box>
      <AddTextDialog 
        pageId={page.id} 
        onAddText={onAddText} 
        bookConfig={bookConfig} />
    </Paper>
  );
};

const PageElement = ({ element, onDelete }) => {
  const ElementContent = () => {
    if (element.type === "text") {
      return <Typography paragraph
      sx={{fontFamily: element.font, fontSize: element.font_size}}
      >
        {element.content}
        </Typography>;
    } else if (element.type === "image") {
      return (
        <Box
          component="img"
          src={element.content}
          alt="Page element"
          sx={{ maxWidth: "100%", height: "auto" }}
        />
      );
    }
    return null;
  };

  return (
    <Box sx={{ position: "relative", mb: 2 }}>
      <IconButton
        aria-label="delete element"
        onClick={onDelete}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: 0,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <ElementContent />
    </Box>
  );
};

const AddTextDialog = ({ pageId, onAddText, bookConfig }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [font, setFont] = useState(bookConfig?.global_font || "Arial");
  const [textFontSize, setTextFontSize] = useState(bookConfig?.global_font_size || 12);

  
  function handleSetFont(font){
    setFont(font);
  }

  function handleSetTextFontSize(textFontSize){
    setTextFontSize(textFontSize);
  }


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setText("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddText(pageId, text, font, textFontSize);
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          color: "white",
          backgroundColor: "#13b5cf",
          "&:hover": { backgroundColor: "#0e8fa3" },
          borderRadius: 5,
          mt: 2,
        }}
      >
        Add Text
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Text to Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            label="Enter text"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="global-font-label">Global Font</InputLabel>
            <StyledFontSelector 
              bookConfig={bookConfig} 
              font={font} 
              handleSetFont={handleSetFont} />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="element-font-size-label">
              Element Font Size
            </InputLabel>
            <FontSizeSelector
              bookConfig={bookConfig}
              textFontSize={textFontSize}
              handleSetTextFontSize={handleSetTextFontSize}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// ***********************************
// TODO: Going to enable this later
// ***********************************
// const AddElementButton = ({ pageId }) => {
//   const [isAdding, setIsAdding] = useState(false);
//   const [content, setContent] = useState("");
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(`Adding element to page ${pageId}: ${content}`);
//     setIsAdding(false);
//     setContent("");
//   };

//   if (!isAdding) {
//     return (
//       <Button
//         variant="contained"
//         onClick={() => setIsAdding(true)}
//         sx={{
//           color: "white",
//           backgroundColor: "#13b5cf",
//           "&:hover": { backgroundColor: "#0e8fa3" },
//           borderRadius: 5,
//         }}
//       >
//         Add Text
//       </Button>
//     );
//   }

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
//       <TextField
//         fullWidth
//         multiline
//         rows={4}
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//         sx={{ mb: 2 }}
//       />
//       <Button type="submit" variant="contained" color="primary">
//         Add
//       </Button>
//     </Box>
//   );
// };

export default BookInterior;
