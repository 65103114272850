import React from "react";
import { Box, Button, DialogContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

function UsageExceeded({
  message,
  showUsageExceeded,
  handleShowUsageExceeded,
}) {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setOpen(showUsageExceeded);
  }, [showUsageExceeded]);

  const handleSetOpen = () => {
    setOpen(!open);
    handleShowUsageExceeded();
  }

  return (
    <Dialog open={open} onClose={handleShowUsageExceeded}>
      <DialogTitle>Usage Exceeded</DialogTitle>
      <DialogContent>
        <Typography variant="h6">🚀 Whoa, there, Picasso! 🎨</Typography>
        <Typography variant="body1">
          It looks like you've unleashed a whole museum's worth of creativity! 
          Our brushes are officially exhausted, and our canvas is taking
          a breather.
        </Typography>
        <Typography variant="body1">
          <br />
          Time to let the paint dry! 🐾
        </Typography>

        <Typography variant="body1">
          <br />
          Your monthly image generation allocation<br /> resets at the strike of midnight on the last day of the month (GMT). 
          <br />Come back then for another round of
          masterpiece-making. 
          <br />Until then, dream up some
          fresh ideas,  <br />and we'll be right here waiting to bring them to life!
        </Typography>

        <Typography variant="body1">
          <br />
          Need help? Reach out to us at{" "}
          <a href="mailto:support@merchdesign.ai">support@merchdesign.ai</a>!
        </Typography>
        <Typography variant="body1">
          ✨ See you soon, art star! ✨
        </Typography>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1em",
          border: "1px solid red",
          borderRadius: "5px",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
          margin: "1em",
        }}
      >
        <p>{message}</p>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
        variant="contained"
        color="success"
        href="/pricing"
        target="_blank"
        sx={{marginRight: ".25em"}}
      >
        Upgrade Now
      </Button>
        <Button
          variant="contained"
          color="primary"
          href="/tos"
          sx={{ marginRight: ".25em", marginLeft: ".25em" }}
        >
          Terms of Service
        </Button>
        
        <Button variant="contained" color="primary" sx={{marginLeft: ".25em"}} onClick={handleSetOpen}>        
          Close
        </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
export default UsageExceeded;
