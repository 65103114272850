import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Checkbox,
  Divider,
  ListItemButton,  
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import UserContext from "../../context/UserContext";

export default function ProjectSelectDialog({
  item,
  openProjectList,
  handleOpen,
  projectList,
  handleRefreshProjects
}) {
  const [checked, setChecked] = useState([]);
  const { profile, APIToken } = useContext(UserContext);

  const handleToggle = (value) => () => {        
    const proj = projectList.find(item => item.name === value);

    const currentIndex = checked.indexOf(proj.id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(proj.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleApplyProjectSelection = (e) => {
    e.preventDefault();    
    const handleAddImageToProject = async () => {      
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/project`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${APIToken}`,
          },
          body: JSON.stringify({
            image_id: item?.id,
            // project id where projectList?.name === checked
            project_id: checked,
            email: profile?.email,
          }),
        }
      );

      if (response.status === 200) {
        await response.json();        
        handleRefreshProjects();
      }
    };
    handleAddImageToProject();
    handleOpen();
  };

  return (
    <Dialog      
      open={openProjectList}
      onClose={handleOpen}
    >
      <DialogTitle>Projects</DialogTitle>
      <DialogContent>
        {projectList.length > 1 && (
          <DialogContentText>
        Select one or more projects to add this design to.
        </DialogContentText>
        )}
        {projectList.length === 1 && (
          <DialogContentText>
          You have no projects. Create a project from the Projects sidebar first.
          </DialogContentText>
          )}
        <Divider sx={{mt:2, mb:2}} />
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
            {projectList.filter(project => project.name !== 'All').map((project) => {
              const labelId = `checkbox-list-label-${project?.name}`;

              return (
                <ListItem
                  key={project?.name}                 
                  disablePadding
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(project?.name)}
                    dense
                  >
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(project?.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />

                    <ListItemText id={labelId} primary={`${project?.name}`} />
                    <Chip
                      label={project?.images.length}
                      color="success"
                      variant="outlined"
                      style={{ marginLeft: ".5em" }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApplyProjectSelection}>Apply</Button>
        <Button onClick={handleOpen}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
