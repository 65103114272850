import React from 'react';
import { useMaintenanceMode } from './context/MaintenanceModeContext';
import AppOffline from './AppOffline';
import AppOnline from './AppOnline';


const App = () => {
  
  const { isInMaintenanceMode } = useMaintenanceMode();
  return isInMaintenanceMode === "True" ? <AppOffline /> : <AppOnline />;
};

export default App;
