import React, { useState, useEffect, useContext } from "react";
import UserContext from "../context/UserContext";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import LinkStripeAccount from "../components/account/LinkStripeAccount";





export default function PlanLimitTracker({ planUsage, projectCount, featureUsage }) {
  const [planLimits, setPlanLimits] = useState({});
  const [showLinkStripe, setShowLinkStripe] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { profile, imageCount, subscriptionCancelsAt } = useContext(UserContext);
  
  const handleCallback = () => {
    setRefresh(!refresh);
  };

  const storageData = [
    { name: "imageCount", value: planUsage.storage_count },
    {
      name: "Storage Limit",
      value: planLimits.max_image_storage - planUsage.storage_count,
    },
  ];

  const COLORS = ["orange", "#0ba5c5"];

  const stats = {
    totalImageCount: imageCount,
    dailyImageCount: planUsage.daily_image_count,
    docgen_usage: planUsage.docgen_usage,
    monthlyImageCount: planUsage.monthly_image_count,    
    planLimits: {
      planName: planLimits.plan_name,
      // maxImagesPerDay: planLimits.designs_per_day,
      maxImagesPerMonth: planLimits.designs_per_month,
      maxProjectsPerMonth: planLimits.projects_per_month,
      maxDailyImages: planLimits.designs_per_day,
      maxMonthlyImages: planLimits.designs_per_month,
      imageStorage: planLimits.max_image_storage,
      docgen_limt:featureUsage?.docgen_limit || 0,
    },
  };

  useEffect(() => {
    if (profile?.email) {
      retrievePlanLimits();
    }
    // eslint-disable-next-line
  }, [profile?.email, refresh]);

  // check user's plan limits
  const retrievePlanLimits = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/subscription/limits/${profile?.email}/`
    );
    const data = await response.json();
    setPlanLimits(data);    
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography variant="h4">Plan Limits</Typography>
        {subscriptionCancelsAt ? (<Typography variant="body1" color="red" sx={{ fontWeight: 600 }} gutterBottom>
          Your subscription has ended as of {subscriptionCancelsAt}
        </Typography>

        ) : (
        <Typography variant="body1" color="#0ba5c6" sx={{ fontWeight: 600 }} gutterBottom>
          Your current plan is {planLimits.plan_name}
        </Typography>
        )}
        {planLimits.plan_name === "Trial" && (
          <Box sx={{backgroundColor:"lightyellow", paddingBottom:"1em", paddingTop:"1em"}}>
        <Typography variant="body1" gutterBottom>Paid for subscription but still seeing Trial?</Typography>
        <Typography variant="body2" gutterBottom>This can happen if you used an email other than {profile?.email} when you paid with Stripe.</Typography>
        <Button variant="contained" onClick={()=>setShowLinkStripe(!showLinkStripe)}>Link Stripe account to MerchDesign AI account</Button>
        </Box>
      )}
      {showLinkStripe && (
        <LinkStripeAccount mdAccountEmail={profile?.email} handleCallback={handleCallback} showDescription={false} />
      )}
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>          
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                
                  <Typography variant="h6" gutterBottom>
                    Images Created Today
                  </Typography>
                
                <ResponsiveContainer width="100%" height={200}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <CropOriginalIcon
                      sx={{
                        color: "#0ba5c6",
                        fontSize: "6em",
                        marginTop: ".1em",
                        marginRight: ".15em",
                      }}
                    />
                    <Typography sx={{ color: "#0ba5c6" }} variant="h1">
                      {stats.dailyImageCount}
                    </Typography>
                  </Box>
                </ResponsiveContainer>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body2" color="textSecondary">
                    Images Created This Month: {planUsage.monthly_image_count}
                  </Typography>   
                  <Typography variant="body2" color="textSecondary">
                    Monthly Image Creation Limit: {stats.planLimits.maxImagesPerMonth}
                  </Typography>   
                                 
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total Image Storage
                </Typography>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={storageData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {storageData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body2" color="textSecondary">
                    Images Stored: {planUsage.storage_count}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Plan Maximum: {stats.planLimits.imageStorage}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Projects Created / Limit
                </Typography>
                <Typography variant="h4">
                  {projectCount} / {planLimits.projects_per_month}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Manuscripts Created / Limit
                </Typography>
                <Typography variant="h4">
                  {featureUsage?.docgen_limit || 0} /{" "}
                  {planLimits.manuscripts_per_month || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
