import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMediaQuery } from "@mui/material";
import { toPng } from 'html-to-image';

import NavButtons from "../../components/navigation/NavButtons";
import EditImage from "./EditImage";
import BreadcrumbNav from "../../components/navigation/BreadcrumbNav";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DrawIcon from "@mui/icons-material/Draw";
import ApprovalIcon from "@mui/icons-material/Approval";
import AbcIcon from "@mui/icons-material/Abc";
import ColorPicker from "../../components/edit/ColorPicker";
import UndoIcon from "@mui/icons-material/Undo";
import Divider from "@mui/material/Divider";
import FontSelector from "../../components/edit/FontSelector";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import FooterNavLinks from "../../components/navigation/FooterNavLinks";
import Switch from "@mui/material/Switch";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ImageIcon from "@mui/icons-material/Image";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import UserContext from "../../context/UserContext";

function EditImagePage() {
  const [selectedTool, setSelectedTool] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [penSize, setPenSize] = useState(20);
  const [textColor, setTextColor] = useState("#000000");
  const [fontSize, setFontSize] = useState(30);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [undo, setUndo] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const [userId, setUserId] = useState();
  const [showTransparent, setShowTransparent] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [imageVersion, setImageVersion] = useState("original");
  const [imageDirty, setImageDirty] = useState(false);
  const [paintText, setPaintText] = useState(false);
  const [enablePaintText, setEnablePaintText] = useState(false);
  const { APIToken, profile } = useContext(UserContext);
  const { image_id } = useParams();
  const location = useLocation();
  const { caption } = location.state || {}; // Ensure to provide fallback to prevent errors
  const printRef = useRef(null);
  const canvasRef = useRef(null);
  const draggableTextRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  // const label = { inputProps: { "aria-label": "Transparent" } };

  useEffect(() => {
    if (!APIToken) {
      return;
    }
    // fetch item from api and set it to currentItem    
    const encodedEmail = encodeURIComponent(profile.email);
    fetch(`${process.env.REACT_APP_API_BE}/api/image?image_id=${image_id}&email=${encodedEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserId(data[0]?.user_id);
        setCurrentItem(data[0]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageDirty]);

  useEffect(() => {
    const handleFeatureUsage = async (feature) => {
      // post to api/usage/acccess with email and feature
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/feature/access/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: userId,
            feature: feature,
          }),
        }
      );
      await response.json();
    };
    if (userId) {
      handleFeatureUsage("edit_image");
    }
  }, [userId]);

  const handlePaintText = () => {
    setPaintText(true);
    // handleSetTool("");
  };

  const handleTransparencyChange = () => {
    setShowTransparent(!showTransparent);
    // set color to transparent
    setSelectedColor("");
  };

  const handleSetImageDirty = (state) => {
    setImageDirty(state);
  };

  const handleDeleteImageVersion = (version) => {
    // Delete the image version from the database
    // debugger;
    fetch(`${process.env.REACT_APP_API_BE}/api/deleteimage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify({
        image_id: image_id,
        image_version: imageVersion,
        email: profile?.email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {        
        setImageVersion("original");
        // update current item to remove edit_url
        setCurrentItem({
          ...currentItem,
          edited_url: "",
        });
      });
  };

  const handleChangeImage = (version) => {
    // debugger;
    if (version === "original") {
      setImageVersion("original");
    }
    if (version === "upscaled") {      
      setImageVersion("upscaled");
    }
    if (version === "no_background") {      
      setImageVersion("no_background");
    }
    if (version === "edited") {      
      setImageVersion("edited");
    }
  };


  const handleSave = () => {    
  const currentRef = canvasRef.current;

  const dataURL = currentRef.toDataURL({
    pixelRatio: window.devicePixelRatio, // Ensures high resolution
  });
    

    fetch(`${process.env.REACT_APP_API_BE}/api/saveimage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image_id: image_id,
        image_data: dataURL,
        image_version: "edited",
      }),
    })
      .then((response) => response.json())
      .then((data) => {        
        setImageVersion("edited");
        handleSetImageDirty(false);
      });
  };

  const handleSetTool = (tool) => {
    setSelectedTool(tool);
    setPenSize(2);
  };

  const handleColorChange = (color) => {
    if (selectedTool === "text") {
      setTextColor(color);
    } else {
      setSelectedColor(color);
    }
  };

  const handleSetFontFamily = (font) => {
    setFontFamily(font);
  };

  const undoLastUpdate = () => {    
    setUndo(!undo);
    if (imageDirty) {
      handleSetImageDirty(false);
    }
  };

  const handleFontSizeChange = (value) => {
    setFontSize(value);
  };

  const handlePenSizeChange = (value) => {
    setPenSize(value);
  };
 

const handleDownloadClick = useCallback(() => {
  if (canvasRef.current === null) {
    return;
  }
  if (printRef.current === null) {
    return;
  }
  
  
  toPng(canvasRef.current, {     
      cacheBust: true,
      // TODO: Let user configure output size
      height: "2048",
      width: "2048",
      pixelRatio: window.devicePixelRatio, // Ensures high resolution      
    })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `${currentItem?.name}-edited.png`
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
}, [canvasRef, printRef, currentItem])


  return (
    <>
      <NavButtons showTagLine={false}/>
      <Box
        component="div"
        sx={{ display: "block", width: "fit-content", margin: "auto" }}
      >
        <BreadcrumbNav here="Edit Image" prev="Designs" prevprev="Home" />
      </Box>
      <Box
        sx={{
          display: "flex", // Establish a flex container
          justifyContent: "center", // Center children horizontally
          alignItems: "flex-start", // Align children at the top
          width: "100%", // Take full width to accommodate inner content
        }}
      >       
        <Paper
          elevation={4}
          sx={{
            display: "flex",
            flexDirection: "column", // or 'row' depending on your layout
            justifyContent: "center", // centers content vertically in a column layout
            alignItems: "center", // centers content horizontally
            padding: "1em",
            width: isMobile ? "95%" : "70%",
            maxWidth: isMobile ? "95%" : "70%",
            height: "fit-content",
            overflow: "auto",
            background: "lightgrey",
          }}
        >
          <Typography
            align="center"
            variant="h2"
            sx={{ color: "white", fontWeight: "400", paddingBottom: ".2em" }}
          >
            Edit Image
          </Typography>
          <Box
            component="div"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <IconButton
              aria-label={`View original image`}
              // if make icon stand out to show it is the loaded image
              // variant={imageVersion === "original" ? "contained" : "outlined"}

              title={"View Original Image"}
              onClick={() => handleChangeImage("original")}
            >
              <ImageIcon
                sx={{
                  color: currentItem?.image_url ? "#13b5cf" : "#bdbdbd",
                  cursor: currentItem?.image_url ? "pointer" : "not-allowed",
                  boxShadow:
                    imageVersion === "original"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  fontWeight: imageVersion === "original" ? "bold" : "normal",
                }}
              />
            </IconButton>
            <IconButton
              aria-label={`View edited image`}
              title={"View Edited Image"}
              onClick={() => handleChangeImage("edited")}
            >
              <ImageIcon
                sx={{
                  color: currentItem?.edited_url ? "#13b5cf" : "#bdbdbd",
                  cursor: currentItem?.edited_url ? "pointer" : "not-allowed",
                  boxShadow:
                    imageVersion === "edited"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  fontWeight: imageVersion === "edited" ? "bold" : "normal",
                }}
              />
            </IconButton>
            <IconButton
              aria-label={`View upscaled image`}
              title={"View Upscaled Image"}
              onClick={() => handleChangeImage("upscaled")}
            >
              <ImageIcon
                sx={{
                  color: currentItem?.variation_url ? "#13b5cf" : "#bdbdbd",
                  cursor: currentItem?.variation_url
                    ? "pointer"
                    : "not-allowed",
                  boxShadow:
                    imageVersion === "upscaled"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  fontWeight: imageVersion === "upscaled" ? "bold" : "normal",
                }}
              />
            </IconButton>
            <IconButton
              aria-label={`View background removed image`}
              title={"View Background Removed Image"}
              onClick={() => handleChangeImage("no_background")}
            >
              <ImageOutlinedIcon
                sx={{
                  color: currentItem?.no_background_url ? "#13b5cf" : "#bdbdbd",
                  cursor: currentItem?.no_background_url
                    ? "pointer"
                    : "not-allowed",
                  boxShadow:
                    imageVersion === "no_background"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  fontWeight:
                    imageVersion === "no_background" ? "bold" : "normal",
                }}
              />
            </IconButton>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "fit-content",
              marginLeft: "0%",
            }}
          >            
              <EditImage
                image_id={image_id}
                imageVersion={imageVersion}
                tool={selectedTool}
                handleSetTool={handleSetTool}
                captionThis={caption}
                replaceColor={selectedColor}
                color={selectedColor}
                penSize={penSize}
                textColor={textColor}
                fontSize={fontSize}
                fontFamily={fontFamily}
                undo={undo}
                handleUndo={undoLastUpdate}
                canvasRef={canvasRef}    
                printRef={printRef}     
                draggableTextRef={draggableTextRef} 
                paintText={paintText}   
                enablePaintText={enablePaintText}   
                setEnablePaintText={setEnablePaintText}
                handleSetImageDirty={handleSetImageDirty}
              />            
          </Box>
        </Paper>
        <Paper elevation={4} sx={{ padding: "1em", marginLeft: ".5em" }}>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start", // Align vertically at the top
              marginLeft: 2, // Add some spacing between Paper and Buttons
            }}
          >
            <Button
              className="plausible-event-name=Edit-Undo"
              sx={{ color: "#04a9c8" }}
              title="Undo"
              onClick={undoLastUpdate}
            >
              <UndoIcon
                sx={{
                  fontSize: "3.5rem",
                  cursor: imageDirty ? "pointer" : "not-allowed",
                  color: imageDirty ? "#04a9c8" : "lightgray",
                }}
              />
            </Button>
            <Divider />
            <Button
              className="plausible-event-name=Edit-MagicWand"
              sx={{ color: "#04a9c8" }}
              title="Magic Wand"
              onClick={() => handleSetTool("magicwand")}
            >
              <AutoFixHighIcon
                sx={{
                  fontSize: "3.5rem",
                  color: "#04a9c8",
                  boxShadow:
                    selectedTool === "magicwand"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  borderRadius: "20%",
                  padding: "5%",
                }}
              />
            </Button>
            {selectedTool === "magicwand" && (
              <>
                <FormControlLabel
                  // control={<Switch {...label} defaultChecked name="Transparent" onChange={}/>}
                  control={
                    <Switch
                      checked={showTransparent}
                      onChange={handleTransparencyChange}
                      inputProps={{
                        "aria-label": !showTransparent
                          ? "Transparent"
                          : "Color",
                      }}
                    />
                  }
                  label={!showTransparent ? "Transparent" : "New Color"}
                  labelPlacement="top"
                />

                {showTransparent && (
                  <ColorPicker
                    className="plausible-event-name-Edit-ColorPicker"
                    color={selectedColor}
                    handleColorChange={handleColorChange}
                  />
                )}
              </>
            )}

            <Button
              className="plausible-event-name-Edit-Text"
              sx={{ color: "#04a9c8" }}
              title="Text"
              onClick={() => handleSetTool("text")}
            >
              <AbcIcon
                sx={{
                  fontSize: "3.5rem",
                  color: "#04a9c8",
                  boxShadow:
                    selectedTool === "text"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  borderRadius: "20%",
                  padding: "5%",
                }}
              />
            </Button>
            {selectedTool === "text" && (
              <>
            <Button className="plausible-event-name=Edit-StampText" sx={{ color: "#04a9c8" }} title="Place Text" onClick={handlePaintText}>
              <ApprovalIcon 
               sx={{
                fontSize: "3.5rem",
                color: enablePaintText ? "#04a9c8" : "lightgray",
                boxShadow:
                  selectedTool === "text" && enablePaintText
                    ? "0px 0px 10px rgba(0,0,0,0.5)"
                    : "none",
                cursor: enablePaintText ? "pointer" : "not-allowed",
                borderRadius: "20%",
                padding: "5%",
               }}
              />
              </Button>
              <Divider />
              </>
            )}
            {selectedTool === "text" && (
              <>
                <FontSelector
                  fontFamily={fontFamily}
                  handleSetFontFamily={handleSetFontFamily}
                />
                <ColorPicker
                  color={selectedColor}
                  handleColorChange={handleColorChange}
                />
                <Box component={"div"} sx={{ display: "block", width: "100%" }}>
                  <Slider
                    className="plausible-event-name=Edit-PenSize"
                    aria-label="Pen Size"
                    defaultValue={30}
                    step={1}
                    min={0}
                    max={128}
                    valueLabelDisplay="auto"
                    onChange={(event, newValue) => {
                      handleFontSizeChange(newValue); // Properly handle state updates
                    }}
                    valueLabelFormat={(value) => `${value}px`} // Directly format the label here
                  />
                </Box>
                <Divider />
              </>
            )}

            <Button
              className="plausible-event-name=Edit-Eraser"
              sx={{ color: "#04a9c8" }}
              title="Remove Background"
              onClick={() => handleSetTool("eraser")}
            >
              <DriveFileRenameOutlineIcon
                title="Remove Background"
                sx={{
                  fontSize: "3.5rem",
                  boxShadow:
                    selectedTool === "eraser"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  borderRadius: "20%",
                  padding: "5%",
                }}
              />
            </Button>

            <Button
              className="plausible-event-name=Edit-PenSelect"
              sx={{ color: "#04a9c8" }}
              title="Draw"
              onClick={() => handleSetTool("pen")}
            >
              <DrawIcon
                title="Draw"
                sx={{
                  fontSize: "3.5rem",
                  boxShadow:
                    selectedTool === "pen"
                      ? "0px 0px 10px rgba(0,0,0,0.5)"
                      : "none",
                  borderRadius: "20%",
                  padding: "5%",
                }}
              />
            </Button>

            {(selectedTool === "pen" || selectedTool === "eraser") && (
              <>
                <Divider />
                <Box component={"div"} sx={{ display: "block", width: "100%" }}>
                  <Slider
                    className="plausible-event-name=Edit-PenSize"
                    aria-label="Pen Size"
                    value={penSize}
                    step={1}
                    min={0}
                    max={48}
                    valueLabelDisplay="auto"
                    onChange={(event, penSize) => {
                      handlePenSizeChange(penSize); // Properly handle state updates
                    }}
                    valueLabelFormat={(penSize) => `${penSize}px`} // Directly format the label here
                  />
                </Box>
              </>
            )}
            {selectedTool === "pen" && (
              <ColorPicker
                className="plausible-event-name=Edit-ColorPicker"
                color={selectedColor}
                handleColorChange={handleColorChange}
              />
            )}
            <Divider />
            <Button sx={{ color: "#04a9c8" }} title="Save" onClick={handleSave}>
              <SaveIcon
                className="plausible-event-name=Edit-Save"
                sx={{                  
                  fontSize: "3.5rem",
                  cursor: imageDirty ? "pointer" : "not-allowed",
                  color: imageDirty ? "#04a9c8" : "lightgray",
                }}
              />
            </Button>

            <Button
              className="plausible-event-name=Edit-Download"
              sx={{ color: "#04a9c8" }}
              title="Download"
              onClick={handleDownloadClick}
            >
              <DownloadIcon
                sx={{ fontSize: "3.5rem", color: "#04a9c8" }}
                title="Dowload"
              />
            </Button>
          </Box>
        </Paper>
        <IconButton aria-label="delete" onClick={handleDeleteImageVersion}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <FooterNavLinks />
    </>
  );
}

export default EditImagePage;
