import React from "react";
import Typography from "@mui/material/Typography";
import Draggable from "react-draggable";

function DraggableText({
  draggableTextRef,
  initialPos,
  text,
  textData,
  setTextData,
  textColor,
  fontSize,
  fontFamily,
  isDraggable,
  handleDoubleClick,
  handleMouseMove,
  handleMouseDownText,
  handleMouseUpText,
}) {
  const handleStop = (e, data) => {
    // Update position state on drag stop
    setTextData({
      ...textData,
      x: data.x,
      y: data.y,
    });
  };

  return (
    <Draggable
      axis="both"
      handle=".handle"
      defaultPosition={initialPos}
      position={textData}
      grid={[1, 1]}
      scale={1}
      onStop={handleStop}
    >
      <div className="handle" style={{ cursor: "move" }}>
        <Typography
          ref={draggableTextRef}
          variant="h6"
          color={textColor}
          fontSize={fontSize}
          fontFamily={fontFamily}
          onDoubleClick={handleDoubleClick}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDownText}
          onMouseUp={handleMouseUpText}
        >
          {text}
        </Typography>
      </div>
    </Draggable>
  );
}

export default DraggableText;
//   return isDraggable ? (
//   ) : (
//     <Typography
//     variant="h6"
//     // color={textColor}
//     color="purple"
//     fontSize={fontSize}
//     fontFamily={fontFamily}
//     onDoubleClick={handleDoubleClick}
//     onMouseMove={handleMouseMove}
//     onMouseDown={handleMouseDownText}
//     onMouseUp={handleMouseUpText}
//     sx={{ position: "absolute", top: textData.y, left: textData.x }}
//   >
//     {text}
//   </Typography>
//     );
