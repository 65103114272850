import React, { useState, useContext } from "react";
import { Button, Divider, Box, Typography } from "@mui/material";
import CustomGoogleLoginButton from "../buttons/CustomGoogleLoginButton";
import UserContext from "../../context/UserContext";
import logo from "../../MerchDesignTransparentCleanSM-svg.webp";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import MobileListMenu from "./MobileListMenu";
import JustButtons from "./JustButtons";

function NavButtons({ showTagLine }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { profile } = useContext(UserContext);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isIpad = useMediaQuery("(max-width:1200px");

  const handleShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  return (
    <>
      <Divider sx={{ marginTop: "2em", marginBottom: "1em" }} />
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          backgroundColor: "aliceblue",
        }}
      >
        <Box
          component="div"
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button href="/app">
            <Box
              component="img"
              sx={{
                height: "50px",
                width: "50px",
                display: "inline-grid",
                margin: "auto",
              }}
              alt="MerchDesign AI logo"
              src={logo}
              width="50"
              height="50"
            />
          </Button>
          {showTagLine && (
            <Box
              component="div"
              sx={{
                display: isMobile ? "none" : isIpad ? "none" : "flex",
                maxWidth: "50%",
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              <Typography variant="body2">
                Create coloring book pages and print on demand ready designs in
                under 2 minutes with MerchDesign AI
              </Typography>
            </Box>
          )}
        </Box>

        {isMobile && showMobileMenu && (
          
          <MobileListMenu  
          profile={profile}           
            handleShowMobileMenu={handleShowMobileMenu} 
          />
          
        )}
        {isMobile && (
          // <Box
          //   component="div"
          //   sx={{ display: "flex", justifyContent: "center" }}
          // >
          <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
            <MenuIcon
              sx={{
                color: "#04a9c8",
                fontSize: "3.5rem",
              }}
              cursor="pointer"
              onClick={handleShowMobileMenu}
            />
          </Box>
        )}
        {/* {showMobileMenu && <MobileListMenu />} */}
        {!isMobile && (
          <Box
            component="div"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <JustButtons />
           
          </Box>
        )}

        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: isMobile ? "0%" : isIpad ? "0%" : !showTagLine? "0%" : "30%",
            marginTop: ".5em"
          }}
        >
          <CustomGoogleLoginButton />
        </Box>
      </Box>

      <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
    </>
  );
}

export default NavButtons;
