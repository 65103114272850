import React from "react";
import { Button } from "@mui/material";


export default function SubscriptionLinks({activeSubscription}) {
  return (
activeSubscription ? (
    <Button
      href="https://billing.stripe.com/p/login/7sI3cC2gHaJvb3W3cc"
      target="_blank"
      sx={{ color: "#04a9c8"  }}
      title="Manage Subscription"
    >
      Manage Subscription
    </Button>
  ) :
  (
    <Button href="/pricing" sx={{ color: "#04a9c8" }} title="Pricing">
      Pricing
    </Button>
  
  )
  );
}
