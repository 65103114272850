import React, { useEffect, useState, useContext } from "react";
import { Button, Stack } from "@mui/material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import UserContext from "../../context/UserContext";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export default function JustButtons({ isMobile, isIpad }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { profile } = useContext(UserContext);

  useEffect(() => {
    if (isMobile || isIpad) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [isMobile, isIpad]);

  return (
    <>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        spacing={isSmallScreen ? 2 : 0}
      >
        <Button
          href={profile ? "/app" : "/"}
          sx={{
            color: "#04a9c8",
            paddingLeft: !profile && !isMobile ? "100px" : "initial",
          }}
          title="Create"
          aria-label="Create"
        >
          <AutoAwesomeIcon
            title="Create"
            sx={{
              fontSize: "3.5rem",
            }}
          />
        </Button>

        {profile !== null && (
          <>
            <Button
              sx={{ color: "#04a9c8" }}
              href="/designs"
              title="Designs"
              aria-label="Design"
            >
              <PhotoLibraryIcon
                title="Design"
                sx={{
                  color: "#04a9c8",
                  fontSize: "3.5rem",
                  marginLeft: isMobile && "-8px",
                }}
                cursor="pointer"
              />
            </Button>
            <Button
              sx={{ color: "#04a9c8" }}
              href="/bookshelf"
              title="Book Shelf"
              aria-label="Book Shelf"
            >
              <MenuBookIcon
                title="Book Shelf"
                sx={{
                  color: "#04a9c8",
                  fontSize: "3.5rem",
                  marginBottom: "4px",
                  marginLeft: isMobile && "-10px",
                }}
                cursor="pointer"
              />
            </Button>
          </>
        )}

        <Button
          title="Prompt Guide"
          sx={{ color: "#04a9c8" }}
          href="/prompt-guide"
          aria-label="Prompt Guide"
          // target="_blank"
        >
          <SpeakerNotesIcon
            title="Prompt Guide"
            sx={{
              color: "#04a9c8",
              fontSize: "3.5rem",
              marginLeft: isMobile && "-10px",
            }}
            cursor="pointer"
          />
        </Button>  
             
        <Button
          title="Video Guides"
          sx={{ color: "#04a9c8" }}
          href="/videos"
          aria-label="Video Guides"
          // target="_blank"
        >
          <OndemandVideoIcon
            title="Video Guides"
            sx={{
              color: "#04a9c8",
              fontSize: "3.5rem",
              marginLeft: isMobile && "-10px",
            }}
            cursor="pointer"
          />
        </Button> 
      </Stack>
    </>
  );
}
