import React from "react";
import { Box, Slider, Typography } from "@mui/material";

export default function PhotoConvertSettings({ settings, handleSettingsChange, isLoading }) {
    return (
        <>
        
        <Box sx={{ width: 250, margin: "auto"}}>
        <Typography gutterBottom>Edge Strength</Typography>
        <Slider
          name="edge_thickness"
          value={settings?.edge_thickness}
          sx={{color: "#13b5cf", cursor: isLoading ?  "not-allowed" : "cursor" }}
          min={1}
          max={8}
          marks={[
            { value: 1, label: "Thin" },
            { value: 8, label: "Thick" },
          ]}
          onChangeCommitted={(event, value) =>
            handleSettingsChange("edge_thickness", value)
          }
          aria-label="Line Strength"
          valueLabelDisplay="auto"
        />
      </Box>
      <Box sx={{ width: 200, margin: "auto" }}>
        <Typography gutterBottom>Edge Spread</Typography>
        <Slider
          name="iterations"
          value={settings?.iterations}
          sx={{color: "#13b5cf", cursor: isLoading ?  "not-allowed" : "cursor" }}
          min={1}
          max={5}
          marks={[
            { value: 1, label: "Minimal" },
            { value: 5, label: "Extensive" },
          ]}
          onChangeCommitted={(event, value) =>
            handleSettingsChange("iterations", value)
          }
          aria-label="Edge Spread"
          valueLabelDisplay="auto"
        />
      </Box>
      <Box sx={{ width: 200, margin: "auto", cursor: isLoading ?  "not-allowed" : "cursor"  }}>
        <Typography gutterBottom>Subtle Line Detection</Typography>
        <Slider
          name="canny_min"
          value={settings?.canny_min}
          sx={{color: "#13b5cf"}}
          min={50}
          max={150}
          marks={[
            { value: 50, label: "Low" },
            { value: 100, label: "Medium" },
            { value: 150, label: "High" },
          ]}
          step={10}
          onChangeCommitted={(event, value) =>
            handleSettingsChange("canny_min", value)
          }
          aria-label="Subtle Line Detection"
          valueLabelDisplay="auto"
        />
      </Box>
      <Box sx={{ width: 200, margin: "auto" }}>
        <Typography gutterBottom>Major Edge Detection</Typography>
        <Slider
          name="canny_max"
          value={settings?.canny_max}
          sx={{color: "#13b5cf", cursor: isLoading ?  "not-allowed" : "cursor" }}
          min={150}
          max={200}
          marks={[
            { value: 150, label: "Subtle" },
            { value: 200, label: "Pronounced" },
          ]}
          step={5}
          onChangeCommitted={(event, value) =>
            handleSettingsChange("canny_max", value)
          }
          aria-label="Major Edge Detection"
          valueLabelDisplay="auto"
        />
      </Box>
      </>
    );
    }