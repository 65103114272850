import React, { useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Box, Button, Divider, Typography } from "@mui/material";
import PricingTable from "./PricingTable";

import useMediaQuery from "@mui/material/useMediaQuery";

// Ensure to set the app element for accessibility reasons
Modal.setAppElement("#root");

function DiscountCodeOffer({
  showDiscountCode,
  handleShowDiscountCode,
  profile,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const callHasSeenOffer = () => {    
    axios.post(
      `${process.env.REACT_APP_API_BE}/api/user/showDiscountCode/${profile?.email}/`
    ).then((response) => {
      console.log("callHasSeenOffer", response.data);
    }
    ).catch((error) => {
      console.error("Error fetching discount code status:", error);
    });
  };

  useEffect(() => {
    callHasSeenOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ display: "block", margin: "auto", width: isMobile ? "90%" : "50%" }}
    >
      <Modal
        isOpen={showDiscountCode}
        onRequestClose={() => handleShowDiscountCode}
        contentLabel="Feedback Form"
      >
        <Typography variant="h5">
          🚀 Flash Deal 20% off any Subscription 🚀
          <br /> But this won't last...          
        </Typography>
        <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
        <Typography variant="h5">
          Use code <strong>MERCH20</strong> at checkout
        </Typography>
        <Typography variant="body2">Applicable for up to 12 months</Typography>
        <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />

        {/* <Typography variant="h4">
          <strong>Limited time offer</strong> */}
             {/* - grab it for <span style={{textDecoration:"underline"}} >only $13.30 per month</span> while you can! </strong> */}
        {/* </Typography> */}
        {/* <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} /> */}
        <PricingTable />
        <Button
          variant="contained"
          onClick={handleShowDiscountCode}
          sx={{
            fontSize: "1.5rem",
            borderRadius: 5,
            marginTop: "1em",
            marginBottom: "1em",
            marginRight: "1%",
            backgroundColor: "#ff6f00",
            "&:hover": {
              color: "#13b5cf",
              backgroundColor: "#ffffff",
            },
          }}
        >
          Close
        </Button>
      </Modal>
    </Box>
  );
}

export default DiscountCodeOffer;
