import React, { useEffect, useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import NewProjectForm from "../../components/projects/NewProjectForm";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function ProjectContainer({
  projectCollection,
  handleGetProjectCollection,
  setProjectCollection,
  handleSelectProject,
  refreshProjects,
  // handleShowDocExportDialog,
  // minimizeProjects,  
  // handleMinimizeProjects,
}) {
  const [expandProjects, setExpandProjects] = useState(false);
  const { profile, APIToken } = useContext(UserContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isIpad = useMediaQuery("(max-width:1200px");

  const handleExpandProjects = () => {
    setExpandProjects(!expandProjects);
  };

  
  useEffect(() => {
    if (isMobile) {
      setExpandProjects(false);
    } else {
      setExpandProjects(true);
    }
  }, [isMobile]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  async function handleDeleteProject(project) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/project`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${APIToken}`,
        },
        body: JSON.stringify({
          project_id: project?.id,
          email: profile?.email,
        }),
      }
    );

    if (response.status === 200) {
      await response.json();
      // handleGetProjectCollection();
      // update project collection to remove deleted project
      const updatedProjectCollection = projectCollection.filter(
        (proj) => proj.id !== project.id
      );      
      setProjectCollection(updatedProjectCollection);
      
      handleSelectProject(projectCollection[0]);
    }
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  useEffect(() => {
    if (profile?.email !== undefined) {
      handleGetProjectCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.email, refreshProjects]);

  return (
    <>     
        <Box sx={{ marginRight: ".5rem", marginLeft: ".5rem" }}>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              padding: ".25em",              
            }}
          >
            <Typography
              variant={isIpad ? "h5" : "h4"}
              component="div"
              sx={{
                flexGrow: 1,
                paddingLeft: ".1em",
                flexShrink: 0,
                minWidth: "fit-content",
              }}
            >
              Projects
            </Typography>
            <Box
              sx={{ display: "flex", flexShrink: 0, minWidth: "fit-content" }}
            >
             
              <NewProjectForm
                handleGetProjectCollection={handleGetProjectCollection}
              />
               <IconButton onClick={handleExpandProjects}>
                {expandProjects ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ marginBottom: ".5em" }} />

          {expandProjects && (
            <Box sx={{ flexGrow: 1, width: "auto", marginTop: ".5em" }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0.5}
                sx={{minWidth: 24}}
              >
                {projectCollection.map((project) => (
                  <Item
                    key={project.id}
                    sx={{ width: "100%", cursor: "pointer" }}
                    onClick={() => handleSelectProject(project)}
                  >
                    {`${truncateString(project?.name, 24)} (${
                      project?.images?.length || 0
                    })`}
                    {project?.name !== "All" && (
                      <>
                        <Tooltip title="Delete Project" placement="top">
                          <DeleteIcon
                            sx={{ display: "block", float: "right" }}
                            onClick={() => handleDeleteProject(project)}
                          />
                        </Tooltip>

                        {/* <Tooltip title="Export to Word Doc" placement="top">
                          <FileOpenIcon
                            sx={{ display: "block", float: "right" }}
                            onClick={() => handleShowDocExportDialog(project)}
                          />
                        </Tooltip> */}
                      </>
                    )}
                  </Item>
                ))}
              </Stack>
            </Box>
          )}
        </Box>
      {/* )} */}
    </>
  );
}
