import React from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import ImageGrid from "../../components/designs/ImageGrid";
import ImageListCollection from "../../components/designs/ImageListCollection";
import UnlockBoxDialog from "./UnlockBoxDialog";
import { useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import axios from "axios";
import AdminNav from "../../components/navigation/AdminNav";
import Header from "../../components/Header";

const DesignBrowser = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [openUnlockBox, setOpenUnlockBox] = useState(false);
  const [imageCollection, setImageCollection] = useState([]);
  const [userList, setUserList] = useState([]);
  const [mostRecentlyActiveUsers, setMostRecentlyActiveUsers] = useState([]);
  const [highActivityUsers, setHighActivityUsers] = useState([]);
  const [featureUsage, setFeatureUsage] = useState([]);
  const [appLastLogin, setAppLastLogin] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [refreshProjects, setRefreshProjects] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState({
    email: "merchdesignai@gmail.com",
  });

  const [selectedTable, setSelectedTable] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { APIToken, logoutUser, profile } = useContext(UserContext);

  const isAdmin = true;

  const handleSelectedTable = () => {
    if (selectedTable === "Most Recently Active Users") {
      setSelectedTable("Highest Usage");
    } else {
      setSelectedTable("Most Recently Active Users");
    }
  };

  const handleRefreshProjects = () => {
    setRefreshProjects(!refreshProjects);
  };

  useEffect(
    () => {
      async function handleGetAllImages(page = 1) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BE}/api/images?email=${selectedRow}&page=${page}&shouldLog=${isAdmin}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.data;
          if (data === null || data?.results?.length === 0) {
            setImageCollection([]);
          } else {
            setImageCollection(data?.results);
            // setImageCount(data?.count);
          }
        } else if (response.status === 401) {
          console.error("Unauthorized, please log in first");
          logoutUser();
        }
      }
      if (selectedRow !== null) {
        handleGetAllImages();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  const handleRowClick = (row) => {
    setSelectedRow(selectedRow === row ? null : row);
  };

  function handleDeleteUser() {
    // call api/user/delete/:email to delete the user
    fetch(
      `${process.env.REACT_APP_API_BE}/api/user/delete/${selectedProfile?.email}/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("User deleted: ", data);
        // remove user from userlist
        setUserList(
          userList.filter((user) => user.email !== selectedProfile?.email)
        );
        setSelectedProfile({ email: userList[0]?.email });
      });
    // console.log("reminder, this is dangerous, add a validation")
  }

  useEffect(() => {
    // fetch list of users from api and set it to account
    const fetchUsers = async () => {
      console.log(`fetching users, ${APIToken}`);
      fetch(`${process.env.REACT_APP_API_BE}/api/admin/users`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUserList(data);
          setSelectedProfile({ ...selectedProfile, email: data[0]?.email });
          // get 10 active users based on last_activity_timestamp with latest first
          // setActiveUsers(data.sort((a, b) => b.last_activity_timestamp - a.last_activity_timestamp).slice(0, 10));
          setMostRecentlyActiveUsers(
            data
              .sort(
                (a, b) =>
                  new Date(b.last_activity_timestamp) -
                  new Date(a.last_activity_timestamp)
              )
              .slice(0, 10)
          );
          setHighActivityUsers(
            data
              .sort((a, b) => b.all_time_image_count - a.all_time_image_count)
              .slice(0, 10)
          );
        });
    };
    if (APIToken) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [APIToken]);

  useEffect(() => {
    const fetchUsageForUser = async () => {
      if (selectedProfile?.email === "") return;
      // fetch list of feature usage from api and set it to featureUsage
      fetch(
        `${process.env.REACT_APP_API_BE}/api/usage/${selectedProfile?.email}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFeatureUsage(data?.feature_usage);
          setSubscriptionStatus(data?.subscription_status);
        })
        .catch((error) => {
          console.error("Error fetching feature usage: ", error);
        });
    };
    if (APIToken) {
      fetchUsageForUser();
    }
  }, [APIToken, selectedProfile]);

  useEffect(() => {
    if (selectedProfile?.email === "") return;
    // fetch user profile from api and set the last login
    fetch(`${process.env.REACT_APP_API_BE}/api/user/${selectedProfile?.email}/`)
      .then((response) => response.json())
      .then((data) => {
        setAppLastLogin(data?.last_login);
      });
  }, [selectedProfile]);

  const handleProfileChange = (event) => {
    setSelectedProfile({ ...selectedProfile, email: event.target.value });
  };

  const renderTable = (users, title) => (
    <Box sx={{ overflowX: "auto", marginBottom: 4 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {title}
      </Typography>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              {!isMobile && (
                <>
                  <TableCell align="right">Daily</TableCell>
                  <TableCell align="right">All Time</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <React.Fragment key={user.email}>
                <TableRow onClick={() => handleRowClick(user.email)}>
                  <TableCell>{user.email}</TableCell>
                  {!isMobile && (
                    <>
                      <TableCell align="right">
                        {user.daily_image_count}
                      </TableCell>
                      <TableCell align="right">
                        {user.all_time_image_count}
                      </TableCell>
                    </>
                  )}
                </TableRow>
                {selectedRow === user.email && (
                  <TableRow>
                    <TableCell colSpan={isMobile ? 1 : 3}>
                      <ImageListCollection imageCollection={imageCollection} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );

  return (
    <>
      <Header title="Design Browser" />
      <Box sx={{ margin: { xs: "1em", sm: "2em", md: "4em" } }}>
        <Typography variant="h4" sx={{ marginBottom: 3 }}>
          Design Browser
        </Typography>

        <AdminNav profile={profile} />

        <Box sx={{ marginTop: "2em", marginBottom: "1em" }}>
          <Button variant="outlined" onClick={handleSelectedTable}>
            {selectedTable === "Highest Usage"
              ? "Most Recently Active Users"
              : selectedTable === "" ? "Most Recently Active Users" : "Highest Usage"}
          </Button>
        </Box>
        {selectedTable === "Most Recently Active Users" &&
          renderTable(mostRecentlyActiveUsers, "Most Recently Active Users")}
        {selectedTable === "Highest Usage" &&
          renderTable(highActivityUsers, "Highest Usage")}

        <Box sx={{ maxWidth: "100%", marginBottom: 4 }}>
          <FormControl fullWidth>
            <InputLabel id="user-select-label">Account</InputLabel>
            <Select
              labelId="user-select-label"
              value={selectedProfile?.email || ""}
              label="User Account"
              onChange={handleProfileChange}
            >
              {userList &&
                userList.map((user) => (
                  <MenuItem key={user.id} value={user.email}>
                    {user.email} ({user.daily_image_count}:
                    {user.all_time_image_count})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        {selectedProfile && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h6">User Details</Typography>
            <Typography>
              Subscription:{" "}
              {subscriptionStatus?.subscription_active ? "Active" : "Inactive"}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              Feature Usage
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 2,
              }}
            >
              <Typography>App Usage: {featureUsage?.app_usage}</Typography>
              <Typography>
                BaseballCard: {featureUsage?.baseball_card_usage}
              </Typography>
              <Typography>
                Design Explorer: {featureUsage?.design_explorer_usage}
              </Typography>
              <Typography>
                View Image: {featureUsage?.view_image_usage}
              </Typography>
              <Typography>
                View Prompt: {featureUsage?.view_prompt_usage}
              </Typography>
              <Typography>Upscale: {featureUsage?.upscale_usage}</Typography>
              <Typography>
                Generate Captions: {featureUsage?.generate_captions_usage}
              </Typography>
              <Typography>
                Generate SEO: {featureUsage?.generate_descriptions_usage}
              </Typography>
              <Typography>
                Generate KDP PDF: {featureUsage?.docgen_usage}
              </Typography>
              <Typography>
                Remove Background: {featureUsage?.remove_background_usage}
              </Typography>
            </Box>
            <Typography sx={{ marginTop: 2 }}>
              Last Login: {appLastLogin}
            </Typography>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenUnlockBox(true)}
              sx={{ marginTop: 2 }}
            >
              Delete User
            </Button>
          </Box>
        )}

        <ImageGrid
          profile={selectedProfile}
          selectedProject={{ name: "All" }}
          projectList={[]}
          handleRefreshProjects={handleRefreshProjects}
          multiSelectProjectId={null}
          multiSelectProjectName={""}
          isAdmin={true}
        />

        <UnlockBoxDialog
          open={openUnlockBox}
          setOpen={setOpenUnlockBox}
          secret={selectedProfile}
          handleDeleteUser={handleDeleteUser}
        />
      </Box>
    </>
  );
};

export default DesignBrowser;
