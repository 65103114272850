import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import Header from "../components/Header";
import { styled } from "@mui/material/styles";
// import axios from "axios";

export default function Debug() {
  const [localProfile, setLocalProfile] = useState({});

  useEffect(() => {
    async function handleGetDebug() {
      const lsp = localStorage.getItem("userProfile")
      setLocalProfile(JSON.parse(lsp))
      }
  
    handleGetDebug();
  }, []);




  
  const Item = styled(Paper)(({ theme }) => ({
    padding: "1rem",
    textAlign: "center",
    color: "black",
  }));

  return (
    <>
      <Header isLandingPage={false} waitingForResponse={false} />

      <Box sx={{ width: "60%", margin: "auto" }}>
        <Typography variant="h5">Debug</Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6}>
            <Item>
              {localProfile}
            </Item>
          </Grid>
          
        </Grid>

      </Box>

      <Box sx={{ marginBottom: "2em" }}></Box>
    </>
  );
}
