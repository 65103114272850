import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";

import {
  coloring_book_animation_styles,
  coloring_book_art_styles,
  coloring_book_themes,  
} from "../../options/visual-design-options";

function ColoringBookTunerOptions({ coloringBookAnimation, coloringBookArt, coloringBookTheme, handleColoringBookAnimationChange, handleColoringBookArtChange, handleColoringBookThemeChange}) {
  return (
    <>
     
      <FormControl>
        <FormLabel
          id="animation-style-radio-buttons-group-label"
          sx={{ fontWeight: "800", textDecoration: "underline" }}
        >
          ANIMATION STYLE
        </FormLabel>
        <RadioGroup
          aria-labelledby="animation-style-radio-buttons-group-label"
          name="animation-style-radio-buttons-group"
        >
          {coloring_book_animation_styles.map((style, index) => (
            <FormControlLabel
              key={index}
              value={style}
              control={<Radio onChange={handleColoringBookAnimationChange} />}
              label={style}
              checked={style === coloringBookAnimation}
            />
          ))}
        </RadioGroup>
      </FormControl>


      <FormControl>
        <FormLabel
          id="visual-elements-radio-buttons-group-label"
          sx={{ fontWeight: "800", textDecoration: "underline" }}
        >
          VISUAL ELEMENTS
        </FormLabel>
        <RadioGroup
          aria-labelledby="visual-elements-radio-buttons-group-label"
          name="visual-elements-radio-buttons-group"
        >
          {coloring_book_art_styles.map((style, index) => (
            <FormControlLabel
              key={index}
              value={style}
              control={<Radio onChange={handleColoringBookArtChange} />}
              label={style}
              checked={style === coloringBookArt}
            />
          ))}
        </RadioGroup>
      </FormControl>


      <FormControl>
        <FormLabel
          id="detail-level-radio-buttons-group-label"
          sx={{ fontWeight: "800", textDecoration: "underline" }}
        >
          DETAIL LEVEL
        </FormLabel>
        <RadioGroup
          aria-labelledby="detail-level-radio-buttons-group"
          name="detail-level-radio-buttons-group"
        >
          {coloring_book_themes.map((theme, index) => (
            <FormControlLabel
              key={index}
              value={theme}
              control={<Radio onChange={handleColoringBookThemeChange} />}
              label={theme}
              checked={theme === coloringBookTheme}
            />
          ))}
        </RadioGroup>
      </FormControl>



    </>
  );
}

export default ColoringBookTunerOptions;
