import React, { useState, useEffect } from "react";
import { List, ListItem, Dialog, DialogContent } from "@mui/material";
import JustButtons from "./JustButtons";

export default function MobileListMenu(profile, handleShowMobileMenu) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (handleShowMobileMenu) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [handleShowMobileMenu]);
  return (    
    <Dialog open={open} onClose={handleClose} sx={{ height: "70%" }}>
      <DialogContent>
        <List dense={true}>
          <ListItem>
            <JustButtons isMobile={true} isIpad={true} />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>    
  );
}
