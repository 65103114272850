import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function InfoHelper({ merch }) {
  const tipGuide = {
    tshirt:
      "Design t-shirt art, stickers or wall art for Amazon Merch and other Print on Demand (POD) platforms.",
    sticker: "Image not perfect? Extra elements around subject? Try removing the background (available in design explorer).",
  };

  return (
    <Tooltip title={tipGuide[merch]}>
      <InfoIcon
        style={{
          display: "inline",
          marginLeft: ".5em",
          verticalAlign: "middle",
          color: "#13b5cf",
        }}
      />
    </Tooltip>
  );
}
export default InfoHelper;
