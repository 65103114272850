import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import NavButtons from "../components/navigation/NavButtons";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function PromptGuide() {
  const [open, setOpen] = React.useState(true);
  const [openSection, setOpenSection] = React.useState("");

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <NavButtons showTagLine={false} />
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
        <Box flexBasis={{ xs: "100%", md: "15%" }} flexShrink={0} p={2}>
          <Paper
            elevation={3}
            sx={{
              margin: "auto",
              padding: "2em",
              textAlign: "left",
              width: "100%",
            }}
          >
            <List
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="prompt-guide-index" onClick={() => setOpenSection("")}>
                  Prompt Guide
                </ListSubheader>
              }
            >
              <ListItemButton>
                <ListItemText
                  className="plausible-event-name=PromptGuide-GettingStarted"
                  primary="Getting Started"
                  onClick={() => setOpenSection("Getting Started")}
                />
              </ListItemButton>
              <ListItemButton>
                <ListItemText
                  className="plausible-event-name=PromptGuide-CraftPromptSubject"
                  primary="Crafting a Prompt Subject"
                  onClick={() => setOpenSection("Crafting a Prompt Subject")}
                />
              </ListItemButton>
              <ListItemButton>
                <ListItemText
                  className="plausible-event-name=PromptGuide-TuningSelectors"
                  primary="Tuning Selectors"
                  onClick={() => setOpenSection("Tuning Selectors")}
                />
              </ListItemButton>
              <ListItemButton onClick={handleClick}>
                <ListItemText primary="Examples" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText
                      className="plausible-event-name=PromptGuide-ExamplesColoringAnimation"
                      primary="Coloring Page Animation Styles"
                      onClick={() =>
                        setOpenSection("Coloring Page Animation Styles")
                      }
                    />
                  </ListItemButton>
                  {/* <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary="Stickers" />
                  </ListItemButton> */}
                </List>
              </Collapse>
            </List>
          </Paper>
        </Box>
        <Box flexBasis={{ xs: "100%", md: "85%" }} flexShrink={0} p={2}>
          <Paper
            elevation={3}
            sx={{
              margin: "auto",
              padding: "2em",
              textAlign: "left",
              width: { xs: "100%", sm: "90%", md: "80%", lg: "70%" },
            }}
          >
            {openSection === "" && (
              <>
            <Typography variant="h2" gutterBottom>
  Prompt Guide
</Typography>
<Typography variant="h6" gutterBottom sx={{ color: 'gray', fontStyle: 'italic' }}>
  Your guide to maximizing the potential of MerchDesign AI
</Typography>
<Typography variant="body1" gutterBottom>
  Welcome to the MerchDesign AI Prompt Guide. Here, you'll find all the information you need to create effective prompts that yield stunning designs. Whether you're new to MerchDesign AI or looking to refine your skills, this guide will help you get the most out of our powerful design tool. From crafting clear and concise prompts to utilizing tuning selectors for enhanced customization, we've got you covered.
</Typography>
<Typography variant="body1" gutterBottom>
  Follow the tips and examples provided to transform your creative ideas into visually striking designs. Let's dive in and explore how to harness the full potential of MerchDesign AI to bring your concepts to life.
</Typography>

                </>
            )}

            {openSection === "Getting Started" && (
              <>
                <br />
                <Typography variant="h4" gutterBottom>
                  Getting Started
                </Typography>
                <Typography variant="body1" gutterBottom>
                  MerchDesign AI is designed to accept simple prompts, which can
                  be refined using pre-defined selectors for mood, art styles,
                  and detail level. When you choose one of the four available
                  types—'Sticker', 'Notebook Cover', 'T-Shirt Art', or 'Coloring
                  Page'—we've done all the hard work for you. Each type features
                  carefully crafted prompts to generate images that are perfect
                  for your selected use case.
                </Typography>

                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: "600", marginTop: 2 }}
                >
                  Available Types:
                </Typography>

                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ fontStyle: "italic" }}
                  >
                    Sticker
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Carefully crafted prompts to generate vibrant and engaging
                    sticker designs.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ fontStyle: "italic" }}
                  >
                    Notebook Cover
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Designed prompts to create visually appealing and thematic
                    notebook covers.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ fontStyle: "italic" }}
                  >
                    T-Shirt Art
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Prompts aimed at generating unique and eye-catching t-shirt
                    artwork.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ fontStyle: "italic" }}
                  >
                    Coloring Page
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Detailed and creative prompts to produce beautiful and
                    engaging coloring pages.
                  </Typography>
                </Box>
              </>
            )}

            {openSection === "Crafting a Prompt Subject" && (
             <>
             <br />
             <Typography variant="h4" gutterBottom>
               Crafting a Prompt Subject
             </Typography>
             <Typography variant="body1" gutterBottom>
               There is no need to create complex prompts with MerchDesign AI. Creating a compelling subject is the first step in generating stunning designs. Start by providing a simple, clear prompt that encapsulates the main idea of your design.
             </Typography>
           
             <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "600" }}>
               Good Prompts:
             </Typography>
           
             <Box sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: "8px", marginBottom: 2 }}>
               <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic" }}>
                 "Bengal cat drinking tea"
               </Typography>
               <Typography variant="body1" gutterBottom>
                 Why it's good: This prompt is simple and specific, allowing MerchDesign AI to focus on creating a clear and cohesive image of a Bengal cat drinking tea.
               </Typography>
             </Box>
           
             <Box sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: "8px", marginBottom: 2 }}>
               <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic" }}>
                 "Robot playing guitar"
               </Typography>
               <Typography variant="body1" gutterBottom>
                 Why it's good: This prompt provides a clear subject (robot) and action (playing guitar), making it easy for the AI to generate a relevant and engaging image.
               </Typography>
             </Box>
           
             <Box sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: "8px", marginBottom: 2 }}>
               <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic" }}>
                 "Fairy in a garden"
               </Typography>
               <Typography variant="body1" gutterBottom>
                 Why it's good: The prompt is concise and evocative, giving a straightforward subject and setting for the AI to work with.
               </Typography>
             </Box>
           
             <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "600", marginTop: 2 }}>
               Bad Prompts:
             </Typography>
           
             <Box sx={{ padding: 2, backgroundColor: "#fbe9e9", borderRadius: "8px", marginBottom: 2 }}>
               <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic" }}>
                 "Design a detailed mandala coloring page with a graceful sea turtle at its core, its shell covered in geometric designs and patterns extending from the center. Ensure the image uses crisp black lines on a white background with no shading. 6.25 inch by 6.25 inch."
               </Typography>
               <Typography variant="body1" gutterBottom>
                 Why it's bad: This prompt is overly detailed and includes instructions that should be handled by the tuning settings, such as specifying geometric designs and the coloring page's style.
               </Typography>
             </Box>
           
             <Box sx={{ padding: 2, backgroundColor: "#fbe9e9", borderRadius: "8px", marginBottom: 2 }}>
               <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic" }}>
                 "Create a notebook cover with an inspiring quote about life, using a sunset background and silhouettes of birds flying."
               </Typography>
               <Typography variant="body1" gutterBottom>
                 Why it's bad: This prompt is too specific about design elements like the quote and background, which should be managed through selectors for mood and style.
               </Typography>
             </Box>
           
             <Box sx={{ padding: 2, backgroundColor: "#fbe9e9", borderRadius: "8px", marginBottom: 2 }}>
               <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic" }}>
                 "Make a T-shirt design with a humorous slogan in bold letters and a cartoon dog holding a balloon. Use bright colors and make sure the text stands out."
               </Typography>
               <Typography variant="body1" gutterBottom>
                 Why it's bad: This prompt includes too many specific design instructions, such as the use of bright colors and text emphasis, which should be adjusted through the AI’s settings.
               </Typography>
             </Box>
           
             <Typography variant="body1" gutterBottom>
               Explanation: Bad prompts often contain too much detail or specific design instructions that should be left to the AI's pre-defined settings for each merch type. Good prompts are concise and provide a clear subject, allowing the AI to utilize its tuning options to generate the best possible design.
             </Typography>
           </>
           
            )}

            {openSection === "Tuning Selectors" && (
              <>
              <Typography variant="h4" gutterBottom>
  Tuning Selectors
</Typography>
<Typography variant="body1" gutterBottom>
  Tuning selectors are available by clicking the Tune button available in the subject input.
</Typography>
<Box sx={{ textAlign: 'center', marginBottom: 2 }}>
  <img
    src="/prompt-guide/tunerbutton.webp"
    alt="Tune Button"
    style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '16px' }}
  />
  <Typography variant="body1" gutterBottom>
    Available tuning options depend on the merch type selected.
  </Typography>
  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: '600', marginTop: 2 }}>
    Stickers & T-Shirts
  </Typography>
  <img
    src="/prompt-guide/sticker-and-tshirt-selectors.webp"
    alt="Sticker and T-Shirt Selectors"
    style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '16px' }}
  />
  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: '600', marginTop: 2 }}>
    Notebook Cover
  </Typography>
  <img
    src="/prompt-guide/notebook-selectors.webp"
    alt="Notebook Cover Selectors"
    style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '16px' }}
  />
  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: '600', marginTop: 2 }}>
    Coloring Pages
  </Typography>
  <img
    src="/prompt-guide/coloring-selectors.webp"
    alt="Coloring Page Selectors"
    style={{ maxWidth: '100%', borderRadius: '8px' }}
  />
</Box>

              </>
            )}

            {openSection === "Prompt Examples" && (
              <Typography variant="h4">Prompt Examples</Typography>
            )}

            {openSection === "Coloring Page Animation Styles" && (
             <>
             <Typography variant="h4" gutterBottom>
               Selecting Animation Styles
             </Typography>
             <Typography variant="body1" gutterBottom>
               In this example, we've used the 'Animation Style' tuner options to demonstrate how selecting 'Cartoon', 'Chibi', and 'Anime' for the same prompt and level of detail will change the look of the final design.
             </Typography>
             <Typography variant="body1" gutterBottom>
               We've kept the default detail level of 'More Detail' and varied the animation style. The prompt is the same in each example:{" "}
               <span style={{ fontWeight: "bold" }}>
                 "cute chihuahua wearing headphones"
               </span>.
             </Typography>
           
             <ImageList cols={3} gap={8} variant="masonry">
               {coloringDataStyles.map((item) => (
                 <ImageListItem key={item.img}>
                   <img
                     srcSet={`${item.img}?w=512&fit=crop&auto=format&dpr=2 2x`}
                     src={`${item.img}?w=512&fit=crop&auto=format`}
                     alt={item.description}
                     loading="lazy"
                     style={{ borderRadius: '8px' }}
                   />
                   <ImageListItemBar
                     subtitle={
                       <Typography
                         sx={{
                           whiteSpace: "normal",
                           wordWrap: "break-word",
                         }}
                         variant="body1"
                       >
                         {item.description}
                       </Typography>
                     }
                     title={
                       <Box sx={{ width: "100%" }}>
                         <Typography
                           noWrap={false}
                           variant="subtitle1"
                           sx={{
                             whiteSpace: "normal",
                             wordWrap: "break-word",
                             fontWeight: 'bold'
                           }}
                         >
                           {item.animationStyle} {item.detailLevel}
                         </Typography>
                       </Box>
                     }
                     position="below"
                   />
                 </ImageListItem>
               ))}
             </ImageList>
           </>
           
            )}
          </Paper>
        </Box>
      </Box>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}


const coloringDataStyles = [
  {
    img: "/prompt-guide/cute-chihuahua-headphones-default.webp",
    description:
      "'Cartoon' style typically has less detail and is more exaggerated, examples include 'Looney Tunes', 'SpongeBob SquarePants'",
    animationStyle: (
      <Chip
        label="Cartoon"
        size="small"
        sx={{
          margin: "0.25em",
          backgroundColor: "#0cb5ce",
          color: "white",
        }}
      />
    ),
    detailLevel: (
      <Chip
        label="More Detail"
        size="small"
        sx={{ margin: "0.25em", backgroundColor: "purple", color: "white" }}
      />
    ),
  },
  {
    img: "/prompt-guide/cute-chihuahua-headphones-chibi-moredetail.webp",
    description:
      "'Chibi' style is a Japanese term for a style of drawing characters where they are small, with big heads and eyes. Examples include 'Hello Kitty', 'Pokemon'",
    animationStyle: (
      <Chip
        label="Chibi"
        size="small"
        sx={{
          margin: "0.25em",
          backgroundColor: "#0cb5ce",
          color: "white",
        }}
      />
    ),
    detailLevel: (
      <Chip
        label="More Detail"
        size="small"
        sx={{ margin: "0.25em", backgroundColor: "purple", color: "white" }}
      />
    ),
  },
  {
    img: "/prompt-guide/cute-chihuahua-headphones-anime.webp",
    description:
      "'Anime' style is a Japanese term for a style of drawing characters that is more realistic and detailed. Examples include 'Naruto', 'Dragon Ball Z'",
    animationStyle: (
      <Chip
        label="Anime"
        size="small"
        sx={{
          margin: "0.25em",
          backgroundColor: "#0cb5ce",
          color: "white",
        }}
      />
    ),
    detailLevel: (
      <Chip
        label="More Detail"
        size="small"
        sx={{ margin: "0.25em", backgroundColor: "purple", color: "white" }}
      />
    ),
  },
];

// const itemData = [
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     title: "Breakfast",
//     author: "@bkristastucchio",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     title: "Coffee",
//     author: "@nolanissac",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//     title: "Hats",
//     author: "@hjrc33",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//     title: "Honey",
//     author: "@arwinneil",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
//     title: "Fern",
//     author: "@katie_wasserman",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
//     title: "Mushrooms",
//     author: "@silverdalex",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
//     title: "Tomato basil",
//     author: "@shelleypauls",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
//     title: "Sea star",
//     author: "@peterlaster",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
//     title: "Bike",
//     author: "@southside_customs",
//   },
// ];
