import React from "react";
import AbcIcon from "@mui/icons-material/Abc";
import IconButton from "@mui/material/IconButton";
// apparently this is no longer used 
// import CaptionPopup from "../CaptionPopup";

export default function CaptionThisButton({ image_id, handleSetOpenCaptions }) {  
  // const [showCaptions, setShowCaptions] = useState(false);

  // const handleShowCaptions = (show) => {
  //   console.log("handleShowCaptions 1: ", show);
  //   setShowCaptions(show);
  // }

  return (
    <>
      <IconButton
       href={`/captionthis/${image_id}`}
        // onClick={() => handleShowCaptions(true)}
        sx={{
          color: "rgba(0, 0, 0)",
        }}
      >
        <AbcIcon sx={{ marginRight: "0.5em" }} />
        Caption This
      </IconButton>
      {/* {showCaptions && (
      <CaptionPopup
        image_id={image_id}        
        showCaptions={showCaptions}
        handleSetOpenCaptions={handleSetOpenCaptions}
        handleShowCaptions={handleShowCaptions}
      />
      )} */}
    </>
  );
}
