import React, { useEffect, useState } from "react";
import PublicAppBar from "../components/navigation/PublicAppBar";
import { Box, Typography } from "@mui/material";

export default function ServiceStatus() {
  const [apiStatus, setApiStatus] = useState("NORMAL");
  const [apiStatusMessage, setApiStatusMessage] = useState("");
  const [fileStorageStatus, setFileStorageStatus] = useState("NORMAL");
  const [fileStorageStatusMessage, setFileStorageStatusMessage] = useState("");
  const [aiServiceStatus, setAiServiceStatus] = useState("NORMAL");
  const [aiServiceStatusMessage, setAiServiceStatusMessage] = useState("");

  // call backend service /api/status
  useEffect(() => {
    const fetchStatus = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/service/status`
      );
      const data = await response.json();
      setApiStatus(data.status["api_status"]);
      setApiStatusMessage(data.status["api_status_message"]);
      setFileStorageStatus(data.status["file_storage_status"]);
      setFileStorageStatusMessage(data.status["file_storage_status_message"]);
      setAiServiceStatus(data.status["ai_status"]);
      setAiServiceStatusMessage(data.status["ai_status_message"]);
    };
    fetchStatus();
  }, []);

  return (
    <Box>
      <PublicAppBar />
      <Typography variant="h2">Service Status</Typography>
      <Box sx={{ marginLeft: "30%", marginRight: "30%", marginTop: "2em" }}>
        <Typography variant="body1" align="left">
          MerchDesign AI is currently operational and available for use. If you
          are experiencing issues with the service, please contact us at support
          at MerchDesign AI dot com.
        </Typography>
      </Box>
      <Box sx={{ marginLeft: "30%", marginRight: "30%", marginTop: "2em" }}>
        <Typography variant="h4">API Status</Typography>
        <Typography variant="body1" align="center">
          {apiStatus}
        </Typography>
        <Typography variant="body2" align="center">
          {apiStatusMessage}
        </Typography>
      </Box>
      <Box sx={{ marginLeft: "30%", marginRight: "30%", marginTop: "2em" }}>
        <Typography variant="h4">File Storage Status</Typography>
        <Typography variant="body1" align="center">
          {fileStorageStatus}
        </Typography>
        <Typography variant="body2" align="center">
          {fileStorageStatusMessage}
        </Typography>
      </Box>
      <Box sx={{ marginLeft: "30%", marginRight: "30%", marginTop: "2em" }}>
        <Typography variant="h4">AI Service Status</Typography>
        <Typography variant="body1" align="center">
          {aiServiceStatus}
        </Typography>
        <Typography variant="body2" align="center">
          {aiServiceStatusMessage}
        </Typography>
      </Box>
    </Box>
  );
}
