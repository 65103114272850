// src/ImageBrowser.js

import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchBox from "./SearchBox";
import FooterNavLinks from "../../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../../components/navigation/CommunityImageLinks";
import { Button, CardActions } from "@mui/material";
// import Header from "../../components/Header";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import UserContext from "../../context/UserContext";
import Alert from "@mui/material/Alert";
import PublicAppBar from "../../components/navigation/PublicAppBar";
import PricingPage from "../PricingPage";
import ImageModifierChips from "../../components/image_display/ImageModifierChips";

const handleDownload = (image) => {
  const link = document.createElement("a");
  link.href = image?.image_url;
  link.download = image?.name;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleReport = async (image, handleSetRefresh) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BE}/api/community/report`,
    {
      method: "POST",
      body: JSON.stringify({ image_id: image.id }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  await response.json();
  handleSetRefresh();
  // alert("Image reported as inappropriate.");
  <Alert severity="error">Image reported.</Alert>;
};

const handleUpVote = async (
  image,
  profile,
  setUpvotes,
  setHasVoted
) => {
  
  const response = await fetch(
    `${process.env.REACT_APP_API_BE}/api/community/upvote`,
    {
      method: "POST",
      body: JSON.stringify({
        image_id: image.id,
        email: profile?.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  await response.json().then((resp) => {
    setUpvotes(resp.upvotes);
    setHasVoted(true);
  });
};

const ImageCard = ({ image, profile, handleSetRefresh }) => {
  const [upvotes, setUpvotes] = useState(image.upvotes);
  const [hasVoted, setHasVoted] = useState(false);
  return (
    <Card>
      <Link to={`/free-coloring-pages/${image.name}.png?imageId=${image.id}`}>
        <CardMedia
          component="img"
          width="512"
          image={
            image?.low_res_image_url
              ? image.low_res_image_url
              : image?.image_url
          }
          alt={image?.name}
        />
      </Link>
      <CardContent>
        <Typography variant="h6">{image.name}</Typography>
        <ImageModifierChips image_modifiers={image.image_modifiers} />       
      </CardContent>
      <CardActions>
        <Button
          className="plausible-event-name=PublicImage-GalleryView-Downloaded"
          size="small"
          color="primary"
          onClick={() => handleDownload(image)}
        >
          Download
        </Button>
        <Button
          className="plausible-event-name=PublicImage-ImageReported"
          size="small"
          color="secondary"
          onClick={() => handleReport(image, handleSetRefresh)}
        >
          Report
        </Button>

        <IconButton
          className="plausible-event-name=PublicImage-Upvoted"
          aria-label="like"
          sx={{ position: "relative", right: "-115px", cursor: hasVoted ? "not-allowed" : "pointer"}}
          onClick={() => {
            if (!hasVoted) {
              handleUpVote(image, profile, setUpvotes, setHasVoted)
            }          
          }}
          disabled={hasVoted}
        >
          {upvotes > 0 ? (
            <Badge
              badgeContent={upvotes}
              color="secondary"
              sx={{
                "& .MuiBadge-badge": {
                  right: -3,
                  top: 13,
                  border: `2px solid ${"white"}`,
                  padding: "0 4px",
                },
              }}
            >
              <ThumbUpIcon />
            </Badge>
          ) : (
            <ThumbUpIcon />
          )}
        </IconButton>
      </CardActions>
    </Card>
  );
};

const GalleryBrowser = ({ category }) => {
  const [images, setImages] = useState([]);
  const [imageType, setImageType] = useState(category);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);  
  const [showPricing, setShowPricing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { profile } = useContext(UserContext);

  const handleSetRefresh = () => {
    setRefresh(!refresh);
  };
  
  useEffect(() => {
    async function fetchImages() {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/gallery/?page=${currentPage}&image_type=${imageType}&search_term=${searchTerm}`
      );
      await response.json().then((resp) => {
        if (!resp.results) {
          return;
        }
        if ((prevPage === currentPage && refresh) || searchTerm) {
          setImages(resp.results);
        } else {
          setImages([...images, ...resp.results]);
        }

        setHasMore(resp?.next !== null);
        setPrevPage(currentPage);
      });
    }
    if (prevPage !== currentPage || refresh || searchTerm) {
      fetchImages();
      setRefresh(false);
    }

    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, refresh, searchTerm]);

  useEffect(() => {
    setImages([]);
    setCurrentPage(1);
    setRefresh(!refresh);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageType]);

  const handleGetNextImages = async () => {
    if (hasMore) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (category === "coloring") {
      setImageType("coloring");
    } else if (category === "stickers") {
      setImageType("stickers");
    } else if (category === "tshirts") {
      setImageType("tshirts");
    }
  }, [category]);

  const handleChange = (event, newValue) => {
    setImageType(
      newValue === 0
        ? "coloring"
        : newValue === 1
        ? "stickers"
        : newValue === 2
        ? "tshirts"
        : "coloring"
    );
  };

  return (
    <>
      <PublicAppBar showPricing={showPricing} setShowPricing={setShowPricing} />
      {showPricing && <PricingPage showHeader={false} />}
      {!showPricing && (
        <Container>          
          <Box textAlign="center" my={4}>
            <Typography variant="h2">Discover Free Images</Typography>
            <Typography variant="subtitle1">
              Browse through images created by our community
            </Typography>
          </Box>
          <SearchBox setSearchTerm={setSearchTerm} />
          <Tabs
            value={
              imageType === "coloring" ? 0 : imageType === "stickers" ? 1 : 2
            }
            onChange={handleChange}
            centered
          >
            <Tab label="Coloring" />
            <Tab label="Stickers" />
            <Tab label="T-Shirts" />
          </Tabs>
          <Grid container spacing={4} mt={2}>
            {images.map((image) => (
              <Grid item key={image.id} xs={12} sm={6} md={4}>
                <ImageCard
                  image={image}
                  profile={profile}
                  handleSetRefresh={handleSetRefresh}                  
                />
              </Grid>
            ))}
          </Grid>
          {hasMore && (
            <Button
              className="plausible-event-name=PublicImage-More"
              variant="contained"
              sx={{
                fontSize: "1.5rem",
                borderRadius: 5,
                marginTop: "1em",
                marginBottom: "1em",
                marginRight: "1%",
                backgroundColor: "#13b5cf",
                "&:hover": {
                  color: "#ffffff",
                  backgroundColor: "#ff6f00",
                },
              }}
              onClick={handleGetNextImages}
            >
              Load More
            </Button>
          )}
          <Divider sx={{ marginTop: "2rem" }} />
          <FooterNavLinks />
          <CommunityImageLinks />
        </Container>
      )}
    </>
  );
};

export default GalleryBrowser;
