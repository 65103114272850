import React from 'react';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import NavButtons from "../../../components/navigation/NavButtons";
import FooterNavLinks from "../../../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../../../components/navigation/CommunityImageLinks";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";


export default function DoNotPromptLikeThis() {
    const title = "Do Not Prompt Like This";
    const subTitle = "Using prompts from other AI generated images can result in unexpected results. Here's how to avoid that."
    const body1 = 
        "MerchDesign AI is finetuned to generate Coloring Pages, Sticker designs, t-shirt designs and notbook covers. Using prompts from other AI platforms as is such as MidJourney will not yield the results you expect. ";
    const body2 = "For best results, simply describe your subject, do not describe a coloring book page or add sizing info like this example. The example on the right attempts to use a typical prompt from other platforms, the one on the left is a simpler prompt and allows MerchDesign AI to work it's magic and generate a better image.";
    // const body3 = "The image was then generated with a simple prompt of just the subject and with the 'Child Drawing' and 'Less Detail' modifiers. Notice how the image is much simpler and more focused on the subject.";
   

  return (
    <>
    <NavButtons showTagLine={false}/>
      <Paper
        elevation={3}
        sx={{
          margin: "auto",
          padding: "2em",
          textAlign: "left",          
          width: { xs: "90%", sm: "80%", md: "70%" },
        }}
      >
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"1em"}}>
        <Typography variant="h2">{title}</Typography>
        </Box>

        
        {/* <VideoCard video_id={"w9gwxt6pDLU"} title={title} steps={steps}/> */}
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", margin:"2em"}}>
        <Typography variant="h5">{subTitle}</Typography>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body1}
        </Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body2}
        </Typography>
        </Box>
        <Box component={"img"} src="/prompts-for-merchdesign.webp" alt="MerchDesign AI generated image prompt recommendation" sx={{width:"100%", maxWidth:"500px"}}/>
        <Link href="https://merchdesign.ai/free-coloring-pages">Image from our community generated free coloring pages repository</Link>
        {/* <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body3}
        </Typography>
        </Box> */}
        {/* <Box component={"img"} src="/smiley-with-modifier.webp" alt="MerchDesign AI generated image with Child Drawing and Less Detail modifiers" sx={{width:"100%", maxWidth:"500px"}}/>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"left", margin:"2em"}}>
        <Typography variant="body1" color="text.secondary">
          {body4}
        </Typography>*/}
        </Box> 
      </Paper>
      <FooterNavLinks />
      <CommunityImageLinks />
    </>
  );
}
