import React, { useEffect, useState, useContext } from "react";
import { useMaintenanceMode } from "../../context/MaintenanceModeContext";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import UserContext from "../../context/UserContext";
import AdminNav from "../../components/navigation/AdminNav";

const MaintenanceModeToggle = () => {
  const [serverMtcMode, setServerMtcMode] = useState(false);
  const { isInMaintenanceMode } = useMaintenanceMode();
  const { APIToken, profile } = useContext(UserContext);

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BE}/api/admin/sysvar/isInMaintenanceMode/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            },
          }
        );
        setServerMtcMode(response.data[0].value);
      } catch (error) {
        console.error("Failed to fetch maintenance mode", error);
      }
    };
    fetchMaintenanceMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callToggleMaintenanceMode = async () => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_API_BE}/api/admin/sysvar/isInMaintenanceMode/`,
          {
            key: "isInMaintenanceMode",
            value: isInMaintenanceMode === "True" ? "False" : "True",
            email: profile?.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            }            
          }
        )
        .then((response) => {
          setServerMtcMode(response.data.value);
        });
    } catch (error) {
      console.error("Failed to toggle maintenance mode", error);
      alert("Failed to toggle maintenance mode.");
    }
  };

  return (
    <Box sx={{ margin: "2em" }}>
      
      <Typography variant="h4">
        Maintenance Mode Status, Server: {serverMtcMode}
      </Typography>
      <AdminNav profile={profile} />
      <Divider sx={{marginTop:"2em", marginBottom:"2em"}}/>
      <Button variant="contained" onClick={callToggleMaintenanceMode}>
        {isInMaintenanceMode === "False"
          ? "Enable Maintenance Mode"
          : "Disable Maintenance Mode"}
      </Button>
      <br />
      <br />
      <Button variant="contained" href="/">
        Home
      </Button>
    </Box>
  );
};
export default MaintenanceModeToggle;
