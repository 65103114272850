import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Divider, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import StyledFontSelector from "./StyledFontSelector";
import FontSizeSelector from "./FontSizeSelector";

const BackPageStyleValues = Object.freeze({
  BLANK: "blank",
  BLACK: "black",
  NONE: "none",
});

const BookSettings = ({
  showBookSettings,
  setShowBookSettings,
  bookConfig,
}) => {
  const { profile, APIToken } = useContext(UserContext);
  const [formatOptions, setFormatOptions] = useState([]);
  const [updatedBook, setUpdatedBook] = useState({
    title: bookConfig.title,
    author: bookConfig.author,
    specification: bookConfig?.specification?.name,
    backPageStyle: bookConfig.back_page_style,
    globalFont: bookConfig.global_font || "Arial",
    globalFontSize: parseInt(bookConfig.global_font_size) || 12,
  });

  useEffect(() => {
    const fetchFormatOptions = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/bookshelf/specifications?email=${profile?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      ).then((response) => response.json());

      setFormatOptions(response);
    };

    if (APIToken && profile?.email && formatOptions.length === 0) {
      fetchFormatOptions();
    }
    // eslint-disable-next-line
  }, [APIToken, profile?.email]);

  useEffect(() => {
    setUpdatedBook({
      title: bookConfig.title,
      author: bookConfig.author,
      specification: bookConfig?.specification?.name,
      backPageStyle: bookConfig.back_page_style,
      globalFont: bookConfig.global_font || "Arial",
      globalFontSize: parseInt(bookConfig.global_font_size) || 12,
    });
  }, [bookConfig]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;    
    setUpdatedBook((prev) => ({ ...prev, [name]: value }));
  };

  function handleSetFont(font){
    setUpdatedBook((prev) => ({ ...prev, globalFont: font }));
  }
  function handleSetFontSize(fontSize){    
    setUpdatedBook((prev) => ({ ...prev, globalFontSize: fontSize }));
  }


  const handleSubmit = (e) => {    
    e.preventDefault();
    const bookId = bookConfig.id;
    fetch(`${process.env.REACT_APP_API_BE}/api/book/${bookId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      body: JSON.stringify({
        email: profile?.email,
        title: updatedBook.title,
        author: updatedBook.author,
        specification: updatedBook.specification,
        back_page_style: updatedBook.backPageStyle,
        global_font: updatedBook.globalFont,
        global_font_size: updatedBook.globalFontSize,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Book updated:", data);
        setShowBookSettings(false);
      })
      .catch((error) => {
        console.error("Error updating book:", error);
      });
  };

  return (
    <Box sx={{ marginLeft: "1em", marginRight: ".5em" }}>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          padding: ".25em",
        }}
      >
        <Typography
          variant={"h5"}
          component="div"
          sx={{
            flexGrow: 1,
            paddingLeft: ".1em",
            flexShrink: 0,
            minWidth: "fit-content",
          }}
        >
          Book Settings
        </Typography>
        <Box sx={{ display: "flex", flexShrink: 0, minWidth: "fit-content" }}>
          <IconButton onClick={() => setShowBookSettings(!showBookSettings)}>
            {showBookSettings ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      </Box>

      <Divider sx={{ marginBottom: ".5em" }} />

      {showBookSettings && (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            length="30"
            margin="normal"
            name="title"
            label="Title"
            value={updatedBook.title}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            length="15"
            margin="normal"
            name="author"
            label="Author"
            value={updatedBook.author}
            onChange={handleInputChange}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="format-label">Format</InputLabel>
            <Select
              labelId="format-label"
              label="Format"
              name="specification"
              value={updatedBook.specification || ""}
              onChange={handleInputChange}
              required
            >
              {formatOptions.map((format) => (
                <MenuItem key={format.id} value={format.name}>
                  {format.name}: {format.description} ({format.trimsize_width}"
                  x {format.trimsize_height}")
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="format-label">Back Page Style</InputLabel>
            <Select
              labelId="backpage-label"
              label="Back Page Style"
              name="backPageStyle"
              value={updatedBook.backPageStyle || ""}
              onChange={handleInputChange}
              required
            >
              {Object.values(BackPageStyleValues).map((style) => (
                <MenuItem key={style} value={style}>
                  {style}
                </MenuItem>
              ))}
            </Select>
          </FormControl>          
          <FormControl fullWidth margin="normal">
            <InputLabel id="global-font-label">Global Font</InputLabel>
            <StyledFontSelector 
              bookConfig={updatedBook}
              font={updatedBook.globalFont}
              handleSetFont={handleSetFont}
             />           
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="global-font-size-label">
              Global Font Size
            </InputLabel>
            <FontSizeSelector 
              bookConfig={bookConfig}
              textFontSize={updatedBook.globalFontSize}
              handleSetTextFontSize={handleSetFontSize}
              />            
          </FormControl>

          <Button
            onClick={() => setShowBookSettings(false)}
            variant="contained"
            sx={{
              mt: 2,
              marginRight: 2,
              borderRadius: 5,
              backgroundColor: "#13b5cf",
              "&:hover": {
                backgroundColor: "#0f9eb5",
              },
              cursor: "pointer",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 2,
              borderRadius: 5,
              backgroundColor: "#13b5cf",
              "&:hover": {
                backgroundColor: "#0f9eb5",
              },
            }}
          >
            Save Book
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BookSettings;
