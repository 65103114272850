import React from "react";
import { Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

// element can be book or page text element
const StyledFontSelector = ({ bookConfig, font, handleSetFont }) => {
  const FontOptions = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Comic Sans MS",
    "Trebuchet MS",
    "Arial Black",
    "Impact",
    "Snell Roundhand",
    "Hand Stencil",
  ];

  const StyledMenuItem = styled(MenuItem)(({ theme, fontFamily }) => ({
    fontFamily: fontFamily,
  }));

  const StyledSelect = styled(Select)(({ theme, fontFamily }) => ({
    "& .MuiSelect-select": {
      fontFamily: fontFamily,
    },
  }));

  
  const handleChange = (e) => {
    const { value } = e.target;
    handleSetFont(value);
  };

  return (
    <StyledSelect
      labelId="global-font-label"
      label="Global Font"
      name="globalFont"
      value={font || bookConfig?.globalFont || ""}
      onChange={handleChange}
      required
      fontFamily={font || bookConfig?.globalFont || ""}
      renderValue={(selected) => (
        <span style={{ fontFamily: selected }}>{selected}</span>
      )}
    >
      {FontOptions.map((font) => (
        <StyledMenuItem key={font} value={font} fontFamily={font}>
          {font}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default StyledFontSelector;
