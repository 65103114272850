import React, { useState } from 'react';

const GenerateSitemap = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  const categoriesToRemove = [
    "null",
    "0 category",
    "adult",
    "alcholic beverage",
    "body",
    "star wars",
    "steam punk",
    "violence",
    "unknown",      
  ];

  const additionalLinks = [
    { url: "/", changefreq: "daily", priority: 1 },
    { url: "/privacy", changefreq: "monthly", priority: 0.8 },
    { url: "/pricing", changefreq: "monthly", priority: 0.8 },
    { url: "/support", changefreq: "daily", priority: 0.9 },
    { url: "/free-coloring-pages", changefreq: "daily", priority: 0.9 },
    { url: "/free-sticker-designs", changefreq: "daily", priority: 0.9 },
    { url: "/free-tshirt-designs", changefreq: "daily", priority: 0.9 },
    { url: "/videos", changefreq: "daily", priority: 0.9 },
    { url: "/guides", changefreq: "daily", priority: 0.9 },
    { url: "/prompt-guide", changefreq: "daily", priority: 0.9 },
  ];

  const generateSitemap = async () => {
    setIsGenerating(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BE}/api/normalized_categories/`);
      const data = await response.json();

      const adjustedCategories = data.filter(
        (category) => category && 
        category.normalized_name && 
        !categoriesToRemove.includes(category.normalized_name.toLowerCase())
      );

      const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${additionalLinks.map(link => `
  <url>
    <loc>https://merchdesign.ai${link.url}</loc>
    <lastmod>${new Date().toISOString().split('T')[0]}</lastmod>
    <changefreq>${link.changefreq}</changefreq>
    <priority>${link.priority}</priority>
  </url>`).join('')}
  ${adjustedCategories.map(category => `
  <url>
    <loc>https://merchdesign.ai/category/${encodeURIComponent(category.normalized_name)}</loc>
    <lastmod>${new Date().toISOString().split('T')[0]}</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.8</priority>
  </url>`).join('')}
</urlset>`;

      // Create a Blob with the XML content
      const blob = new Blob([xmlContent], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'sitemap.xml';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log('Sitemap generated successfully.');
    } catch (error) {
      console.error('Error generating sitemap:', error);
      setError('Failed to generate sitemap. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Sitemap Generator</h2>
      <button 
        onClick={generateSitemap}
        disabled={isGenerating}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {isGenerating ? 'Generating...' : 'Generate Sitemap'}
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default GenerateSitemap;