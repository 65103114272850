import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SnackbarContent from "@mui/material/SnackbarContent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import FooterActionButtons from "./buttons/FooterActionButtons";
import UserContext from "../context/UserContext";
import Snackbar from "@mui/material/Snackbar";
import ImageHighlight from "./ImageHighlight";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import BreadcrumbNav from "./navigation/BreadcrumbNav";

function CaptionThisGen() {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [captions, setCaptions] = useState([]);
  const [openSpinner, setOpenSpinner] = useState(false);
  const [formData, setFormData] = useState({
    caption_1: "",
    caption_2: "",
    caption_3: "",
    caption_4: "",
    caption_5: "",
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  const { profile, APIToken } = useContext(UserContext);
  const { image_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    
    const fetchData = async () => {
      if (!profile?.email) return;

      const encodedEmail = encodeURIComponent(profile.email);
      const url = `${process.env.REACT_APP_API_BE}/api/image?image_id=${image_id}&email=${encodedEmail}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken || localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCurrentItem(data[0]);
        if (profile?.email && APIToken) {
          await handleCheckIfCaptionsExist();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.email, image_id, APIToken]);

  const handleCheckIfCaptionsExist = async () => {
    console.log("Checking if captions exist for image_id: ", image_id);
    setOpenSpinner(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/captions/${image_id}/?email=${profile?.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      }
    );

    // const data = response.status === 200 ? await response.json() : setOpenSpinner(false);;
    if (response.status === 200) {
      const data = await response.json();
      if (data?.length > 0 && data !== undefined) {
        const jsonData = textToJson(data);
        setCaptions(jsonData);
        setFormData({
          caption_1: jsonData[0],
          caption_2: jsonData[1] || "",
          caption_3: jsonData[2] || "",
          caption_4: jsonData[3] || "",
          caption_5: jsonData[4] || "",
        });

        // setOpenSpinner(false);
      }
    }
    setOpenSpinner(false);
  };

  function textToJson(text) {
    const lines = text
      .split("\n")
      .filter((line) => line.trim() !== "")
      .map((line) => line.replace(/^\d+\.\s*['"]|['"]$/g, ""));
    return lines;
  }

  const addAndEdit = (caption) => {
    console.log("addAndEdit: ", caption, image_id);
    // Navigate to /edit/:image_id with caption as a state parameter
    navigate(`/edit/${image_id}`, { state: { caption: caption } });
  };

  const getCaptionSuggestions = async () => {
    setOpenSpinner(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/generate/captions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
        body: JSON.stringify({
          image_id: image_id,
          email: profile?.email,
        }),
      }
    );
    if (!response.ok) {
      console.error("Failed to fetch captions:", response);
      setOpenSpinner(false);
      <Alert severity="error">We experienced an error, please retry</Alert>;
    }
    const data = await response.json();

    const jsonData = textToJson(data.generated_captions);
    setCaptions(jsonData);
    setFormData({
      caption_1: jsonData[0] || "",
      caption_2: jsonData[1] || "",
      caption_3: jsonData[2] || "",
      caption_4: jsonData[3] || "",
      caption_5: jsonData[4] || "",
    });
    setOpenSpinner(false);
  };

  const handleDisplaySnackBar = () => {
    setOpen(true);
  };

  function copyCaptionText(keywords) {
    navigator.clipboard.writeText(keywords);
    handleDisplaySnackBar();
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Header showDesigns={true} />
      <Box
        component="div"
        sx={{ display: "block", width: "fit-content", margin: "auto" }}
      >
        <BreadcrumbNav here="Caption This" prev="Designs" prevprev="Home" />
      </Box>
      <Paper
        elevation={4}
        sx={{
          padding: "1em",
          width: isMobile ? "90%" : "60%",
          margin: "auto",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openSpinner}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography align="center" variant="h2">
          Caption Suggestions
        </Typography>
        <Box sx={{ width: isMobile ? "95%" : "60%", margin: "auto" }}>
          <Typography variant="body1" align="center">
            ** These are suggestions, you are responsible for ensuring they are
            not copyrighted **
          </Typography>
        </Box>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 4, md: 4 }}
        >
          <Grid item xs={4} sm={4} md={4}>
            <Box
              component="div"
              sx={{ width: "100%", marginTop: "1%", marginBottom: "1em" }}
            >
              {formData[0]?.length === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getCaptionSuggestions}
                  target="_blank"
                  sx={{
                    fontSize: "1.5rem",
                    borderRadius: 5,
                    marginTop: "5%",
                    marginBottom: "5%",
                    marginRight: "1%",
                    backgroundColor: "#13b5cf",
                    "&:hover": {
                      color: "#13b5cf",
                      backgroundColor: "#ffffff",
                    },
                  }}
                >
                  {captions.length === 0 ? "Generate" : "Refresh"}
                </Button>
              )}
            </Box>
          </Grid>

          {formData?.caption_1.length > 0 ? (
            <>
              {captions.map((caption, index) => {
                return (
                  <Grid item xs={4} sm={4} md={4} key={index}>
                    <FormHelperText id="outlined-weight-helper-text">
                      Caption {index + 1}
                    </FormHelperText>
                    <Input
                      sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                      id={"caption_" + index}
                      name={"caption_" + index}
                      value={caption}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <AddPhotoAlternateIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => addAndEdit(caption)}
                          />
                          <IconButton
                            onClick={() => copyCaptionText(caption)}
                            aria-label="copy feature caption"
                            title="Copy Feature Caption"
                            edge="end"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <></>
          )}
          <Grid item xs={4} sm={4} md={4}>
            <Box
              component="div"
              sx={{ width: "100%", marginTop: "0em", marginBottom: "0em" }}
            >
              <Grid item xs={4} sm={4} md={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getCaptionSuggestions}
                  target="_blank"
                  sx={{
                    fontSize: "1.5rem",
                    borderRadius: 5,
                    marginTop: "5%",
                    marginBottom: "5%",
                    marginRight: "1%",
                    backgroundColor: "#13b5cf",
                    "&:hover": {
                      color: "#13b5cf",
                      backgroundColor: "#ffffff",
                    },
                  }}
                >
                  {captions.length === 0 ? "Generate" : "Refresh"}
                </Button>
              </Grid>
              <ImageHighlight currentItem={currentItem} />
            </Box>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <SnackbarContent message="Copied!" />
        </Snackbar>
      </Paper>
      <FooterActionButtons />
    </>
  );
}

export default CaptionThisGen;
