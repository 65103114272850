import React, { useState, useEffect, useRef } from "react";
import CanvasMagicWand from "canvas-magic-wand";
import DraggableText from "./DraggableText";
import { Input } from "@mui/material";

export default function EditImage({
  image_id,
  imageVersion,
  tool,
  handleSetTool,
  replaceColor,
  captionThis,
  color,
  penSize,
  textColor,
  fontSize,
  fontFamily,
  undo,
  handleUndo,
  canvasRef,
  printRef,
  draggableTextRef,
  paintText,
  setEnablePaintText,
  handleSetImageDirty,
}) {
  // const [inputText, setInputText] = useState(captionThis ? captionThis : ""); // State to hold the input text
  const [image2Blob, setImage2Blob] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [history, setHistory] = useState([]);
  // const [selectedKnockoutColor, setSelectedKnockoutColor] = useState("#02dbba");
  const [textData, setTextData] = useState({
    x: 50,
    y: 50,
    text: captionThis ? captionThis : "Edit or move me",
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isEditing, setIsEditing] = useState(false);  
  const imageRef = useRef(null);


  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.getContext("2d").lineWidth = penSize;
    canvas.getContext("2d").strokeStyle = color;
    canvas.getContext("2d").lineJoin = "round";
    canvas.getContext("2d").lineCap = "round";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [penSize, color]);

  useEffect(() => {
    const img = imageRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const loadImage = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };

    if (img.complete) {
      // If the image is already loaded, draw it
      loadImage();
    } else {
      // Listen for the load event if the image is not yet loaded
      img.addEventListener("load", loadImage);
      return () => {
        img.removeEventListener("load", loadImage);
      };
    }
    // eslint-disable-next-line
  }, [imgSrc]);

  useEffect(() => {
    // Create an object URL for the blob
    if (image2Blob) {
      const objectUrl = URL.createObjectURL(image2Blob);
      setImgSrc(objectUrl);      
      // Cleanup the URL to avoid memory leaks
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image2Blob]);

  
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BE}/api/serveimage/${image_id}&${imageVersion}`
    )
      .then((response) => response.blob())
      .then((blob) => {
        // const imageUrl = URL.createObjectURL(blob);
        setImage2Blob(blob);
      })
      .catch((error) => console.error("Error loading the image: ", error));
    // eslint-disable-next-line
  }, [imageVersion, undo]);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    setIsDrawing(true);
    setLastPosition({ x: offsetX, y: offsetY });
    // Save current state before drawing
    saveCurrentState();
    handleSetImageDirty(true);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = nativeEvent;
    const context = canvasRef.current.getContext("2d");
    context.globalCompositeOperation =
      tool === "pen" ? "source-over" : "destination-out";

    context.beginPath();
    context.moveTo(lastPosition.x, lastPosition.y);
    context.lineTo(offsetX, offsetY);
    context.stroke();
    setLastPosition({ x: offsetX, y: offsetY });
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const saveCurrentState = () => {
    const canvas = canvasRef.current;
    const imgData = canvas
      .getContext("2d")
      .getImageData(0, 0, canvas.width, canvas.height);
    setHistory((prevHistory) => [...prevHistory, imgData]);
  };

  useEffect(() => {
    const undoLastAction = () => {
      if (history.length === 0) return;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const prevState = history.pop();      
      context.putImageData(prevState, 0, 0);
      setHistory(history.slice(0, -1));
    };
    if (undo) {
      undoLastAction();
      handleUndo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undo, history, handleUndo]);

  function rgbToHex(r, g, b) {
    const toHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  const handleCanvasClick = (event) => {
    const ctx = canvasRef.current.getContext("2d");
    const { offsetX, offsetY } = event.nativeEvent;
    const { data } = ctx.getImageData(offsetX, offsetY, 1, 1);    
    const hex = rgbToHex(data[0], data[1], data[2]);    
    knockoutColor(hex);
    handleSetImageDirty(true);
  };

  const knockoutColor = (hex) => {
    const image2Url = URL.createObjectURL(image2Blob);    
    CanvasMagicWand.knockoutColor({
      src: image2Url,
      targetColor: hex ? hex : color ? color : "#02dbba",
      replacementColor: replaceColor || `RGB(0, 0, 0, 0)`,
      tolerance: 10,
      edgeBlur: 1,
      edgeBrightness: 2,
      edgeContrast: 6,
      debugMode: false,
    }).then((img) => {      
      const processedImageElement = document.getElementById("processedImage");
      processedImageElement.src = img.src;

      // Optional: Revoke the old image URL to free up memory
      URL.revokeObjectURL(image2Url);
    });
  };



  const handleMouseDownText = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    // Simple collision detection    
    if (
      mouseX > textData.x &&
      mouseX < textData.x + 100 &&
      mouseY < textData.y &&
      mouseY > textData.y - 30
    ) {
      setIsDragging(true);
      handleSetImageDirty(true);
      setDragOffset({ x: mouseX - textData.x, y: mouseY - textData.y });
    }
  };

  const handleMouseMoveText = (e) => {    
    if (isDragging) {
      const rect = canvasRef.current.getBoundingClientRect();
      setTextData({
        ...textData,
        x: e.clientX - rect.left - dragOffset.x,
        y: e.clientY - rect.top - dragOffset.y,
      });
    }
  };

  useEffect(() => {
    const paintTextToCanvas = (textData) => {
      if (draggableTextRef.current === null) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const canvasRect = canvasRef.current.getBoundingClientRect();
      const textRect = draggableTextRef.current.getBoundingClientRect();

      // Calculate positions relative to the canvas
      const left = textRect.left - canvasRect.left + textRect.width / 2;
      const top = textRect.top - canvasRect.top + textRect.height / 2;
      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillStyle = textColor;      
      ctx.textAlign = "center"; // Align text center horizontally
      ctx.textBaseline = "middle"; // Align text center vertically
      ctx.fillText(textData.text, left, top);

      // Update metrics after text is redrawn, important for resizing or changing text
      const metrics = ctx.measureText(textData.text);
      setTextData((prevData) => ({
        ...prevData,
        width: metrics.width,
        height:
          metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
      }));
    };
    if (paintText) {
      paintTextToCanvas(textData);    
      // setCanPlaceText(false);
      setEnablePaintText(false);
      handleSetImageDirty(true);
        handleSetTool("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paintText]);

  const handleMouseUpText = () => {
    setIsDragging(false);
    setEnablePaintText(true);
  };

  const handleDoubleClick = (e) => {
    setIsEditing(true);    
    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;    
    if (
      mouseX > textData.x &&
      mouseX < textData.x + 100 &&
      mouseY < textData.y &&
      mouseY > textData.y - 30
    ) {
      setIsEditing(true);
    }
  };

  const handleTextChange = (e) => {
    setTextData({ ...textData, text: e.target.value });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      handleSetImageDirty(true);
    }
  };

  return (
    <div>
      {tool === "text" && (
        <>          
          {isEditing && (
            <Input
              type="text"
              value={`${textData.text}`}
              onChange={handleTextChange}
              onKeyDown={handleKeyDown}
              style={{
                left: textData.x,
                top: textData.y,
              }}
              autoFocus
            />
          )}
        </>
      )}

      <img
        id="processedImage"
        ref={imageRef}
        src={imgSrc}
        width={1024}
        height={1024}
        alt="Color Picker Source"
        style={{ display: "none" }}
      />
      <div ref={printRef}>
        {tool === "text" && (
          <DraggableText
            draggableTextRef={draggableTextRef}
            initialPos={{ x: textData.x, y: textData.y }}
            text={textData.text}
            textData={textData}
            setTextData={setTextData}
            textColor={textColor}
            fontSize={fontSize}
            fontFamily={fontFamily}
            handleDoubleClick={handleDoubleClick}
            handleMouseMove={handleMouseMoveText}
            handleMouseDownText={handleMouseDownText}
            handleMouseUpText={handleMouseUpText}
          />
        )}

        <canvas
          ref={canvasRef}
          onMouseDown={
            tool === "text"
              ? handleMouseDownText
              : tool === "pen"
              ? startDrawing
              : tool === "eraser"
              ? startDrawing
              : null
          }
          onMouseMove={
            tool === "text"
              ? handleMouseMoveText
              : tool === "pen"
              ? draw
              : tool === "eraser"
              ? draw
              : null
          }
          onMouseUp={
            tool === "text"
              ? handleMouseUpText
              : tool === "pen"
              ? stopDrawing
              : tool === "eraser"
              ? stopDrawing
              : null
          }
          onMouseOut={
            tool === "pen"
              ? stopDrawing
              : tool === "eraser"
              ? stopDrawing
              : null
          }
          onDoubleClick={handleDoubleClick}
          onClick={
            tool !== "pen" && tool !== "eraser" && tool !== "text"
              ? handleCanvasClick
              : null
          }
          style={{
            cursor:
              tool === "magicwand"
                ? "crosshair"
                : tool === "text" && isEditing
                ? "text"
                : tool === "text" && !isEditing
                ? "move"
                : tool === "pen"
                ? `url('/penicon.png#${penSize}x${penSize}'), auto`
                : tool === "eraser"
                ? `url('/eraser16.png#${penSize}x${penSize}'), auto`
                : `url('/penicon.png#${penSize}x${penSize}'), auto`,
          }}          
        />
      </div>
    </div>
  );
}
