import React, {useContext} from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import IconButton from "@mui/material/IconButton";
import UserContext from "../../context/UserContext";

function DownloadNoBackgroundImageButton({ item, nobackgroundUrl }) {
  const { profile } = useContext(UserContext);
  return (
    <IconButton
      sx={{
        color: item.no_background_url || nobackgroundUrl
          ? "rgba(0, 0, 0)"
          : "rgba(0, 0, 0, 0.54)",
        disabled:
          profile?.given_name === "Guest" || (item?.no_background_url === "" && nobackgroundUrl === "")
            ? true
            : false,
        cursor:
          profile?.given_name === "Guest" || (item?.no_background_url === "" && nobackgroundUrl === "")
            ? "not-allowed"
            : "pointer",
      }}
      aria-label={`download without background`}
      title="Download without background"
      href={item?.no_background_url ? item?.no_background_url : nobackgroundUrl}
      target="_blank"
    >
      <DownloadOutlinedIcon sx={{
          marginRight: "0.5em"}}/>Download No Background
    </IconButton>
  );
}

export default DownloadNoBackgroundImageButton;
