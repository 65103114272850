import React from "react";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import FooterActionButtons from "../../components/buttons/FooterActionButtons";

export function TermsOfService() {
  return (
    <Paper
      elevation={3}
      sx={{
        margin: "auto",
        padding: "2em",
        textAlign: "left",
        width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
      }}
    >
      <Typography variant="h2">MerchDesign AI Terms of Service</Typography>
      <br />
      <Typography variant="h4"> 1. Acceptance of Terms</Typography>
      <Typography variant="body1" component="div">
        By accessing or using the MerchDesign AI web application ("MerchDesign
        AI" or "Service"), you agree to comply with and be bound by these Terms
        of Service ("Terms"). If you do not agree to these Terms, please do not
        use the Service.
      </Typography>
      <Typography variant="h4">2. Prohibited Content</Typography>
      <Typography variant="body1" component="div">
        Users are strictly prohibited from uploading, creating, or sharing any
        content that: a. Is sexually explicit or suggestive. b. Depicts or
        promotes violence, including the use of firearms or any other weapons.
        c. Advocates or promotes hate speech, including but not limited to,
        content that is discriminatory, racist, sexist, or bigoted. d.
        Encourages or glorifies illegal activities. e. Contains graphic or
        disturbing images. f. Violates any copyright, trademark, or other
        intellectual property rights. g. Is deemed inappropriate or offensive at
        the sole discretion of MerchDesign AI.
      </Typography>
      <Typography variant="h4">3. Content Review</Typography>
      <Typography variant="body1" component="div">
        MerchDesign AI reserves the right to review any content submitted to the
        Service. Any content found to be in violation of these Terms may be
        removed without prior notice. Users found repeatedly violating these
        terms may have their access to the Service terminated.
      </Typography>
      <Typography variant="h4">4. Unlimited Access</Typography>
      <Typography variant="body1" component="div">
        At MerchDesign AI, we empower our subscribers with unlimited access to
        our cutting-edge design tools, enabling you to unleash your creativity
        and bring your merchandise ideas to life. "Unlimited access" means you
        can explore, design, and create without the worry of exhausting service
        credits on a daily basis.
      </Typography>
      <Typography variant="h5">Understanding the Limits</Typography>
      <Typography variant="body1" component="div">
        <Typography variant="h6">Daily Image Generation Limit</Typography>
        While our platform promotes creative freedom, we implement a fair usage
        cap of 300 image generations per day for accounts that are specified as
        unlimited. This limit is designed to ensure equitable resource
        distribution among our user base, maintaining high performance and
        availability for all.
        <br />A day is a 24-hour cycle that resets at midnight UTC. If you reach
        the daily limit, you can continue generating images the next day.
      </Typography>
      <Typography variant="h6">Prohibition of Account Sharing</Typography>
      <Typography variant="body1" component="div">
        Each MerchDesign AI account is intended for individual use only. Sharing
        account credentials with others or using a single account
        collaboratively across multiple individuals or entities is strictly
        prohibited. This policy is in place to prevent unauthorized access and
        ensure that all users benefit from a secure and personalized experience
        on our platform.
      </Typography>
      <Typography variant="h6">Prohibition of Automation and Bots</Typography>
      <Typography variant="body1" component="div">
        The use of automation software, bots, or any other automated system to
        generate images or interact with our services is strictly prohibited.
        Such practices can overload our system and degrade the quality of
        service for other users. Violation of this policy may result in
        immediate suspension or termination of the account.
      </Typography>
      <Typography variant="h6">Fair Use Policy</Typography>
      <Typography variant="body1" component="div">
        Our daily limit falls under our Fair Use Policy, which safeguards
        against the misuse of resources. This policy helps us prevent service
        disruptions and ensures a smooth, efficient experience for everyone
        using MerchDesign AI.
      </Typography>
      <Typography variant="h6">Exceeding Daily Limits</Typography>
      <Typography variant="body1" component="div">
        If you reach your daily limit of 100 image generations (or the limit
        specified by your plan), further image creation attempts will be
        temporarily paused until the next day. We calculate daily usage based on
        a 24-hour cycle that resets at midnight UTC.
      </Typography>
      <Typography variant="h6">Monitoring and Adjustments</Typography>
      <Typography variant="body1" component="div">
        MerchDesign AI reserves the right to modify the daily image generation
        limit based on overall system performance and user feedback. Any changes
        to this limit will be communicated through updates to our Terms of
        Service and, where applicable, direct notifications to our users.
      </Typography>
      <Typography variant="h4">5. Disclaimers</Typography>
      <Typography variant="body1" component="div">
        MerchDesign AI is provided "as is" and "as available". We disclaim all
        warranties, express or implied, including but not limited to the
        accuracy, completeness, or reliability of content created through the
        Service.
      </Typography>
      <Typography variant="h4">6. Limitation of Liability</Typography>
      <Typography variant="body1" component="div">
        Under no circumstances shall MerchDesign AI, its affiliates, employees,
        or agents be liable for any indirect, incidental, special, or
        consequential damages arising from the use or inability to use the
        Service.
      </Typography>

      <Typography variant="h5">Limitation of Liability Clause</Typography>
      <Typography variant="h6">
        Limitation of Claims Against MerchDesign AI
      </Typography>
      <Typography variant="body1" component="div">
        The user acknowledges and agrees that, to the fullest extent permitted
        by law, MerchDesign AI, including its affiliates, officers, directors,
        employees, agents, and licensors, shall not be liable for any direct,
        indirect, incidental, special, consequential, or exemplary damages,
        including but not limited to damages for loss of profits, goodwill, use,
        data, or other intangible losses, resulting from: The use or the
        inability to use the service provided by MerchDesign AI; Unauthorized
        access to or alteration of your transmissions or data; Statements or
        conduct of any third party on the service; Any other matter relating to
        the service. Waiver of Rights to Sue: By agreeing to these Terms of
        Service, the user expressly waives any and all rights to bring any
        claims or actions against MerchDesign AI related to the use of the
        service, except for claims arising from MerchDesign AI’s gross
        negligence or willful misconduct. This waiver includes any claims that
        might arise after the user has terminated the use of MerchDesign AI’s
        services. Acknowledgment of Agreement: The user acknowledges that they
        have read and understood this limitation of liability clause, and agree
        that the limitations of liability set forth herein are fundamental
        elements of the basis of the bargain between MerchDesign AI and the
        user. Consequently, the service would not be provided without such
        limitations.
      </Typography>
      <Typography variant="h4">7. Email Communication and Marketing</Typography>
      <Typography variant="body1" component="div">
        By using MerchDesign AI, you agree that we may use your provided email
        address to send you information related to your account and the Service.
        This includes transactional emails, such as information about your
        subscription renewing, which are essential for continuous service and
        cannot be opted out of. Additionally, we may send you marketing
        communications, such as notifications about product updates, blog post
        updates, new informative videos, or special deals. You may opt out of
        receiving marketing communications at any time by following the
        unsubscribe instructions provided in these emails.
      </Typography>
      <Typography variant="h4">8. Changes to the Terms</Typography>
      <Typography variant="body1" component="div">
        MerchDesign AI reserves the right to update or modify these Terms at any
        time without prior notice. Continued use of the Service after any such
        changes shall constitute your consent to such changes.
      </Typography>
      <Typography variant="h4">9. Governing Law</Typography>
      <Typography variant="body1" component="div">
        These Terms shall be governed by and construed in accordance with the
        laws of the State of California, without regard to its conflict of law
        principles.
      </Typography>
      <Typography variant="h4">10. Image Visibility Policy</Typography>
      <Typography variant="body1" component="div">
        All images generated by our platform are publicly viewable by default
        for non-subscribers. This accessibility allows non-subscribing members
        and visitors to view a broad range of content hosted on our platform.
        However, if you wish to maintain privacy over your generated images, we
        offer an exclusive option for our subscribers. By subscribing to our
        service, you gain the ability to restrict public access to your images,
        thus making them viewable only to you and those you choose to share them
        with. Subscription not only enhances your control over image visibility
        but also provides additional premium features designed to improve your
        user experience. We encourage you to review our subscription plans to
        find one that best suits your privacy and usage needs.
      </Typography>
      <Typography variant="h4">
        Inactive Account Policy and Image Storage
      </Typography>
      <Typography variant="body1" component="div">
        To optimize our platform's performance and manage storage resources
        efficiently, we apply limitations to the storage of images for inactive
        and cancelled accounts. For active subscriptions, images are stored for
        the lifetime of the subscription up to the maximum storage limit
        specified in your plan. If your account remains inactive, defined as no
        logins or usage of our services, for a continuous period of 30 days, we
        reserve the right to delete all stored images associated with your
        account. Furthermore, if the inactivity persists, we reserve the right
        to terminate your account. In the event of subscription cancellation,
        you will have 30 days to access and manage your images before they are
        permanently deleted from our servers. We will attempt to notify you via
        the email associated with your account prior to any deletion, providing
        an opportunity to reactivate your account or save your data elsewhere.
        It is crucial to keep your contact information up-to-date to receive
        these notifications. We encourage you to regularly backup your content
        to avoid any potential loss of data.
      </Typography>

      <Typography variant="h4">11. Contact</Typography>
      <Typography variant="body1" component="div">
        For any questions or concerns about these Terms, please contact us at
        MerchDesignAI at gmail dot com.
      </Typography>
      <FooterActionButtons />
    </Paper>
  );
}
