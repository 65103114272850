import React, { useEffect, useState, useContext } from "react";
import UserContext from "../context/UserContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, Divider } from "@mui/material";
import Chip from "@mui/material/Chip";
import { format, parseISO } from "date-fns";
import { Button } from "@mui/material";
import SubscriptionLinks from "./navigation/SubscriptionLinks";

const BaseballCard = ({ planUsage, setProjectCount }) => {
  const [featureUsage, setFeatureUsage] = useState({});
  const { profile, APIToken, activeSubscription, logoutUser } = useContext(UserContext);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = parseISO(dateString);
    return format(date, "MMMM d, yyyy");
  };

  const handleLogout = () => {
    logoutUser();
  };

  useEffect(
    () => {
      async function getUserStats() {
        const response = await fetch(
          `${process.env.REACT_APP_API_BE}/api/profile/stats/${profile?.email}/`,
          {
            headers: {
              Authorization: `Token ${APIToken}`,

            },
          }
        );
        const data = await response.json();
        setFeatureUsage((prev) => ({ ...prev, ...data.feature_usage }));
        setProjectCount(data?.project_count);
      }
      if (profile?.email) {
        getUserStats();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [APIToken]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      {planUsage && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                margin: "auto",
                mt: 2,
                boxShadow: 3,
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {`${planUsage.given_name} ${planUsage.family_name}`}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {planUsage.email}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  A Merchdesigner since {formatDate(planUsage?.created_date)}
                </Typography>

                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography variant="body1">
                  <SubscriptionLinks activeSubscription={activeSubscription} />{" "}
                  |{" "}
                  <Button
                    onClick={handleLogout}
                    sx={{ color: "#04a9c8" }}
                    title="logout"
                  >
                    Logout
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                margin: "auto",
                mt: 2,
                boxShadow: 3,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "box",
                    marginTop: "1em",
                    marginRight: ".5em",
                    marginLeft: ".5em",
                  }}
                >
                  <Typography variant="body">
                    🔥 you've saved ${planUsage?.all_time_image_count * 15} 🔥
                  </Typography>
                  <Typography variant="body2">
                    using MerchDesign AI to create your designs
                  </Typography>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography variant="caption" color="text.secondary">
                    Our estimate based on the number of designs you've created
                    vs hiring a designer at $15 per design
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                margin: "auto",
                mt: 2,
                boxShadow: 3,
              }}
            >
              <CardContent>
                <Typography variant="h5" color="text.secondary">
                  Feature Usage
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Chip
                    sx={{ margin: "2px" }}
                    label={`${featureUsage?.edit_image_usage} Images Edited`}
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    sx={{ margin: "2px" }}
                    label={`${featureUsage?.upscale_usage} Images Upscaled`}
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    sx={{ margin: "2px" }}
                    label={`${featureUsage?.docgen_usage} Manuscripts Exported`}
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    sx={{ margin: "2px" }}
                    label={`${featureUsage?.remove_background_usage} Backgrounds Auto Removed`}
                    color="success"
                    variant="outlined"
                  />

                  <Chip
                    sx={{ margin: "2px" }}
                    label={`${featureUsage?.generate_descriptions_usage} SEO Listings Generated`}
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    sx={{ margin: "2px" }}
                    label={`${featureUsage?.generate_captions_usage} Captions Generated`}
                    color="success"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BaseballCard;
