import React, { useState, useContext } from "react";
import { Box } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import UserContext from "../../context/UserContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

export default function NewProjectForm({ handleGetProjectCollection }) {
  const [showInput, setShowInput] = useState(false);
  const { profile, APIToken } = useContext(UserContext);


  const handleShowInput = () => {
    setShowInput(!showInput);
  };

  const handleCreateProjectSubmit = async (e) => {
    e.preventDefault();
    
    if (e.currentTarget.elements[0].value !== "") {
      // setProjectName(e.currentTarget.elements[0].value);

      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/project`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${APIToken}`,
          },
          body: JSON.stringify({
            project_name: e.currentTarget.elements[0].value,
            email: profile?.email,
          }),
        }
      );
      if (response.status === 200) {
        await response.json();        
        handleGetProjectCollection();        
      }
    }
    setShowInput(!showInput);
  };

  return (
    <>
      <Box sx={{ padding: ".5em" }}>
        {showInput && (
          <>            
            <Dialog
              open={showInput}
              onClose={handleShowInput}
              PaperProps={{
                component: "form",
                onSubmit: handleCreateProjectSubmit,
              }}
            >
              <DialogTitle>Create Project</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To create a new project, please enter a project name here.
                </DialogContentText>
                <TextField                  
                  required
                  margin="dense"
                  id="project-name"                  
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleShowInput}>Cancel</Button>
                <Button type="submit">Create</Button>
              </DialogActions>
            </Dialog>           
          </>
        )}                
        <AddCircleOutlineIcon 
        onClick={handleShowInput}
        sx={{cursor: "pointer"}}
        />
      </Box>
    </>
  );
}
