import React, { useRef } from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ScrollableCardMedia = ({hero}) => {
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -400, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 400, behavior: 'smooth' });
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
      <IconButton
        onClick={scrollLeft}
        style={{color: "#ff6f00", backgroundColor:"white", borderStyle:"solid", borderColor:"#ff6f00", borderWidth:".01em", fontSize: 40 , position: 'absolute', left: 5, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <div
        ref={containerRef}
        style={{ display: 'flex', overflowX: 'auto', scrollBehavior: 'smooth', width: '100%' }}
      >
        <img
          src={`/landing/${hero}`}
          alt="Expand Your Horizons"
          style={{ height: '400px', marginBottom: '32px', flexShrink: 0 }}
        />
        {/* You can add more <img> elements here if needed */}
      </div>
      <IconButton
        onClick={scrollRight}
        style={{color: "#ff6f00", backgroundColor:"white", borderStyle:"solid", borderColor:"#ff6f00", borderWidth:".01em", fontSize: 40 , position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

export default ScrollableCardMedia;
