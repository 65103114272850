import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FooterActionButtons from "../components/buttons/FooterActionButtons";
import PublicAppBar from "../components/navigation/PublicAppBar";
import PhotoToColoring from "./PhotoToColoring";

const YourPhotoToColoringPage = ({ showHeader = true }) => {
  const [imageSelected, setImageSelected] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {showHeader && <PublicAppBar />}
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          mt: 4,
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "md", textAlign: "center" }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Convert a Photo To Coloring Page
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" paragraph>
            Upload any photo and watch it convert to a coloring page!
          </Typography>
          <Typography variant="body2" paragraph>
            Works best with high contrast photos
          </Typography>
        </Box>

        <Box
          sx={{
            my: 4,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: isSmallScreen ? "100%" : "lg",
            }}
          >
            <PhotoToColoring setImageSelected={setImageSelected} />
          </Box>
        </Box>

        {!imageSelected && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <Button
              variant="contained"
              size="large"
              href="/app"
              sx={{
                fontSize: "1.5rem",
                borderRadius: 5,
                color: "#ffffff",
                backgroundColor: "#13b5cf",
                "&:hover": {
                  backgroundColor: "#0e8fa3",
                },
              }}
            >
              Start Over
            </Button>
          </Box>
        )}
      </Container>

      <Box component="footer">
        <FooterActionButtons />
      </Box>
    </Box>
  );
};

export default YourPhotoToColoringPage;
