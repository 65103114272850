import React from "react";
import FooterActionButtons from "../components/buttons/FooterActionButtons";
import PricingTable from "../components/PricingTable";
import { Box } from "@mui/system";
import PublicAppBar from "../components/navigation/PublicAppBar";

const PricingPage = ({showHeader=true}) => {
  return (
    <>
    {showHeader &&
    <PublicAppBar />
    }

    
    <Box
    component="div"
    sx={{
      marginTop: "2em",
      paddingTop: "2em",
      margin: "auto",
      width: "100%",
      // maxWidth: "fit-content",
      overflow: "wrap", // or "auto" for scrollbars
      whiteSpace: "wrap", // Prevent line breaks
      textOverflow: "ellipsis", // Add ellipsis for truncated text
    }}
  >   
  
    <PricingTable />
    <FooterActionButtons />
    </Box>
    
    </>
  );
};

export default PricingPage;
