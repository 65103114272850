import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import PublicAppBar from "../../components/navigation/PublicAppBar"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { toPng } from "html-to-image";

const ImageDetail = () => {
  const { imageName } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const imageId = queryParams.get("imageId");
  const [imageData, setImageData] = useState(null);
  const printRef = useRef(null);

  const handleDownloadClick = useCallback(() => {
    if (printRef.current === null) {
      return;
    }

    toPng(printRef.current, {
      cacheBust: true,
      // TODO: Let user configure output size
      height: "512",
      width: "512",
      pixelRatio: window.devicePixelRatio, // Ensures high resolution
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = imageData?.name;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printRef]);

  // Fetch and display the image detail using imageName
  useEffect(() => {
    const fetchImageData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/public/image/${imageId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("data: ", data);
      setImageData(data[0]);
    };

    if (imageId) {
      fetchImageData();
    }
  }, [imageId]);

  if (!imageData) {
    return <div>Nothing here...</div>;
  }

  return (
    <>
    <PublicAppBar  />
    <Box
        component="div"
        style={{
          display: "flex",
          width: "100%",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h1>{imageName?.split(".png")[0]}</h1>
        <Paper
          elevation={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            height: "50%",
            margin: "20px 0",
          }}
        >
          <Box
            component="img"
            ref={printRef}
            src={imageData.image_url}
            alt={imageName}
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </Paper>
        <Box
          component="div"
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            className="plausible-event-name=PublicImage-ImageView-Downloaded"
            variant="contained"
            color="primary"
            sx={{
              fontSize: "1.5rem",
              borderRadius: 5,
              marginTop: "5%",
              marginBottom: "5%",
              marginRight: "1%",
              backgroundColor: "#13b5cf",
              "&:hover": {
                color: "#13b5cf",
                backgroundColor: "#ffffff",
              },
            }}
            title="Download"
            onClick={handleDownloadClick}
          >
            Download
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ImageDetail;
