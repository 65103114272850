import React from "react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";


function CommunityImageLinks() {
  
  
  return (
    <>
        
      <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1em", marginBottom: "1em"}}>
      <Button href="/free-coloring-pages" sx={{ color: "#04a9c8" }} title="Free Coloring Pages">
      Free Coloring Pages
        </Button>
        <Button href="/free-sticker-designs" sx={{ color: "#04a9c8" }} title="Free Sticker Designs">
        Free Sticker Designs
        </Button>
        <Button href="/free-tshirt-designs" sx={{ color: "#04a9c8" }} tile="Free T-Shirt Designs">
        Free T-Shirt Designs
        </Button>
       
        
      </Grid>
    </>
  );
}

export default CommunityImageLinks;
