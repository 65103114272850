import React, { useState, useContext, useEffect } from "react";
import { Box, Paper, Divider } from "@mui/material";
import ImageGrid from "../components/designs/ImageGrid";
import ImageList from "../components/designs/ImageList";
import Grid from "@mui/material/Grid";
import NavButtons from "../components/navigation/NavButtons";
import ProjectContainer from "../components/projects/ProjectContainer";
import UserContext from "../context/UserContext";
// import useMediaQuery from "@mui/material/useMediaQuery";
import ExportToWordDialog from "../components/ExportToWordDialog";
import { FormControl, MenuItem, Select, IconButton } from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";
// import CustomHTMLToolTip from "../components/CustomHTMLToolTip";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import SearchBox from "../pages/gallery/SearchBox";

import axios from "axios";

export default function DesignContainer() {
  const [selectedProject, setSelectedProject] = useState({});
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [projectCollection, setProjectCollection] = useState([]);
  const [showDocExportDialog, setShowDocExportDialog] = useState(false);
  const [multiSelectProjectId, setMultiSelectProjectId] = useState("");
  const [multiSelectProjectName, setMultiSelectProjectName] = useState("");
  const [showPaywall, setShowPaywall] = useState(false);
  const [showGridView, setShowGridView] = useState(true);
  const [showListView, setShowListView] = useState(false);
  // const [minimizeProjects, setMinimizeProjects] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { profile, logoutUser, APIToken } = useContext(UserContext);
  // const isMobile = useMediaQuery("(max-width:600px)");
  // const isIpad = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    if (projectCollection && searchTerm === "") {
      setSelectedProject(projectCollection[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [projectCollection]);

  // useEffect(() => {
  //   setMinimizeProjects(isMobile);
  // }, [isMobile]);

  // const handleMinimizeProjects = () => {
  //   setMinimizeProjects(!minimizeProjects);
  // };

  const hideGridView = () => {
    setShowGridView(false);
    setShowListView(true);
  };

  const hideListView = () => {
    setShowListView(false);
    setShowGridView(true);
  };

  useEffect(() => {
    async function searchForImages() {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/search/?query=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${APIToken}`,
          },
        }
      );
      await response.json().then((resp) => {
        if (!resp) {
          return;
        }
        setProjectCollection((prevProjectCollection) => {
          // Remove any existing project with the name "Search"
          const filteredCollection = prevProjectCollection.filter(
            (project) => project.name !== "Search"
          );

          // Add the new "Search" project
          return [
            ...filteredCollection,
            {
              id: 0,
              description: "Search",
              name: "Search",
              user: profile.id,
              images: resp,
            },
          ];
        });
        setSelectedProject({
          id: 0,
          description: "Search",
          name: "Search",
          user: profile.id,
          images: resp,
        });
      });
    }
    if (searchTerm !== "") {
      searchForImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  async function handleGetProjectCollection(page = 1) {
    const token = localStorage.getItem("token");
    if (!token || token === "undefined") {
      console.error("Not logged in");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BE}/api/projects?email=${profile?.email}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized, please log in first");
        logoutUser();
      } else if (response.status === 200) {
        const data = await response.data;
        if (data === null || data?.results?.length === 0) {      
          setProjectCollection([]);
        } else {
          setProjectCollection(data);
        }
      }
    } catch (error) {
      console.error("Error fetching project collection:", error);
      logoutUser();
    }
  }

  const handleMultiSelect = (project) => {
    console.log(`Selected project: ${project}`);
    setMultiSelectProjectId(project);
  };

  const handleShowDocExportDialog = (project) => {
    setSelectedProject(project);
    setShowDocExportDialog(!showDocExportDialog);
  };

  const handleSelectProject = (project) => {
    setCurrentPage(1);
    setSelectedProject(project);
    setMultiSelectProjectId(null);
    setMultiSelectProjectName("");
    if (project.name === "All") {
      setSearchTerm("");
    }
  };

  const handleRefreshProjects = (page = currentPage) => {
    setRefreshProjects(!refreshProjects);
    setCurrentPage(page);
  };

  const handleChange = (event) => {  
    setMultiSelectProjectName(event.target.value);
    setMultiSelectProjectId(
      projectCollection.find((item) => item.name === event.target.value).id
    );
    if (event.target.value === "All") {
      setSearchTerm("");
    }
  };

  const handleShowPaywall = () => {
    setShowPaywall(!showPaywall);
    if (showPaywall) {
      setShowDocExportDialog(false);
    }
  };

  return (
    <>
      <NavButtons showTagLine={false} />
      <Grid container direction="row" spacing={1} sx={{ padding: "2em" }}>
        <Grid item xs={12} sm={4} md={3}>
          <Box
            sx={{
              flexGrow: 2,              
              maxWidth: 600,
              // width: "100%",
              textAlign: "left",
              backgroundColor: "aliceblue",
              height: "100%",
              marginRight: ".25rem",
              paddingRight: "1rem",
            }}
          >
            {showDocExportDialog && (
              <ExportToWordDialog
                selectedProject={selectedProject}
                showDocExportDialog={showDocExportDialog}
                handleShowDocExportDialog={handleShowDocExportDialog}
                showPaywall={showPaywall}
                handleShowPaywall={handleShowPaywall}
              />
            )}
            <ProjectContainer
              projectCollection={projectCollection}
              handleGetProjectCollection={handleGetProjectCollection}
              setProjectCollection={setProjectCollection}
              handleSelectProject={handleSelectProject}
              refreshProjects={refreshProjects}
              // handleShowDocExportDialog={handleShowDocExportDialog}
              handleMultiSelect={handleMultiSelect}
              // minimizeProjects={minimizeProjects}
              // handleMinimizeProjects={handleMinimizeProjects}
            />
            {/* )} */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          {(selectedProject?.name === "All" ||
            selectedProject?.name === "Search") && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "stretch", sm: "center" },
                  justifyContent: "center",
                  marginLeft: { xs: "-.5rem", sm: ".25em" },
                  marginRight: { xs: "0rem", sm: ".25em" },
                  width: "100%",
                }}
              >
                {!showListView && (
                <Paper
                  elevation={4}
                  sx={{
                    padding: ".5rem",
                    marginRight: { xs: 0, sm: "1rem" },
                    marginBottom: { xs: "1rem", sm: 0 },
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    width: { xs: "100%", sm: "100%" },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width:"100%",
                    }}
                  >
                    {/* <CustomHTMLToolTip
                      title="Organize Designs into Projects"
                      body="Selecting project from list. Click 'add to project' icon located on design info bar to add to the project."
                      children={
                        <InfoIcon
                          sx={{
                            color: "#04a9c8",
                            marginTop: ".75em",
                            marginLeft: ".5rem",
                            marginRight: ".5rem",
                          }}
                        />
                      }
                    ></CustomHTMLToolTip> */}
                    <FormControl
                      sx={{
                        width: "100%",
                        borderRadius: "4rem",
                        backgroundColor: "white",
                      }}
                    >
                      <Select
                        labelId="project-list-input-selector"
                        id="project-list-input-selector"
                        value={multiSelectProjectName}
                        label="Add Designs To a Project"
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          padding: "6px 14px",
                          borderRadius: "4rem",
                          borderStyle: "solid",
                          borderWidth: "6px",
                          borderColor: "#0cb5ce",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiSelect-select": {
                            padding: "10px 14px", // Adjust padding as needed
                          },
                        }}
                      >
                        <MenuItem key={"label"} value={""}>
                          Add designs to a project
                        </MenuItem>
                        {projectCollection
                          .filter(
                            (project) =>
                              project.name !== "All" &&
                              project.name !== "Search"
                          )
                          .map((project) => (
                            <MenuItem key={project.id} value={project.name}>
                              {project.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>                  
                </Paper>
                )}

                <Paper
                  elevation={4}
                  sx={{
                    padding: ".5rem",
                    width: {
                      xs: "100%",
                      // sm: isMobile ? "100%" : isIpad ? "90%" : "70%",
                    },
                    marginRight: { xs: 0},
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ flexGrow: 1, marginRight: ".frem" }}>
                    <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "40px", // Fixed width for the icon buttons
                      marginBottom: ".5rem",
                    }}
                  >
                    <IconButton
                      onClick={hideListView}
                      aria-label="Grid View"
                      title="Grid View"
                      edge="end"
                      sx={{ padding: "0px" }}
                    >
                      <ViewModuleIcon />
                    </IconButton>
                    <IconButton
                      onClick={hideGridView}
                      aria-label="List View"
                      title="List View"
                      edge="end"
                      sx={{ padding: "0px" }}
                    >
                      <TableRowsIcon />
                    </IconButton>
                  </Box>
                </Paper>
              </Box>
            </>
          )}

          {showGridView && (
            <ImageGrid
              profile={profile}
              selectedProject={selectedProject}
              projectList={projectCollection}
              handleRefreshProjects={handleRefreshProjects}
              multiSelectProjectId={multiSelectProjectId}
              multiSelectProjectName={multiSelectProjectName}
              isAdmin={false}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          {showListView && (
            <ImageList
              profile={profile}              
              selectedProject={selectedProject}
              projectList={projectCollection}
              isAdmin={false}
            />
          )}
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
      <FooterNavLinks />
      <CommunityImageLinks />
      <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
    </>
  );
}

// export default DesignContainer;
