import React from 'react'
import { Box, Chip } from '@mui/material'


// image_modifiers is an object with keys that are the modifier type and values that are the modifier value
export default function ImageModifierChips({image_modifiers}) {
    return (
        <div>
        <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
          {Object.keys(image_modifiers?.[0] ?? {}).map((key) => (
            <Box key={key} mr={1} mb={1}>
              {image_modifiers[0][key].length > 0 ? (
                <Chip
                  label={image_modifiers[0][key]}
                  size="small"
                  sx={{
                    marginRight: "0.25em",
                    backgroundColor:
                      key === "art_style"
                        ? "#da17aa"
                        : key === "color_style"
                        ? "black"
                        : key === "animation_style"
                        ? "#0cb5ce"
                        : key === "mood"
                        ? "green"
                        : key === "theme"
                        ? "purple"
                        : "yellow",

                    color: "white",
                  }}
                />
              ) : null}
            </Box>
          ))}
        </Box>    
        </div>
    )
}