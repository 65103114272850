import React from 'react';
import {Typography } from '@mui/material';
import { Divider } from '@mui/material';
import UserAlert from "../UserAlert";
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';


export default function PromptInput({ showSubjectAlert, handleSubjectChange, handleShowTuner }) {
    return (
        <>
        {showSubjectAlert && (
            <UserAlert
              title="Subject Too Long"
              message={
                <>
                  <Divider />
                  <Typography variant="body1">
                    Please use a subject that is less than 500 characters.
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    Merchdesign AI customizes prompts so that you don't have to.
                  </Typography>
                  <Typography variant="body2">
                    Focus on the subject and scenario. Don't describe the media type.
                  </Typography>
                </>
              }
              severity="error"
            />
          )}
          <OutlinedInput
                type="text"
                id="subject"
                name="subject"
                placeholder="subject of your design"
                onChange={handleSubjectChange}
                fullWidth
                sx={{ paddingRight: "1em" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleShowTuner()}
                      aria-label="Show image tuner"
                      title="Tune Image"
                      edge="end"
                      style={{
                        color: "#08acc8",
                        border: "1px solid #08acc8",
                        borderRadius: "5px",
                      }}
                    >
                      <TuneIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></OutlinedInput>
          </>
    );
    }

