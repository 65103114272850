import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/system";
import { generateProductCopy } from "../prompts/merch-copy-prompts";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Header from "./Header";
import FooterActionButtons from "./buttons/FooterActionButtons";
import UserContext from "../context/UserContext";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import { List, ListItem, ListItemText, Divider } from "@mui/material";
import GenerateSEOListingButton from "./buttons/GenerateSEOListingButton";
import BreadcrumbNav from "./navigation/BreadcrumbNav";
import ImageHighlight from "./ImageHighlight";
import axios from "axios";


function SEOListingGen() {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [openSpinner, setOpenSpinner] = useState(false);
  const [copySaved, setCopySaved] = useState(false);
  const { profile, APIToken } = useContext(UserContext);
  const { itemId } = useParams();

  const [formData, setFormData] = useState({
    brand: "",
    design_title: "",
    description: "",
    feature_bullet_1: "",
    feature_bullet_2: "",
    keywords: [],
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchData = async () => {
      if (!itemId || !APIToken) return;

      try {
        const encodedEmail = encodeURIComponent(profile.email);
        const response = await fetch(          
          `${process.env.REACT_APP_API_BE}/api/image?image_id=${itemId}&email=${encodedEmail}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${APIToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCurrentItem(data[0]);
        
        await callGetMarketingCopy();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error state here, e.g., set an error message state
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, APIToken]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleDisplaySnackBar = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  async function callGetMarketingCopy() {
    if (currentItem?.image_id !== undefined && profile?.email !== undefined) {
      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_API_BE}/api/listing/${currentItem?.image_id}/?email=${profile?.email}`,
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: `Token ${APIToken}`,
        },
      };

      try {
        const response = await axios.request(options);
        var jsonArrayKewords = response?.data[0]?.keywords.replace(/'/g, '"');
        if (jsonArrayKewords) {
          jsonArrayKewords = JSON.parse(jsonArrayKewords);
        }

        setFormData((prevState) => ({
          ...prevState,
          brand: response?.data[0]?.brand || "",
          design_title: response?.data[0]?.design_title || "",
          feature_bullet_1: response?.data[0]?.feature_bullet_1 || "",
          feature_bullet_2: response?.data[0]?.feature_bullet_2 || "",
          description: response?.data[0]?.description || "",
          keywords: jsonArrayKewords || [],
        }));
        return response; // return the response
      } catch (error) {
        console.error(error);
        return null; // return null in case of an error
      }
    }
  }

  const callGenerateProductCopy = async () => {
    setOpenSpinner(true);
    await generateProductCopy(
      currentItem?.name,
      currentItem?.low_res_image_url
        ? currentItem?.low_res_image_url
        : currentItem?.variation_url
        ? currentItem?.variation_url
        : currentItem?.image_url,
      APIToken, 
      profile?.email
    ).then((response) => {
      var messageObject = JSON.parse(response);

      setFormData({
        brand: messageObject.Brand,
        design_title: messageObject.DesignTitle,
        feature_bullet_1: messageObject.FeatureBullet1,
        feature_bullet_2: messageObject.FeatureBullet2,
        description: messageObject.Description,
        keywords: messageObject.Keywords,
      });
      setOpenSpinner(false);
      setCopySaved(false);
    });
  };

  useEffect(() => {
    const handleCheckIfMarketingCopyExists = async () => {
      await callGetMarketingCopy().then(setOpenSpinner(false));
    };
    if (currentItem?.image_id !== undefined && profile?.email !== undefined) {
      handleCheckIfMarketingCopyExists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRefreshCopy = () => {
    callGenerateProductCopy();
  };

  const handleSaveCopy = async () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_BE}/api/listing`,
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Token ${APIToken}`,
      },
      data: {
        brand: formData.brand,
        design_title: formData.design_title,
        description: formData.description,
        feature_bullet_1: formData.feature_bullet_1,
        feature_bullet_2: formData.feature_bullet_2,
        keywords: formData.keywords,
        image_id: currentItem?.image_id,
        email: profile?.email,
      },
    };

    try {
      await axios.request(options);
      setCopySaved(true);
    } catch (error) {
      console.error(error);
    }
  };

  function handleCopy(element) {
    const copyText = document.getElementById(element).value;
    navigator.clipboard.writeText(copyText);
    handleDisplaySnackBar();
  }

  function copyKeywordText(keywords) {
    navigator.clipboard.writeText(keywords);
    handleDisplaySnackBar();
  }

  // const openInNewTab = (url) => {    
  //   if (!url) return;
  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  // };

  return (
    <>
      <Header showDesigns={true} />
      <Box
        component="div"
        sx={{ display: "block", width: "fit-content", margin: "auto" }}
      >
        <BreadcrumbNav here="Generate SEO Listing" prev="Designs" prevprev="Home" />
      </Box>
      <Paper
        elevation={4}
        sx={{
          padding: "1em",
          width: isMobile ? "90%" : "60%",
          margin: "auto",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openSpinner}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography align="center" variant="h2">
          Create SEO Focused Listing
        </Typography>
        <Box sx={{ width: isMobile ? "95%" : "60%", margin: "auto" }}>
          <Typography variant="body1" align="center">
            Generate SEO focused listing copy for your product.
          </Typography>
        </Box>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 4, md: 4 }}
        >
          <Grid item xs={4} sm={4} md={4}>
            <Box
              component="div"
              sx={{ width: "100%", marginTop: "1%", marginBottom: "1em" }}
            >
              {formData?.brand?.length === 0 && (
                <GenerateSEOListingButton
                  refresh={formData.brand.length > 0}
                  handleRefreshCopy={handleRefreshCopy}
                  openSpinner={openSpinner}
                />
              )}
            </Box>
          </Grid>

          {formData.brand.length > 0 ? (
            <>
              <Grid item xs={4} sm={4} md={4}>
                <FormHelperText id="outlined-weight-helper-text">
                  🔸 Design Title
                </FormHelperText>
                <OutlinedInput
                  type="text"
                  id="design_title"
                  name="design title"
                  value={formData.design_title}
                  onChange={handleInputChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleCopy("design_title")}
                        aria-label="copy design title"
                        title="Copy Design Title"
                        edge="end"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <FormHelperText id="outlined-weight-helper-text">
                  🔸 Brand
                </FormHelperText>
                <OutlinedInput
                  type="text"
                  id="brand"
                  name="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleCopy("brand")}
                        aria-label="copy brand"
                        title="Copy Brand"
                        edge="end"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <FormHelperText id="outlined-weight-helper-text">
                  🔸 Keywords
                </FormHelperText>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    alignContent: "start",
                    wordWrap: "normal",
                  }}
                >
                  <Paper style={{ display: "flex", flexWrap: "wrap" }}>
                    {formData.keywords.map((keyword) => (
                      <div key={keyword} style={{ margin: "5px" }}>
                        {keyword}
                      </div>
                    ))}

                    <Box
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        marginLeft: "91%",
                      }}
                    >
                      <IconButton
                        onClick={() => copyKeywordText(formData.keywords)}
                        aria-label="copy keywords"
                        title="Copy Keywords"
                        edge="end"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <FormHelperText id="outlined-weight-helper-text">
                  🔸 Description
                </FormHelperText>
                <OutlinedInput
                  multiline
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleCopy("description")}
                        aria-label="copy description"
                        title="Copy Description"
                        edge="end"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <FormHelperText id="outlined-weight-helper-text">
                  🔸 Feature Bullet 1
                </FormHelperText>
                <OutlinedInput
                  multiline
                  id="feature_bullet_1"
                  name="feature_bullet_1"
                  value={formData.feature_bullet_1}
                  onChange={handleInputChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleCopy("feature_bullet_1")}
                        aria-label="copy feature bullet 1"
                        title="Copy Feature Bullet 1"
                        edge="end"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <FormHelperText id="outlined-weight-helper-text">
                  🔸 Feature Bullet 2
                </FormHelperText>
                <OutlinedInput
                  multiline
                  id="feature_bullet_2"
                  name="feature_bullet_2"
                  value={formData.feature_bullet_2}
                  onChange={handleInputChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleCopy("feature_bullet_2")}
                        aria-label="copy feature bullet 2"
                        title="Copy Feature Bullet 2"
                        edge="end"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={4} sm={4} md={4}>
            <Box
              component="div"
              sx={{ width: "100%", marginTop: "0em", marginBottom: "0em" }}
            >
              <Grid item xs={4} sm={4} md={4}>
                {formData.brand.length > 0 ? (
                  <Button
                    variant="contained"
                    onClick={handleSaveCopy}
                    sx={{
                      fontSize: "1.5rem",
                      borderRadius: 5,
                      marginTop: "5%",
                      marginBottom: "5%",
                      marginRight: "1%",
                      backgroundColor: copySaved ? "#bdbdbd" : "#13b5cf",
                      "&:hover": {
                        color: copySaved ? "#ffffff" : "#13b5cf",
                        backgroundColor: copySaved ? "#bdbdbd" : "#ffffff",
                      },
                      disabled: copySaved ? true : false,
                      cursor: copySaved ? "not-allowed" : "pointer",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <></>
                )}
                {formData?.brand?.length > 0 && (
                  <GenerateSEOListingButton
                    refresh={formData.brand.length > 0}
                    handleRefreshCopy={handleRefreshCopy}
                    openSpinner={openSpinner}
                  />
                )}
              </Grid>
                           
              <ImageHighlight currentItem={currentItem} />
            </Box> 
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Copied!{" "}
          </Alert>
        </Snackbar>
      </Paper>
      <FooterActionButtons />
    </>
  );
}

export default SEOListingGen;
