import React from "react";
import { Box } from "@mui/system";
import { Button, Divider, Typography } from "@mui/material";
import logo from "../MerchDesignTransparentCleanSM-svg.webp";

export default function Header({
  isLandingPage,
  waitingForResponse,
  showDesigns,
}) {

  return (
    <Box
      component="div"
      sx={{
        margin: "auto",
        width: "100%",
        maxWidth: "fit-content",
        overflow: "wrap", // or "auto" for scrollbars
        whiteSpace: "wrap", // Prevent line breaks
        textOverflow: "ellipsis", // Add ellipsis for truncated text
      }}
    >
      {showDesigns && (
      <Button href="/app">
        <Box
          component="img"
          sx={{
            height: "auto",
            width: showDesigns ? "50px" : "225px",
            display: showDesigns ? "inline-grid" : "none",
            paddingTop: showDesigns ? "1em" : "0",
            margin: showDesigns ? "auto" : "0",
            animation: waitingForResponse ? "spin 7s linear infinite" : "",
          }}
          alt="MerchDesign AI logo"
          src={logo}
        />
      </Button>
      )}
      <Box
        sx={{ display: "inline-grid", margin: "auto", width: "fit-content" }}
      >
        <Typography
          variant="body1"
          sx={{
            display: !isLandingPage ? "block" : "inline-grid",
            // paddingBottom: "1em",
            width: "95%",
            margin: "auto",
            marginBottom: ".5em",
            marginTop: "1em",
            // marginLeft: "1em",
            // marginRight: "1em",
          }}
        >
          Create coloring book pages and print on demand ready designs in under 2 minutes with
          MerchDesign AI
        </Typography>
      </Box>
      <Divider
        sx={{
          display: !isLandingPage ? "block" : "inline-grid",
          width: "100%",
          margin: "auto",
          marginBottom: "1em",
        }}
      />
      <Button href="/app">
        <Box
          component="img"
          sx={{
            height: "auto",
            width: !showDesigns ? "150px" : "50px",
            display: !showDesigns ? "block" : "none",
            margin: "auto",
            animation: waitingForResponse ? "spin 7s linear infinite" : "",
          }}
          alt="MerchDesign AI logo"
          src={logo}
        />
      </Button>      
    </Box>
  );
}
