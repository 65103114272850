import React, { useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import { callCheckImageCount } from "./callCheckImageCount";
import { callCheckFeedbackStatus } from "./callCheckFeedbackStatus";
import ExtendTrialForm from "../components/ExtendTrialForm";
import PaywallPopup from "../components/PaywallPopup";
import UserContext from "../context/UserContext";
import DiscountCodeOffer from "../components/DiscountCodeOffer";
import UsageExceeded from "../components/UsageExceeded";

export default function OnboardingStateDirector({
  profile,
  showPaywall,
  handleShowPaywall,  
}) {
  const {
    imageCount,
    setImageCount,
    dailyImageCount,
    setDailyImageCount,
    monthlyImageCount,
    setMonthlyImageCount,
    storageCount,
    setStorageCount,
    setIsUsageLimited,
    activeSubscription,
    setActiveSubscription,
    hasExtendedTrial,
    setHasExtendedTrial,
    setNoAccessAllowed,
  } = useContext(UserContext);
  // const [monthlyImageCount, setMonthlyImageCount] = useState(0);
  const [showExtendTrial, setShowExtendTrial] = useState(false);
  const [showDiscountCode, setShowDiscountCode] = useState(false);

  const [subDailyLimit, setSubDailyLimit] = useState(0);
  const [subMonthlyLimit, setSubMonthlyLimit] = useState(0);
  const [subStorageLimit, setSubStorageLimit] = useState(0);

  const [subDailyLimitExceeded, setSubDailyLimitExceeded] = useState(false);
  const [subMonthlyLimitExceeded, setSubMonthlyLimitExceeded] = useState(false);
  const [subStorageLimitExceeded, setSubStorageLimitExceeded] = useState(false);

  const [showUsageExceeded, setShowUsageExceeded] = useState(false);
  const [storagePopupDisplay, setStoragePopupDisplay] = useState(false);

  const TRIAL_IMAGE_COUNT = 6
  const EXTENDED_TRIAL_IMAGE_COUNT = 10 


  useEffect(() => {
    if (profile?.email) {
      checkIfSubscriptionActive(profile?.email);
      handleCheckImageCount();
      handleCheckFeedback();
      // checkIfUsageExceeded();
    }
    // eslint-disable-next-line
  }, [profile?.email]);

  useEffect(() => {
    handleCheckImageCount();
    if (subStorageLimit > 0) {
      checkIfUsageExceeded();
    }
    // eslint-disable-next-line
  }, [imageCount]);

  useEffect(() => {
    // user has created 4 images, has no active subscription and has not extended trial
    // so show them the extend trial form
    if (imageCount >= TRIAL_IMAGE_COUNT && !activeSubscription && !hasExtendedTrial) {
      setShowExtendTrial(true);
    }
    // user has created 8 images, has no active subscription and has extended trial
    // so show them a discount code offer
    else if (imageCount >= EXTENDED_TRIAL_IMAGE_COUNT && !activeSubscription && hasExtendedTrial) {
      setShowExtendTrial(false);
      setShowDiscountCode(true);
    }
    // eslint-disable-next-line
  }, [imageCount]);

  const handleShowExtendTrial = () => {
    setShowExtendTrial(!showExtendTrial);
  };

  const handleShowDiscountCode = () => {
    setShowDiscountCode(false);
  };

  const handleShowUsageExceeded = () => {
    console.log(`showUsageExceeded: ${showUsageExceeded}`);
    setShowUsageExceeded(!showUsageExceeded);
  };


  async function checkIfSubscriptionActive() {
    const response = await fetch(
      `${process.env.REACT_APP_API_BE}/api/subscription/${profile?.email}/`
    );
    await response.json().then((resp) => {
      setActiveSubscription(resp[0]?.subscription_active || false);
      localStorage.setItem("activeSubscription", resp[0]?.subscription_active);
    });
  }

  function handleCheckImageCount() {
    const count_promise = callCheckImageCount(profile?.email);
    count_promise
      .then((counts) => {        
        setImageCount(counts?.image_count);
        setDailyImageCount(counts?.daily_image_count);
        setIsUsageLimited(counts?.usage_limited);
        setMonthlyImageCount(counts?.monthly_image_count);     
        setStorageCount(counts?.storage_count);                    
        setShowUsageExceeded(counts.usage_limited);
      })
      .catch((error) => {
        console.error("Error fetching image counts:", error);
      });
  }

  function closeAndBlockAccess() {
    setSubMonthlyLimitExceeded(false);
    setNoAccessAllowed(true);
    setStoragePopupDisplay(!setStoragePopupDisplay)
  }

  function checkIfUsageExceeded() {
    console.log(`dailyImageCount: ${dailyImageCount}, subDailyLimit: ${subDailyLimit}`);
    if (dailyImageCount >= subDailyLimit) {
      // debugger;
      setSubDailyLimitExceeded(true);
    }
    // TODO: check if monthly limit exceeded, based on calendar month
    console.log(`monthlyImageCount: ${monthlyImageCount}, subMonthlyLimit: ${subMonthlyLimit}`);
    if (monthlyImageCount >= subMonthlyLimit) {
      setSubMonthlyLimitExceeded(true);
    }
    console.log(`storageCount: ${storageCount}, subStorageLimit: ${subStorageLimit}`);
    if (storageCount >= subStorageLimit) {
      setSubStorageLimitExceeded(true);
      setNoAccessAllowed(true);
    }
  }


  function handleCheckFeedback() {
    if (profile?.email) {
      const feedback_promise = callCheckFeedbackStatus(profile?.email);
      feedback_promise
        .then((feedback) => {
          setHasExtendedTrial(feedback.has_extended_trial);
        })
        .catch((error) => {
          console.error("Error fetching feedback:", error);
          setHasExtendedTrial(false);
        });
    }
  }

  useEffect(() => {
      // User has created 8 images, has no active subscription and has not extended trial
      // so disable access to image creation
      if (imageCount >= EXTENDED_TRIAL_IMAGE_COUNT && !activeSubscription && hasExtendedTrial) {
        setNoAccessAllowed(true);
      }      
      
    },
    // eslint-disable-next-line
    [imageCount, activeSubscription, hasExtendedTrial]);

    // check user's plan limits
  useEffect(() => {
    const retrievePlanLimits = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BE}/api/subscription/limits/${profile?.email}/`
      );
      const data = await response.json();
      // designs_per_day: 10
      // manuscripts_per_month: 10
      // max_image_storage: 1000
      // plan_name: "Pencil"projects_per_month: 10

      // have decided to set the daily limit to 30 times the daily limit bc it can be frustrating
      // to not be able to finish a project in one day
      setSubDailyLimit(data?.designs_per_month);
      setSubMonthlyLimit(data?.designs_per_month);
      setSubStorageLimit(data?.max_image_storage);
    }
    if (profile?.email) {
      retrievePlanLimits();
    }
    // eslint-disable-next-line
  }
  , [profile?.email]);


  
  return (
    <Box  sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1em',
      width: '100%',
      boxSizing: 'border-box',
    }}>
      {imageCount >= TRIAL_IMAGE_COUNT && activeSubscription === false && !hasExtendedTrial && (
        <ExtendTrialForm
          profile={profile}
          showExtendTrial={true}
          handleShowExtendTrial={handleShowExtendTrial}
        />
      )}
      {imageCount >= EXTENDED_TRIAL_IMAGE_COUNT && activeSubscription === false && hasExtendedTrial && (
        <DiscountCodeOffer
          profile={profile}
          showDiscountCode={showDiscountCode}
          handleShowDiscountCode={handleShowDiscountCode}
        />
      )}
      {showPaywall && (
        <PaywallPopup
          title="Subscription Feature"
          buttonTxt="Join Now"
          message="Subscribe to enable multiple image generation"
          showPaywall={showPaywall}
          handleShowPaywall={handleShowPaywall}
        />
      )}

      {(subDailyLimitExceeded || subMonthlyLimitExceeded) && activeSubscription === true && (       
        <UsageExceeded
          message={`You have exceeded your monthly image generation limit of ${subMonthlyLimit} images`}
          showUsageExceeded={showUsageExceeded}
          handleShowUsageExceeded={handleShowUsageExceeded}
        />
      )}

      {subStorageLimitExceeded && activeSubscription === true && (
        <PaywallPopup
          title="Storage Limit Exceeded"
          buttonTxt="storage limit"
          message={`You have exceeded your storage limit of ${subStorageLimit} images. You will need to upgrade your subscription to continue creating images.`}
          showPaywall={storagePopupDisplay}
          // handleShowPaywall={() => setSubStorageLimitExceeded(false)}
          handleShowPaywall={closeAndBlockAccess}
        />
      )}
      
    </Box>
  );
}
