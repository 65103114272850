import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CategoryChip from "./CategoryChip";

export default function Categories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoriesToRemove = [
      "null",
      "0 category",
      "adult",
      "alcholic beverage",
      "body",
      "star wars",
      "steam punk",
      "violence",
      "unknown",  
      "coloring page sticker",
      "anatomy",
      "archaeology",
      "cinema",
      "bath",
      "disney",
      "aviation",
      "dance",

    ];
    fetch(`${process.env.REACT_APP_API_BE}/api/normalized_categories/`)
    .then((response) => response.json())
    .then((data) => {
      const adjustedCategories = data.filter(
        (category) => category && 
        category.normalized_name && !categoriesToRemove.includes(category.normalized_name.toLowerCase())
      );

      setCategories(adjustedCategories);
    })
    .catch((error) => {
      console.error("Error fetching categories:", error);
    });
  }, []);

  return (
    <Container sx={{ backgroundColor: "white" }}>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Typography sx={{ color: "#04a9c8" }} variant="h4" align="center">
        Browse Free Community Images
      </Typography>
      <Divider sx={{ marginTop: "1rem", marginBottom: "2rem" }} />
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {Object.keys(categories).map((category) => (              
              <React.Fragment key={categories[category].normalized_name}>
                {categories[category].image_count !== 0 && (                  
                  <CategoryChip                    
                    category={categories[category].normalized_name}
                    count={categories[category]?.image_count || 0}
                    to={`/category/${categories[category].normalized_name}/`}
                  />
                )}              
              </React.Fragment> 
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "2rem" }} />
    </Container>
  );
}
