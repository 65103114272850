import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";

import {
  moods,
  color_styles,
  art_styles,
} from "../../options/visual-design-options";

function TShirtTunerOptions({customMood, customArtStyle, customColorStyle, handleMoodChange, handleArtStyleChange, handleColorStyleChange}) {
  return (
    <>
      <FormControl>
        <FormLabel
          id="demo-radio-buttons-group-label"
          sx={{ fontWeight: "800", textDecoration: "underline" }}
        >
          MOOD
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {moods.map((mood, index) => (
            <FormControlLabel
              key={index}
              value={mood}
              control={<Radio onChange={handleMoodChange} />}
              label={mood} 
              checked={mood === customMood}             
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel
          id="demo-radio-buttons-group-label"
          sx={{ fontWeight: "800", textDecoration: "underline" }}
        >
          ART STYLE
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {art_styles.map((art, index) => (
            <FormControlLabel
              key={index}
              value={art}
              control={<Radio onChange={handleArtStyleChange} />}
              label={art}
              checked={art === customArtStyle}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel
          id="demo-radio-buttons-group-label"
          sx={{ fontWeight: "800", textDecoration: "underline" }}
        >
          COLOR STYLE
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {color_styles.map((color, index) => (
            <FormControlLabel
              key={index}
              value={color}
              control={<Radio onChange={handleColorStyleChange} />}
              label={color}
              checked={color === customColorStyle}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default TShirtTunerOptions;
